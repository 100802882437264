'use strict';

require('./widget.component.css');

export default class WidgetComponent {
	public restrict: any;
	public scope: any;
	public template: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      widgetTitle: '=',
      subTitle: '=',
      icon: '=',
      count: '=',
      subCount: '=',
      state: '=',
      callback: '=',
      callbackMeta: '=',
        linkActive: '='
    }
    this.template = require('./widget.component.html');
    this.controller = WidgetComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class WidgetComponentController {
	public helperService: any;
	public $scope: any;
	public $rootScope: any;
	public $log: any;
	public $uibModal: any;
	public dataService: any;
	public state: any;
	public linkActive: any;
	public callback: any;
	public callbackMeta: any;

  constructor($rootScope, $scope, $log, $uibModal, helperService, dataService) {
    this.helperService = helperService;
    this.$scope = $scope;
    this.$rootScope = $rootScope
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.state = 'LOADING';
  }

  triggerCallback() {
    if (!this.linkActive) return;
    this.$log.info('Triggering callback for widget...');
    this.callback(this.callbackMeta);
  }
}
