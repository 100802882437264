'use strict';

/* @ngInject */
export default class ModalImportInstanceController {
	public $scope: any;
	public $rootScope: any;
	public $http: any;
	public $uibModalInstance: any;
	public restService: any;
	public uploader: any;
	public unit: any;
	public isImporting: any;

  constructor($scope, $rootScope, $http, $uibModalInstance, restService, uploader, unit) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$http = $http;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.uploader = uploader;
    this.unit = unit;

    this.uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    }
    this.uploader.url = this.restService.getBaseUrl() + '/units/upload?code=' + encodeURIComponent(this.unit.code);

    this.uploader.onCompleteItem = (item, response, status, headers) => {
      this.$uibModalInstance.close();
    };
  }

  import() {
    this.isImporting = true;
    this.uploader.uploadAll();
  }

  cancel() {
    this.$uibModalInstance.close();
  }
}
