'use strict';

require('./object.danger.substance.component.css');

import angular = require('angular');
export default class ObjectDangerSubstanceComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.danger.substance.component.html');
    this.scope = {
      substance: '=',
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectDangerSubstanceComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectDangerSubstanceComponentController {
	public $scope: any;
	public $uibModal: any;
	public helperService: any;
	public restService: any;
	public riskClass: any;
	public imgPath: any;
	public substance: any;
	public alarmObject: any;
	public isEditable: any;

  constructor($scope, $uibModal, helperService, restService) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.helperService = helperService;
    this.restService = restService;

    this.riskClass = ['INFECT',
      'CORROSIV',
      'NON_FLAMMABLE_GAS',
      'FLAMMABLE_LIQUID',
      'FLAMMABLE_GAS',
      'HEAT',
      'EX_SOLID',
      'PRESSURE_GAS',
      'FLAMMABLE_SOLID',
      'DANGEROUS_WHEN_WET',
      'SURFACE',
      'DANGER',
      'TOXIC_BREATH',
      'TOXIC',
      'LIQUID',
      'RADIOACTIVE_1',
      'RADIOACTIVE_2',
      'RADIOACTIVE_3',
      'TOXIC_ENVIRONMENT'
    ];
    this.imgPath = '';

    this.$scope.$watch('ctrl.substance', () => {
      this.imgPath = 'img/static/classes/' + this.helperService.getIconForRiskClass(this.substance.riskClass) + '.png';
    });
  }

  /**
   * Delete existing substance
   * @param {*} persons
   */
  deleteSubstance() {
    this.alarmObject.risks.substances.splice(this.alarmObject.risks.substances.indexOf(this.substance), 1);
  };

  /**
   * Change risk class
   * @param {*} cls
   * @param {*} risk
   */
  changeRiskClass(cls) {
    this.substance.riskClass = cls;
    this.imgPath = 'img/static/classes/' + this.helperService.getIconForRiskClass(cls) + '.png';
  };

  //#####
  //Typeahead
  //#####

  /**
   * Search for dangerous goods by UN number
   */
  getDangerousGoodsByUnNumber(val) {
    return this.restService.getDangerousGoodsByUnNumber(val);
  };

  /**
   * Search for dangerous goods by name
   */
  getDangerousGoodsByName(val) {
    return this.restService.getDangerousGoodsByName(val);
  };

  dangerousGoodSelected($item) {
    this.substance.un = $item.unId;
    this.substance.risk = $item.name;
  }

  /**
   * Show on map
   * @param {*} element
   */
  showOnMap() {
    if (this.isEditable) {
      if (angular.isUndefined(this.substance.coords)) {
        this.substance.coords = {
          lat: this.alarmObject.address.coords.lat,
          lng: this.alarmObject.address.coords.lng,
          showOnMap: true
        }
      }

      this.$uibModal.open({
        template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
        controller: 'ChooseOnMapCtrl',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          coords: () => {
            return this.substance.coords;
          },
          okFunction: () => {
            return (coords) => {
              this.substance.coords = coords;
            }
          }
        }
      });
    };
  }
}
