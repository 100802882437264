'use strict';

//numberOfPersons
/* @ngInject */
export default class NumberOfPersonsFilter {
  static filter(nbrOfPersons) {
    switch (nbrOfPersons) {
      case 0:
        return 'Keine Person';
      case 1:
        return 'Eine Person';
      default:
        return nbrOfPersons + ' Personen';
    }
  }
}
