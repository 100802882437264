export interface CustomMapLayer {
    type: ECustomMapLayerType
    name: string;
    url: string;
    wmsParameters: WMSParameters

    type2: ECustomMapLayerType
    name2: string;
    url2: string;
    wmsParameters2: WMSParameters

    type3: ECustomMapLayerType
    name3: string;
    url3: string;
    wmsParameters3: WMSParameters

    type4: ECustomMapLayerType
    name4: string;
    url4: string;
    wmsParameters4: WMSParameters
}

export interface SingleMapLayer {
    type: ECustomMapLayerType
    name: string;
    url: string;
    wmsParameters: WMSParameters
}

export interface WMSParameters {
    layers: string;
    styles: string;
    format: string;
    version: string;
}

export enum ECustomMapLayerType {
    TILE = "TILE", WMS = "WMS"
}