'use strict';

import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import VehicleService from "../../../../services/vehicle.service";
import {Vehicle} from "../../../../data/vehicles.data";
import * as angular from "angular";
import {VehicleModes} from "../../../modals/wache/vehicle.modal/vehicle.modal";

require("./sirens.component.css")

export default class SirensComponent {

  private restrict: any;
  private scope: any;
  private controller: any;
  private template: any;
  private controllerAs: string;
  private bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
    }
    this.template = require('./sirens.component.html');

    this.controller = SirensController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//parking
/* @ngInject */
class SirensController {

  public $window: angular.IWindowService;
  public $http: angular.IHttpService;
  public FileUploader: any;
  public listeners: any[];
  public vehicleIds: string[];
  public vehicleService: VehicleService;
  public isLoading: boolean = false;
  public hasVehicles: boolean = false;
  public limit = 50;
  public searchFilter: string = '';
  public previousSearchFilter: string = '';
  public vehicleData = {
    currentPage: 1,
    totalElements: 0,
    data: undefined
  } as VehicleDataPage;


  constructor(
    private $scope: any,
    private $rootScope: any,
    $window: angular.IWindowService,
    $http: angular.IHttpService,
    private $log: any,
    private restService: RestService,
    FileUploader,
    public privilegeService: PrivilegeService,
    private $uibModal: any) {
    this.FileUploader = FileUploader;
    this.$window = $window;
    this.$http = $http;
    this.vehicleIds = [];
    this.listeners = [];
    this.initListeners();
    this.pageChanged();
  }

  /**
   * Init event listeners
   */
  initListeners() {

    // Update vehicle list
    this.listeners.push(this.$rootScope.$on('update.vehicles', () => {
      this.pageChanged();
    }));

    (this.$rootScope as any).$eventToObservable('search.sirens').debounce(800).subscribe((val) => {
      this.pageChanged();
    });

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  exportVehicles() {
    this.$window.open(
      this.restService.getBaseUrl() + '/vehicles/download?Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );

  }

  importVehicles() {
    var modalInstance = this.$uibModal.open({
      template: require('../../../modals/wache/import.vehicle.modal/import.vehicle.modal.html'),
      controller: 'ImportVehicleModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader
        }
      }
    });
    modalInstance.result.then(() => {
      this.pageChanged()
      this.$rootScope.$emit('imported.vehicles');
    });

  }

  exportFaultsAsPdf() {
    this.$window.open(this.restService.getBaseUrl() +
      '/vehicles/faults/export?Authorization=' + this.$http.defaults.headers.common.Authorization, '_blank');
  }
  resetSearchFilter() {
    this.searchFilter = '';
    this.previousSearchFilter = '';
    this.vehicleData.currentPage = 1;
    this.isLoading = true;
    this.onSearchFilterChanged();
  }

  onSearchFilterChanged() {
    this.$rootScope.$emit('search.sirens');
  }

  pageChanged(fromPageSelection?: boolean) {
    let searchPage = this.vehicleData.currentPage - 1;

    // Check filtering only if page selection did not change
    if (!fromPageSelection) {
      if (this.searchFilter !== this.previousSearchFilter) {
        /* when seach filter is different from previous we reset page
        */
        searchPage = 0;
        // save the current search filter
        this.previousSearchFilter = this.searchFilter;
      } else if (this.searchFilter.length > 0) {
        this.$log.info('Suchfilter hat sich nicht geändert');
      }
    }
    this.isLoading = true;
    this.restService.loadVehiclesByTypePaginated(searchPage, this.limit, this.searchFilter, 'SIRENS', 'ASC', (response) => {
      this.isLoading = false;
      this.vehicleData = response;
      this.vehicleData.currentPage = this.vehicleData.currentPage + 1
      this.hasVehicles = this.vehicleData.totalElements !== 0; // always needs to be true

      if (this.searchFilter) {
        // Set focus to input
        setTimeout(() => document.getElementById('wache-vehicle-search-input').focus(), 500);
      }

      this.$log.debug(`Filtered ${this.vehicleData.totalElements} vehicles!`);
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  }

  addVehicle() {
    this.$uibModal.open({
      template: require('../../../modals/wache/add.vehicle.modal/add.vehicle.modal.html'),
      controller: 'AddVehicleController',
      backdrop: 'static',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name: string, code: string) => {
            this.isLoading = true;
            this.restService.addVehicle(name, code).then(vehicle => {
              this.$uibModal.open({
                template: require('../../../modals/wache/vehicle.modal/vehicle.modal.html'),
                controller: 'VehicleModalController',
                controllerAs: 'ctrl',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  vehicle: () => {
                    return vehicle;
                  },
                  vehicleId: () => {
                    return vehicle.id;
                  },
                  okFunction: () => {
                    return (savedVehicle: Vehicle) => {
                      if (angular.isDefined(savedVehicle)) {
                        this.pageChanged();
                      }
                    };
                  },
                  mode: () => VehicleModes.OVERVIEW
                }
              });

            }).then(() => {
              this.pageChanged();
            }).catch(error => {
              this.$log.error(error);
              this.isLoading = false;

            });
          }
        }
      }
    });
  }

}
interface VehicleDataPage {
  length: number,
  currentPage: number,
  totalPages: number,
  totalElements: number,
  data: Vehicle[]
}