'use strict';

import { ILogService, IScope } from "angular";
import { UserAccount } from "../../../../data/account.data";
import { Access, AvailabilityExternalAccess } from "../../../../data/availability.data";

/* @ngInject */
export default class ApiModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $uibModalInstance: any;
  public api: AvailabilityExternalAccess;
  public dataService: any;
  public onlineservices = [];
  public isAdding: boolean = false;
  public account: UserAccount;
  public isAdmin: boolean = false;
  public isLoading: boolean = false;

  constructor($scope: IScope, $log: ILogService, $uibModalInstance, dataService, api: AvailabilityExternalAccess) {
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModalInstance = $uibModalInstance;
    this.api = api;
    this.dataService = dataService;

    this.isAdding = this.api === undefined;

    if (this.isAdding) {
      this.api = {
        name: '',
        active: true,
        access: []
      } as AvailabilityExternalAccess;
    } else {
      this.$log.debug(this.api);
    }

    this.init();
  }

  init() {
    // Init
    this.account = this.dataService.getAccount();
    this.isAdmin = this.account.admin;

    // Load all online services
    this.isLoading = true;
    this.dataService.getOnlineServices(true, (response) => {
      this.isLoading = false;
      this.onlineservices = response.data;
      if (this.isAdding) {
        this.addAccess();
      }
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });
  }

  /**
   * Add a new condition
   */
  addAccess() {
    if (this.account.admin) {
      this.api.access.push({
        osId: this.onlineservices[0].id,
        groups: [],
        functions: [],
        access: 'ONLY_SELECTED'
      });
    } else {
      this.api.access.push({
        osId: this.account.fosId,
        groups: [],
        functions: [],
        access: 'ONLY_SELECTED'
      });
    }
  }

  /**
   * Remove access from api key
   * @param  access 
   */
  removeAccess(access: Access) {
    this.api.access.splice(this.api.access.indexOf(access), 1);
  }

  /**
   * Save
   */
  save() {

    this.isLoading = true;
    if (this.isAdding) {
      this.dataService.createApiKey(this.api, (data) => {
        this.isLoading = false;
        this.$uibModalInstance.close();
        this.$log.debug(data);
      }, (err) => {
        this.isLoading = false;
        this.$log.error(err);
      });
    } else {
      this.dataService.saveApiKey(this.api, (data) => {
        this.isLoading = false;
        this.$uibModalInstance.close();
        this.$log.debug(data);
      }, (err) => {
        this.isLoading = false;
        this.$log.error(err);
      });
    }
  }

  /**
   * Close modal
   */
  cancel() {
    this.$uibModalInstance.close();
  }

  getOsForId(id: number) {
    for (var i = 0; i < this.onlineservices.length; i++) {
      var os = this.onlineservices[i];
      if (os.id === id) {
        return os;
      }
    }
    return undefined;
  }

  isInFunctionList(access: Access, func: string) {
    return access.functions.indexOf(func) !== -1;
  }

  setInFunctionList(access: Access, func: string) {
    var index = access.functions.indexOf(func);
    if (index === -1) {
      access.functions.push(func);
    } else {
      access.functions.splice(index, 1);
    }
  }

  isInGroupList(access: Access, group: string) {
    return access.groups.indexOf(group) !== -1;
  }

  setInGroupList(access: Access, group: string) {
    var index = access.groups.indexOf(group);
    if (index === -1) {
      access.groups.push(group);
    } else {
      access.groups.splice(index, 1);
    }
  }

  /**
   * Get the name of a online serivce by its id
   * @param {} id 
   */
  getNameForOsById(id: number) {
    for (var i = 0; i < this.onlineservices.length; i++) {
      var os = this.onlineservices[i];
      if (os.id === id) {
        return os.name;
      }
    }
    if (id === this.account.fosId) {
      return this.account.username;
    }
    return id;
  }

  /**
   * Returns true if this api key is valid
   * @returns 
   */
  isReadyToSave(): boolean {
    if (!this.api.name || this.api.name === '') {
      return false;
    }
    if (this.api.access.length === 0) {
      return false;
    }

    var hasFunctionsOrGroups = true;
    for (let i = 0; i < this.api.access.length; i++) {
      var access = this.api.access[i] as Access;
      if (access.functions.length === 0 && access.groups.length === 0) {
        // Every access needs at least one function or group
        hasFunctionsOrGroups = false;
        break;
      }
    }

    return hasFunctionsOrGroups;
  }
}