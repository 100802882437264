'use strict';

import RestService from "../../../services/rest.service";
require('./external.view.component.scss');


/* @ngInject */
export default class ExternalViewController {
  constructor(private restService: RestService) {
  }

  getStaticImage(imageName: string) {
    return this.restService.getStaticImageUrl(imageName);
  }
}
