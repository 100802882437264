'use strict';

/* @ngInject */
export default class CreatePersonModalController {
	public $scope: any;
	public $uibModalInstance: any;
	public okFunction: any;
	public firstName: any;
	public lastName: any;

  constructor($scope, $uibModalInstance, okFunction){
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;

    this.firstName = '';
    this.lastName = '';
  }

  cancel(){
    this.$uibModalInstance.close();
  }
  ok  () {
    if (this.firstName === '' || this.lastName === '') {
      return;
    }

    let displayName = this.lastName + ' ' + this.firstName;

    this.okFunction({
      firstName: this.firstName,
      lastName: this.lastName,
      displayName: displayName
    });
    this.cancel();
  };

}
