import { Vehicle } from "./vehicles.data";

export interface AAOResponsePaginated {
    aaoResponseElements: AAO[],
    totalPages: number,
    totalElements: number,
    number: number
}

export interface AAO {
    defaultAAO: string;
    id: string;
    name: string;
    userId: string;
    keywords: AAOKeyword[];
    imported: boolean;
}

export interface AAOKeyword {
    keyword: string;
    description: string;
}

export interface AAOEntryResponseElement {
    id: string;
    note: string;
    defaultEntry: boolean;
    times: AAOTime;
    conditions: AAOCondition[];
    defaultConditionField: string;
    vehicleHtmlPreview: string;
}

export interface AAOTime {
    monday: AAOTimeDay;
    tuesday: AAOTimeDay;
    wednesday: AAOTimeDay;
    thursday: AAOTimeDay;
    friday: AAOTimeDay;
    saturday: AAOTimeDay;
    sunday: AAOTimeDay;
    inverted: boolean;
    holiday: AAOTimeDay;
}

export interface AAOTimeDay {
    start: string;
    end: string;
    fullDay: boolean;
    active: boolean;
}

export interface AAOCondition {
    field: string;
    value: string;
    checkMode: AAOConditionCheckMode
}
export interface AAOEntryResponse {
    aao: AAO;
    entries: AAOEntryResponseElement[];
}

export interface AAOVehicleAssignment {
    id?: string;
    vehicle: Vehicle;
    order: number;
    subOrder: number;
}

export enum AAOConditionCheckMode {
    EQUALS = 'EQUALS',
    CONTAINS = 'CONTAINS'
}