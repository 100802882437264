import { ILogService } from 'angular';
import angular = require('angular');
import PrivilegeService from '../../../../services/privilege.service';
import UnitsService from '../../../../services/units.service';
import { CustomFeedbackResponseResponse, CustomFeedbackEntryResponse, CreateCustomFeedbackResponseRequest, CreateCustomFeedbackEntryRequest, EFeedbackState } from './../../../../data/customFeedback.data';
'use strict';

require('./edit.custom.feedback.modal.css');

/* @ngInject */
export default class EditCustomFeedbackModalController {
  public MAX_NBR_ENTRIES: number = 15;
  public $uibModalInstance: any;
  public $scope: angular.IScope;
  public $log: ILogService;
  public okFunction: any;
  public closeFunction: any;
  public deleteFunction: any;
  public customFeedback: CustomFeedbackResponseResponse;
  public customFeedbackOrig: CustomFeedbackResponseResponse;
  public unitsService: UnitsService;
  public isLoading: boolean = false;
  public isDirty: boolean = false;
  public $uibModal;
  public isNew: boolean = false;
  public tmpNewName: string = '';
  public isEditingEntryById: string;
  public tmpName: string = '';
  public tmpType: EFeedbackState;
  public feedbackTypes: string[] = Object.keys(EFeedbackState);

  public customFeedbackEntries: Array<CustomFeedbackEntryResponse> = [];

  constructor($uibModalInstance, $uibModal, $scope: angular.IScope, unitsService, $log: ILogService, okFunction, closeFunction,
    public privilegeService: PrivilegeService, customFeedback: CustomFeedbackResponseResponse) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.$log = $log;
    this.okFunction = okFunction;
    this.closeFunction = closeFunction;
    this.customFeedback = customFeedback;
    this.customFeedbackOrig = angular.copy(this.customFeedback);
    this.unitsService = unitsService;
    if (customFeedback === undefined) {
      this.isNew = true;
    } else {
      this.getCustomFeedbackEntries();
    }
    this.$uibModal = $uibModal;
  }

  cancel() {
    if (this.customFeedback) {
      this.customFeedback.name = this.customFeedbackOrig.name;
      this.customFeedback.description = this.customFeedbackOrig.description;
    }
    this.closeFunction();
    this.$uibModalInstance.close();
  }

  getCustomFeedbackEntries() {
    this.isLoading = true;
    this.unitsService.getCustomFeedbackEntries(this.customFeedback).then(entries => {
      this.$log.info("Einträge für Rückmeldeliste geladen");
      this.customFeedbackEntries = entries;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  create() {
    const newData = {
      name: this.customFeedback.name,
      description: this.customFeedback.description
    } as CreateCustomFeedbackResponseRequest;

    this.isLoading = true;
    this.unitsService.createCustomFeedback(newData).then(data => {
      this.customFeedback = data;
      this.customFeedbackOrig = angular.copy(this.customFeedback);
    }).finally(() => {
      this.okFunction();
      this.isLoading = false;
      this.isNew = false;
      this.$scope.$apply();
    });
  }

  save() {

    this.isLoading = true;
    this.unitsService.updateCustomFeedback(this.customFeedback).finally(() => {
      this.okFunction();
      this.isLoading = false;
      this.$uibModalInstance.close();
    });
  }

  delete() {
    this.$uibModal.open({
      template: require('../../misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        additionalText: () => {
          return 'CUSTOM_FEEDBACK.CONFIRM_DELETE';
        },
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.unitsService.deleteCustomFeedback(this.customFeedback).finally(() => {
              this.okFunction();
              this.$uibModalInstance.close();
            });
          };
        }
      }
    });
  }

  createEntry() {
    if (this.tmpNewName !== undefined && this.tmpNewName.length > 0) {
      this.isLoading = true;

      const newEntry = {
        name: this.tmpNewName,
        parentId: this.customFeedback.id
      } as CreateCustomFeedbackEntryRequest

      this.unitsService.createCustomFeedbackEntry(newEntry).then((data) => {
        this.tmpNewName = '';
        this.customFeedbackEntries.push(data);
        this.customFeedbackEntries.sort();
        this.isLoading = false;
        this.$scope.$apply();
      }).catch(err => {
        this.isLoading = false;
      });
    }
  }

  deleteEntry(entry: CustomFeedbackEntryResponse) {
    this.unitsService.deleteCustomFeedbackEntry(entry).finally(() => {
      this.getCustomFeedbackEntries();
    })
  }

  updateEntry(entry: CustomFeedbackEntryResponse) {
    // enable when edit of type is supported
    // entry.type = this.tmpType;
    this.unitsService.updateCustomFeedbackEntry(entry).finally(() => {
      this.isEditingEntryById = '';
      this.tmpName = '';
      this.tmpType = undefined;
      this.getCustomFeedbackEntries();
    })
  }

  editEntry(entry: CustomFeedbackEntryResponse) {
    if (this.isEditingEntryById !== '') {
      //An edit was not cancelled correctly so we reset the previous category name
      var _self = this;
      this.customFeedbackEntries.forEach(entry => {
        if (entry.id === _self.isEditingEntryById) {
          entry.name = _self.tmpName;
          entry.type = _self.tmpType;
        }
      })
    }

    this.isEditingEntryById = entry.id;
    this.tmpName = entry.name;
    this.tmpType = entry.type;
  }

  cancelEdit(entry: CustomFeedbackEntryResponse) {
    this.isEditingEntryById = '';
    entry.name = this.tmpName;
    entry.type = this.tmpType
    this.tmpName = '';
    this.tmpType = undefined;
  }
}