'use strict'

import angular = require("angular");
import { AlarmObject } from "../../../../data/objects.data";

require("./emergency.power.component.scss");

export default class EmergencyPowerComponent {
    public restrict: any;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: any;
    public bindToController: any;

    constructor() {
        this.restrict = 'E'
        this.template = require('./emergency.power.component.html');
        this.scope = {
            alarmObject: "=",
            isEditable: "="
        };
        this.controller = EmergencyPowerComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class EmergencyPowerComponentController {

    public isEditable: boolean;
    public alarmObject: AlarmObject;

    constructor(private $uibModal: any) { }


}