'use strict';
import { ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import { User } from "../../../../data/admin.data";
import { SimpleBuildingControllerResponse } from "../../../../data/controller.data";
import RestService from "../../../../services/rest.service";


export default class UserRelaisComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
        this.restrict = 'E'
        this.template = require('./admin.users.relais.component.html');
        this.scope = {
            user: '='
        };
        this.controller = UserRelaisController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class UserRelaisController {


    public isSaving = false;
    public isLoading = false;

    public allControllers: SimpleBuildingControllerResponse[];
    public assignedControllers: SimpleBuildingControllerResponse[];

    public user: User;

    constructor(private restService: RestService,
        private $log: ILogService,
        private $scope: IScope) {

        // get user when it is set, otherwise user will always be undefined
        $scope.$watch('ctrl.user', (oldValue, newValue: User) => {
            if (angular.isDefined(newValue)) {
                this.user = newValue
                if (this.user.admin) {
                    return; // Don't need to load anything for admin
                }
                this.loadControllers();
            }
        });
    }

    removeFromOrganisation(controller: SimpleBuildingControllerResponse){
        this.isSaving = true;
        this.restService.removeAccessToBuildingController(controller.id, this.user.id)
          .then(() => {
            this.isSaving = false;
            this.$scope.$applyAsync();
            this.loadControllers();
          })
      }
    
      addToOrganisation(controller: SimpleBuildingControllerResponse){
        this.isSaving = true;
        this.restService.addBuildingControllerAccessForUser(controller.id, this.user.id)
        .then(() => {
            this.isSaving = false;
            this.$scope.$applyAsync();
            this.loadControllers();
          }
        );
      }
    
      isSelected(controller: SimpleBuildingControllerResponse){
        if (angular.isUndefined(this.assignedControllers)){
          return;
        }
        var list = this.assignedControllers.filter(compareTo => controller.id === compareTo.id);
        return list.length === 1;
      }

    private loadControllers() {
        this.isLoading = true;
        this.restService.getBuildingControllers()
            .then(controllers => this.allControllers = controllers)
            .then(() => this.restService.getBuildingControllersForUser(this.user.id))
            .then(controllers => this.assignedControllers = controllers)
            .then(() => this.$scope.$applyAsync())
            .catch(err => this.$log.error(err))
            .finally(() => this.isLoading = false);
    }
}