'use strict';

import PrivilegeService from "../../../../services/privilege.service";
import angular = require('angular');

//objectNotes
export default class ObjectNotesComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.notes.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectNotesComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectNotesComponentController {
  public alarmObject: any;

  constructor(public privilegeService: PrivilegeService) {
  }

  /**
   * Add a new note
   */
  addNote() {
    var data = {
      author: '',
      note: '',
      timestamp: (new Date()).getTime()
    }

    if (angular.isUndefined(this.alarmObject.notes)) {
      this.alarmObject.notes = [];
    }
    this.alarmObject.notes.push(data);
  };

  /**
   * Delete existing note
   * @param {*} data
   */
  deleteNote(data) {
    this.alarmObject.notes.splice(this.alarmObject.notes.indexOf(data), 1);
  };


}
