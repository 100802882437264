'use strict';

import { IHttpService, ILogService, IRootScopeService, IScope } from "angular";
import ErrorService from "../../../../services/error.service";
import RestService from "../../../../services/rest.service";

//Controller for import adressbook
/* @ngInject */
export default class ImportModalController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $uibModalInstance: any;
  public $log: ILogService;
  public $http: IHttpService;
  public restService: RestService;
  public errorService: ErrorService;
  public uploader: any;
  public url: string;
  public isImporting = false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModalInstance, $log: ILogService, $http: IHttpService, restService: RestService, errorService: ErrorService, uploader, url: string) {

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.$http = $http;
    this.restService = restService;
    this.errorService = errorService;
    this.uploader = uploader;
    this.url = url;

    this.uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };

    this.uploader.url = this.restService.getBaseUrl() + url;

    this.uploader.onCompleteAll = () => {
      this.$log.info('onCompleteAll');
      this.$uibModalInstance.close();
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.$log.info('OnSuccessItem: ' + status);
    };
  }
  cancel() {
    this.$uibModalInstance.close();
  }
  import() {
    this.isImporting = true;
    this.uploader.onErrorItem = (item, response, status) => {
      if (status === 406) {
        //Could not import all persons due to licence issues
        this.errorService.notify(this.errorService.ERROR_IDS.COULD_NOT_IMPORT_ALL);
      } else {
        this.errorService.notifyWithText(response.message);
      }
    };

    this.uploader.uploadAll();
  };


}

