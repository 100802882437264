'use strict';

//numberOfGroups
/* @ngInject */
export default class NumberOfGroupsFilter {
  static filter(nbrOfGroups) {
    switch (nbrOfGroups) {
      case 0:
        return 'Keine Gruppe';
      case 1:
        return 'Eine Gruppe';
      default:
        return nbrOfGroups + ' Gruppen';
    }
  }
}
