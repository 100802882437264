import RestService from "../../../../services/rest.service";
import {IRootScopeService, IScope} from "angular";
import {User} from "../../../../data/admin.data";
import {IOprint} from "../../../../data/ioprint.data";
import angular = require("angular");

require('./admin.users.ioprint.component.css')

export default class UserIOprintComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.ioprint.component.html');
    this.scope = {
      user: '='
    };
    this.controller = UserIOprintController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class UserIOprintController {
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public user: User;
  public ioPrints: IOprint[] = [];
  public selectedIOprints: IOprint[] = [];
  public isLoadingIOprints: boolean;
  public isLoadingIOprintsWithAccess: boolean;
  public isSaving = false;

  constructor(restService: RestService, $scope: angular.IScope, $rootScope: angular.IRootScopeService) {
    this.restService = restService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.selectedIOprints = [];
    this.ioPrints = [];
    // get user when it is set, otherwise user will always be undefined
    $scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (angular.isDefined(newValue)) {
        this.user = newValue
        if (this.user.admin) {
          return; // Don't need to load anything for admin
        }
        this.loadIOprintsWithAccess();
        this.loadIOprints();
      }
    });
  }

  removeFromOrganisation(IOprint: IOprint) {
    this.isSaving = true;
    this.restService.removeAccessToIOprint(this.user, IOprint)
      .finally(() => {
        this.isSaving = false;
        this.$scope.$applyAsync();
        this.loadIOprintsWithAccess();
      })
  }

  addToOrganisation(IOprint: IOprint) {
    this.isSaving = true;
    this.restService.addAccessToIOprint(this.user, IOprint)
      .finally(() => {
        this.isSaving = false;
        this.$scope.$applyAsync();
        this.loadIOprintsWithAccess();
      });
  }

  isSelected(IOprint: IOprint) {
    if (angular.isUndefined(this.selectedIOprints)) {
      return;
    }
    var list = this.selectedIOprints.filter(compareTo => IOprint.id === compareTo.id);
    return list.length === 1;
  }

  /**
   * load all IOprints from backend
   */
  loadIOprints() {
    this.isLoadingIOprints = true;
    this.restService.loadAllIOprints().then((result: IOprint[]) => {
      if (angular.isDefined(result)) {
        this.ioPrints = result;
      }
    }).finally(() => {
      this.isLoadingIOprints = false;
      this.$scope.$applyAsync();
    });

  }

  /**
   * load IOprints that organisation has access to from backend
   */
  loadIOprintsWithAccess() {
    this.isLoadingIOprintsWithAccess = true;
    if (angular.isUndefined(this.user)) {
      this.isLoadingIOprintsWithAccess = false;
      return;
    }
    this.restService.loadIOprintsForUser(this.user)
      .then((result: IOprint[]) => {
          if (angular.isDefined(result)) {
            this.selectedIOprints = result;
          }
        }
      ).finally(() => {
      this.isLoadingIOprintsWithAccess = false;
      this.$scope.$applyAsync();
    })

  }

}