'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {InputPlugin} from "../../../../data/input.data";
import RestService from "../../../../services/rest.service";

require('./admin.input.component.css');

export default class AdminInputComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.input.component.html');
    this.scope = {
    };
    this.controller = AdminInputComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
class AdminInputComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public isLoading: boolean = true;
  public inputs: InputPlugin[] = [];
  private restService: RestService;
  private listeners = [];
  private $uibModal: any;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $uibModal, restService: RestService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.restService = restService;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.load();

    this.listeners.push(this.$rootScope.$on('update.input.plugins', () => {
      this.load();
    }));

  }

  openURLInfo(url: string) {
    // Open in browser
    window.open(url, '_blank').focus();
  }

  /**
   * Load all users
   */
  load() {
    this.$log.debug('Loading input plugins...');
    this.isLoading = true;
    this.restService.getAllInputs().then((inputs) => {
      this.$log.info(inputs);
      this.inputs = inputs;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  };

  addInput(): void {
    this.$uibModal.open({
      template: require('../../../modals/admin/add.input.modal/add.input.modal.html'),
      controller: 'AddInputModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.load();
          }
        }
      }
    });
  }
}

