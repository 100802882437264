import angular from 'angular';
import 'angular-ui-bootstrap'

import services from '../../../services'

import AlarmDetailComponent from './alarm.detail.component/alarm.detail.component';
import AlarmCloseActionsComponent from './alarm.closeactions.component/alarm.closeactions.component';
import AlarmErrorsComponent from './alarm.errors.component/alarm.errors.component';
import AlarmExternalCostsComponent from './alarm.externalcosts.component/alarm.externalcosts.component';
import AlarmFeedbackComponent from './alarm.feedback.component/alarm.feedback.component';
import AlarmFeedbackPanelComponent from './alarm.feedbackpanel.component/alarm.feedbackpanel.component';
import AlarmTableComponent from './alarm.table.component/alarm.table.component';
import AlarmCenterComponent from './alarm.center.component/alarm.center.component';
import AlarmComponent from './alarm.component/alarm.component';
import AlarmVehiclesComponent from './alarm.vehicles.component/alarm.vehicles.component';
import AlarmTemplatesTableRowComponent from './alarm.templates.tablerow.component/alarm.templates.tablerow.component';
import AlarmTemplatesComponent from './alarm.templates.component/alarm.templates.component';
import AlarmTemplateButtonComponent from './alarm.template.button.component/alarm.template.button.component';
import AlarmProtocolComponent from './alarm.protocol.component/alarm.protocol.component';
import AlarmMapComponent from './alarm.map.component/alarm.map.component';
import AlarmVideoComponent from './alarm.video.component/alarm.video.component';
import AlarmRowEntryComponent from './alarm.row.entry.component/alarm.row.entry.component';
import AlarmMessagesComponentController from './alarm.messages.component/alarm.messages';
import AlarmMessageTableRowComponentController from './alarm.message.row.component/alarm.message.row.component';
import AlarmAssignedRowEntryComponent from './alarm.assigned.row.entry.component/alarm.assigned.row.entry.component';
import MissionAlarmComponent from "./mission/mission.alarm.component/mission.alarm.component";
import MissionAlarmsComponent from "./mission/mission.alarms.component/mission.alarms.component";
import MissionRowEntryComponent from "./mission/mission.row.entry.component/mission.row.entry.component";
import MissionReportComponent from "./mission/mission.report.component/mission.report.component";
import MissionLocationMapComponent from "./mission/mission.location.map.component/mission.location.map.component";
import MissionExportComponent from "./mission/mission.export.component/mission.export.component";
import MissionTimelineComponent from "./mission/mission.timeline.component/mission.timeline.component";
import ManualAlarmDetailsComponent from "./manual.alarm.details.component/manual.alarm.details.component";
import MissionImagesComponent from "./mission/mission.images.component/mission.images.component";
import RestProtocolBodyComponent from "./rest_protocol/rest.protocol.body.component/rest.protocol.body.component";
import RestProtocolHeadersComponent
  from "./rest_protocol/rest.protocol.headers.component/rest.protocol.headers.component";
import RestProtocolInfoComponent from "./rest_protocol/rest.protocol.info.component/rest.protocol.info.component";
import WorkloadComponent from "./workload.component/workload.component";
import MissionReportTablerowComponent
  from "./mission/mission.report.tablerow.component/mission.report.tablerow.component";
import MissionReportMissionDataComponent
  from "./mission/mission.report.missionData.component/mission.report.missionData.component";
import MissionReportParticipantsComponent
  from "./mission/mission.report.participants.component/mission.report.participants.component";
import MissionReportResourceComponent
  from "./mission/mission.report.resources.component/mission.report.resource.component";
import MissionReportResourceTablerowComponent
  from "./mission/mission.report.resources.tablerow.component/mission.report.resource.tablerow.component";
import MissionReportParticipantsEntryComponent
  from "./mission/mission.report.participant.entry.component/mission.report.participants.entry.component";

export default angular.module('FE2.components.alarm', ['ui.bootstrap', services])
  .directive('alarmDetail', () => new AlarmDetailComponent)
  .directive('alarmCloseActions', () => new AlarmCloseActionsComponent)
  .directive('alarmErrors', () => new AlarmErrorsComponent)
  .directive('alarmExternalCosts', () => new AlarmExternalCostsComponent)
  .directive('alarmFeedback', () => new AlarmFeedbackComponent)
  .directive('alarmFeedbackPanel', () => new AlarmFeedbackPanelComponent)
  .directive('alarmTable', () => new AlarmTableComponent)
  .directive('alarmCenterView', () => new AlarmCenterComponent)
  .directive('sendAlarmView', () => new AlarmComponent)
  .directive('alarmVehicles', () => new AlarmVehiclesComponent)
  .directive('alarmTemplatesRow', () => new AlarmTemplatesTableRowComponent)
  .directive('alarmTemplatesView', () => new AlarmTemplatesComponent)
  .directive('alarmTemplateButton', () => new AlarmTemplateButtonComponent)
  .directive('alarmProtocol', () => new AlarmProtocolComponent)
  .directive('alarmMap', () => new AlarmMapComponent)
  .directive('alarmVideo', () => new AlarmVideoComponent)
  .directive('alarmRowEntry', () => new AlarmRowEntryComponent)
  .directive('alarmMessages', () => new AlarmMessagesComponentController)
  .directive('alarmMessageRow', () => new AlarmMessageTableRowComponentController)
  .directive('alarmAssignedRowEntry', () => new AlarmAssignedRowEntryComponent)
  .directive('missionAlarm', () => new MissionAlarmComponent)
  .directive('missionAlarms', () => new MissionAlarmsComponent)
  .directive('missionRowEntry', () => new MissionRowEntryComponent)
  .directive('missionReport', () => new MissionReportComponent)
  .directive('missionLocationMap', () => new MissionLocationMapComponent)
  .directive('missionImages', () => new MissionImagesComponent)
  .directive('missionExport', () => new MissionExportComponent)
  .directive('missionTimeline', () => new MissionTimelineComponent)
  .directive('manualAlarmDetails', () => new ManualAlarmDetailsComponent())
  .directive('restProtocolBody', () => new RestProtocolBodyComponent())
  .directive('restProtocolHeaders', () => new RestProtocolHeadersComponent())
  .directive('restProtocolInfo', () => new RestProtocolInfoComponent())
  .directive('workload', () => new WorkloadComponent)
  .directive('missionReportTablerow', () => new MissionReportTablerowComponent)
  .directive('reportMissionData',() => new MissionReportMissionDataComponent)
  .directive('reportParticipants',() => new MissionReportParticipantsComponent)
  .directive('reportResources',() => new MissionReportResourceComponent)
  .directive('reportResourceTablerow', ()=> new MissionReportResourceTablerowComponent)
  .directive('reportParticipantEntry', () => new MissionReportParticipantsEntryComponent)
  .name;
