import { AssetStrength, EVehicleStatus, VehicleNameIDResponse } from './vehicles.data';
import { AlarmData } from "./alarm.data";

export interface Queue {
    id: string;
    name: string;
    active: boolean;

    hasConnectedVehicle: boolean;
    connectedVehicleId?: string;
    status?: EVehicleStatus;
    statusColor?: string;
    statusTextColor?: string;

    // Only calculated in frontend
    empty?: boolean;
}

export interface QueueTableResponse {
    inProgress: QueueEntryResponse[];
    assigned: QueueEntryResponse[];
    done: QueueEntryResponse[];
    assignedCounter: number;
    inProgressCounter: number;
    totalCounter: number;
    doneCounter: number;
    numberOfQueues: number;

    queues: SimpleQueueResponse[];
}

export interface QueueResponse {
    inProgress: QueueEntryResponse[];
    assigned: QueueEntryResponse[];
    done: QueueEntryResponse[];

    name: string;
    assignedCounter: number;
    inProgressCounter: number;
    totalCounter: number;
    doneCounter: number;
    state: EQueueState;
    active: boolean;
    hasConnectedVehicle: boolean;
    connectedVehicleId?: string;
    status?: EVehicleStatus;
    statusColor?: string;
    statusTextColor?: string;
    assignedVehicles: VehicleNameIDResponse[];
}

export interface QueueEntryResponse {
    id: string;
    emoji: string;
    queueId: string;
    queueConnectedVehicleId: string;
    queueActive: boolean;
    queueName: string;
    externalId: string;
    state: EQueueEntryState;
    alarmData: QueueEntryAlarmData;
    clock: string;
    doneClock:string;
    progressClock: string;
    progressTimeAsString: string
    priority: number;
    priorityColor: string;
    creationTime: string;
    creationTimeAsString: string;
    creationTimeAsStringOnlyTime: string;
    doneTime: string;
    doneTimeAsString: string;
    lastChangedTimeAsString: string;
    hasMap: boolean;
    similarEntries: SimpleQueueResponse[];
    similarEntriesText: string;
    connectedVehicleId?: string;
    status?: EVehicleStatus;
    statusColor?: string;
    statusTextColor?: string;
    locationString: string;
    hasNewImportantMessage: boolean;
    hasNewAdditionalRequest: boolean;
    nbrOfStreetOccurencesInQueues: number;
}

export interface SimpleQueueResponse {
    queueName: string;
    queueId: string;
}

export interface UpdateQueuePriorityRequest {
    priority: number;
}

export interface UpdateQueueActiveStateRequest {
    active: boolean;
}


export interface UpdateQueueStateRequest {
    state: EQueueEntryState;
    queueId: string;
    reportMessage?: string;
}


export interface MoveToQueueRequest {
    newQueueId: string;
    mode: EMoveMode;
}

export interface QueueEntryExpanded {
    id: string;
    expanded: boolean;
}

export interface QueueEntryAlarmData {
    keyword: string;
    locationDest: string;
    lat: string;
    lng: string;
    city_abbr: string;
    city: string;
    house: string;
    displayTextForFE2: string;
    keyword_color: string;
    pluginmessage: string;
    locationMapId: string;
    vehicles: QueueEntryVehicle[];
}

export interface QueueEntryVehicle {
    vehicleId: string;
    vehicleName: string;
    status: EVehicleStatus;
    statusColor: string;
    statusTextColor: string;
    alarmTime: number;
    statusTime: number;
    strength: AssetStrength;
    totalStrength: number;
    assignedToCurrentQueue: boolean;
}

export interface QueueEntrySelectedEvent {
    entry: QueueEntryResponse;
    hasNewImportantMessage: boolean;
    hasNewAdditionalRequest: boolean;
}

export interface QueueEntryForBroadcast {
    bcEntry: QueueEntrySelectedEvent;
    vehicleId: string;
}

export enum EMoveMode {
    COPY = 'COPY', MOVE = 'MOVE'
}

export enum EQueueEntryState {
    ASSIGNED = 'ASSIGNED', IN_PROGRESS = 'IN_PROGRESS', DONE = 'DONE'
}
export enum EQueueState {
    BUSY = 'BUSY', GOOD = 'GOOD'
}

export enum EFinishMode {
    FINISH_SINGLE_ENTRY = 'FINISH_SINGLE_ENTRY', FINISH_ALL_RUNNING_ENTRIES = 'FINISH_ALL_RUNNING_ENTRIES', FINISH_ALL_ENTRIES = 'FINISH_ALL_ENTRIES'
}