'use strict';
import ErrorService from "../../../../services/error.service";
import {ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import RestService from "../../../../services/rest.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";

require('./export.inRange.modal.css')
/* @ngInject*/
export default class ExportInRangeModalController {
  public $uibModalInstance: any;
  public isLoading: boolean = false;
  public errorService: ErrorService;
  public $log: ILogService;
  public $http: any;
  public $window: IWindowService;
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public startDate: Date;
  public endDate: Date;
  public url: string;
  public title: string;

  constructor( url, title, $scope: IScope, $rootScope: IRootScopeService, $uibModalInstance, errorService: ErrorService, $log: ILogService,$window, $http,restService: RestService,public privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.errorService = errorService;
    this.$log = $log;
    this.$http = $http;
    this.restService = restService;
    this.$window = $window;
    this.url = url;
    this.title = title;

    this.endDate=new Date();
    this.endDate.setMilliseconds(0);
    this.endDate.setSeconds(0);

  }

  cancel() {
    this.$uibModalInstance.close();
  }

  export() {
      const auth = this.$http.defaults.headers.common.Authorization;
      let start = (this.startDate) ? this.startDate.getTime() : 0;
      let end = (this.endDate) ? this.endDate.getTime() : 0;
      const url = `${this.restService.getBaseUrl()}${this.url}?Authorization=${auth}&start=${start}&end=${end}`;
      this.$window.open(url, '_blank');
  }
}