'use strict';

import { IScope } from "angular";
import { MasterSlaveSettings } from "../../../../../data/admin.data";
import { EServerMode } from "../../../../../data/socket.data";
import RestService from "../../../../../services/rest.service";
import {isValidDate} from "@fullcalendar/core/internal";

require('./admin.masterslave.component.css');

export default class AdminMasterSlaveSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.masterslave.component.html');
    this.scope = {
    };
    this.controller = AdminMasterSlaveComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AdminMasterSlaveComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private settings: MasterSlaveSettings;
  private masterValid: boolean;
  private portValid: boolean;
  private intervalValid: boolean;

  constructor($scope: IScope, restService: RestService) {
    this.restService = restService;
    this.$scope = $scope;
    this.load();
  }


  /**
   * Load map settings
   */
  load() {
    this.isLoading = true;
    this.restService.loadMasterSlaveSettings().then(settings => {
      this.settings = settings;
    }).finally(() => {
      this.isLoading = false;
      this.validate();
      this.$scope.$applyAsync();
    });
  }

  /**
   * Save map settings
   */
  save() {
    this.isLoading = true;
    this.restService.saveMasterSlaveSettings(this.settings).then(response => {
      this.settings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  setType(mode: EServerMode): void {
    this.settings.mode = mode;
    this.validate();
    this.$scope.$applyAsync();
  }

  private validate() {
    if (this.settings.mode === EServerMode.SLAVE) {
      this.validateMaster();
      this.validatePort();
      this.validateInterval();
    } else {
      this.masterValid = true;
      this.portValid = true;
      this.intervalValid = true;
    }
  }

  validateMaster() {
    if (this.settings.masterIp.indexOf(";") !== -1) {
      let ipsToCheck = this.settings.masterIp.split(";");
      for (let index = 0; index < ipsToCheck.length; index++) {
        this.masterValid = this.checkIpValid(ipsToCheck[index]);
        if (!this.masterValid) {
          // if one is invalid check can be stopped
          break;
        }
      }
    } else {
      this.masterValid = this.checkIpValid(this.settings.masterIp);
    }
  }

  private checkIpValid(address:string) {
    const matchLocal = address.match(/^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/);
    const matchRemote = address.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+$/);
    const matchIp = address.match(/^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm);

    const isLocal = matchLocal !== undefined && matchLocal !== null && matchLocal.length >= 0;
    const isRemote = matchRemote !== undefined && matchRemote !== null && matchRemote.length >= 0;
    const isIp = matchIp !== undefined && matchIp !== null && matchIp.length >= 0;
    return isLocal || isRemote || isIp;
  }

  validatePort() {
    this.portValid = this.settings.masterPort && this.settings.masterPort > 0;
  }

  validateInterval() {
    this.intervalValid = this.settings.interval && this.settings.interval > 0;
  }
}
