'use strict';
//poiRow
export default class PoiRowComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./poi.tablerow.component.html');
    this.scope = {
      poi: '=',
      username: '='
    };
    this.controller = PoiRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class PoiRowComponentController {
  constructor() {
  }
}