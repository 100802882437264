'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { AlarmGroupSimple } from "../../../../data/alarmgroup.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./alarm.messages.css');

export default class AlarmMessagesComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;
  public isLoading: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.messages.html');
    this.scope = {
      account: '='
    };
    this.controller = AlarmMessagesComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;

    this.isLoading = false;
  }
}

/* @ngInject */
class AlarmMessagesComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public messagesService: any;
  public $uibModal: any;
  public Notification: any;
  public $translate: any;
  public selectedGroups: AlarmGroupSimple[] = [];
  public alarmGroups: AlarmGroupSimple[] = [];
  public messages: any;
  public listeners = [];
  public newMessage: any;
  public isLoading = false;
  public hasOnlineService = false;
  public loadingAlarmgroups = false;
  public restService: RestService;

  constructor($scope: IScope, $rootScope: IRootScopeService, $translate, messagesService, restService: RestService,
    dataService, $log: ILogService, $uibModal, Notification, public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.messagesService = messagesService;
    this.restService = restService;
    this.$uibModal = $uibModal;
    this.Notification = Notification;
    this.$translate = $translate;
    this.selectedGroups = [];

    this.messages = [];

    this.hasOnlineService = dataService.getAccount().hasOnlineService;

    this.newMessage = {
      title: '',
      message: '',
      color: '#337AB7',
      type: 'INFO',
      feedback: false,
      groupIds: [],
      schedule: {
        infoMessageScheduleType: 'NOW'
      }
    }

    this.loadAlarmGroups();
    this.loadMessages();

    this.listeners.push(this.$rootScope.$on('alarm.messages', (event, messages) => {
      this.messages = messages;
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Init data model
   */
  loadMessages() {
    this.isLoading = true;
    this.messagesService.getMessages((response) => {
      this.messages = response;
      this.isLoading = false;
      this.$scope.$applyAsync();
    }, (errorResponse) => {
      this.isLoading = false;
    });
  };

  /**
   * Open modal to add a new message
   */
  addMessage() {
    this.$uibModal.open({
      template: require('../../../modals/alarms/messages.modal/messages.modal.html'),
      controller: 'AlarmMessagesModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        message: () => {
          return undefined;
        },
        hasOnlineService: () => {
          return this.hasOnlineService;
        }
      }
    });
  }

  /**
   * Open modal to edit an existing message
   */
  editMessage(msg) {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Messages_Planned)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/alarms/messages.modal/messages.modal.html'),
      controller: 'AlarmMessagesModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        message: () => {
          return msg;
        },
        hasOnlineService: () => {
          return this.hasOnlineService;
        }
      }
    });
  }

  loadAlarmGroups() {
    this.loadingAlarmgroups = true;
    this.restService.loadAlarmGroupsSimple().then(alarmGroups => {
      this.alarmGroups = alarmGroups;
    }).finally(() => {
      this.loadingAlarmgroups = false;
      this.$scope.$applyAsync();
    });
  }

  selectGroup(group: AlarmGroupSimple) {
    const includes = this.selectedGroups.includes(group);
    if (includes) {
      this.selectedGroups.splice(this.selectedGroups.indexOf(group), 1);
    } else {
      this.selectedGroups.push(group);
    }

    this.newMessage.groupIds = [];
    this.selectedGroups.forEach(group => {
      this.newMessage.groupIds.push(group.id);
    });
  }

  resetMessage() {
    this.newMessage.title = '';
    this.newMessage.message = '';
    this.newMessage.feedback = false;
    this.newMessage.groupIds = [];
    this.selectedGroups = [];
  }

  /**
   * Delete all old messages
   */
  clearMessages() {
    this.isLoading = true;
    this.messagesService.clearMessages((response) => {
      this.messages = response;
      this.isLoading = false;
    }, (errorResponse) => {
      this.isLoading = false;
    });
  }

  sendMessage() {
    this.messagesService.sendMessage(this.newMessage, () => {

      this.$translate(['MESSAGES.SUCCESS_MESSAGE', 'MESSAGES.SUCCESS_TITLE']).then((translations) => {
        this.Notification.success({
          message: translations['MESSAGES.SUCCESS_MESSAGE'],
          title: translations['MESSAGES.SUCCESS_TITLE']
        });
      });

      this.$log.info('Message sent successfully');
      this.resetMessage();
    }, (errorResponse) => {
      this.$log.error('Could not send message', errorResponse);
    });
  }
}
