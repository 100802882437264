'use strict';
import {IScope} from "angular";
import {
  Emergency, EMissionReportState,
  MissionReport,
} from "../../../../../data/emergency.data";
import RestService from "../../../../../services/rest.service";
import {
  DataBasePersonForTracking,
  PaginatedDatabasePersonForTracking,
  PersonSearchQueryResult
} from "../../../../../data/person.data";
import PrivilegeService from "../../../../../services/privilege.service";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import {SortParams} from "../../../../views/addressbook.view.component/addressbook.view.component";
import {VehicleForReport, VehicleForReportSlice} from "../../../../../data/vehicles.data";
/* @ngInject */
export default class MissionReportResourceComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.scope = {
      report: "=",
      emergency: "="
    }
    this.template = require('./mission.report.resource.component.html');
    this.controller = MissionReportResourceController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
require('./mission.report.resources.component.scss')

class MissionReportResourceController {

  public $scope: IScope;
  public report: MissionReport;
  public isLoadingVehicles: boolean = false;
  public showSelected: boolean = false;
  public selectedVehicleList: VehicleForReport[] = [];
  public allVehicles: VehicleForReportSlice;
  public hasEditRight: boolean = false;
  public emergency: Emergency;
  public listeners: any = [];
  public params = {
    sortType: 'name',
    searchFilter: '',
    currentPage: 0,
    pageSize: 10
  } as SortParams;

  constructor($scope: IScope, public $rootScope: any, public privilegeService: PrivilegeService, public restService: RestService, public $uibModal) {
    this.$scope = $scope;
    this.listeners.push($scope.$watch('ctrl.emergency', (newValue, oldValue) => {
      if (this.emergency) {
        this.isLoadingVehicles = true;
        this.restService.loadVehiclesSlicesAsPromise(this.params.currentPage, this.params.pageSize, this.params.searchFilter, this.emergency.parentUserId)
          .then((vehicles) => {
            this.allVehicles = vehicles;
            this.updateUI();
          }).finally(() => {
          this.isLoadingVehicles = false;
        })
      }
    }));
    this.listeners.push($scope.$watch('ctrl.report', (newValue: MissionReport, oldValue) => {
      if (this.report) {
        this.hasEditRight = this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write);;
        if (this.report.state === EMissionReportState.PUBLISHED) {
          this.hasEditRight = false;
        }
        this.selectedVehicleList = [];
        if (this.report.reportResources && this.report.reportResources.length > 0) {
          this.report.reportResources.forEach(vehicle => {
            if (vehicle && vehicle.vehicleId){
             this.restService.loadVehicleForReport(this.emergency.parentUserId, vehicle.vehicleId).then((selected)=>{
               this.selectedVehicleList.push(selected);
             })
            }
          })
        }
      }
    }));

    this.listeners.push($scope.$on('reportResource.add', (event,newResource: VehicleForReport) => {
      if (this.report) {
        this.selectedVehicleList.push(newResource);
      }
    }));
    this.listeners.push($scope.$on('reportResource.remove', (event,oldResource: VehicleForReport) => {
      if (this.report) {
        let index = this.selectedVehicleList.findIndex((resource) => resource.id === oldResource.id);
        this.selectedVehicleList.splice(index,1);
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  getCorrectVehicleList() {
    if (this.report && this.allVehicles) {
      if (this.showSelected) {
        return this.selectedVehicleList;
      }
      return this.allVehicles.content;
    }

  }

  previousPage() {
    this.params.currentPage--;
    if (this.params.currentPage < 0) {
      this.params.currentPage = 0;
    }
    this.reloadVehicles();
  }
  getDisplayPage(){
    return this.params.currentPage + 1;
  }

  nextPage() {
    this.params.currentPage++;
    if (this.params.currentPage < 0) {
      this.params.currentPage = 0;
    }
    this.reloadVehicles();
  }

  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.searchVehicles();
  }

  searchVehicles() {
    if (!this.params.searchFilter) {
      this.params.currentPage = 0;
    }
    this.reloadVehicles();
  }

  reloadVehicles() {
    this.isLoadingVehicles= true;
    this.restService.loadVehiclesSlicesAsPromise(this.params.currentPage, this.params.pageSize, this.params.searchFilter, this.emergency.parentUserId)
      .then((vehicles) => {
        this.allVehicles = vehicles;
        this.updateUI();
      }).finally(() => {
      this.isLoadingVehicles = false;
      this.$scope.$applyAsync();
    })
  }
  updateUI(){
    if (!this.allVehicles){
      return;
    }
    this.params.currentPage = this.allVehicles.number
  }

}