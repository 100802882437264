'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import {AlarmGroup, AlarmGroupSimple, PersonSimpleResponse} from "../../../../data/alarmgroup.data";
import {Person, PersonsAddressbookResponse, PersonSimple, PaginatedDatabasePersonForAlarmGroups, DataBasePersonForAlarmGroups} from "../../../../data/person.data";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {AgeVerificationSetting} from "../../../../data/admin.settings";

require('./alarm.groups.component.css');

export default class AlarmGroupsComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {},
      this.template = require('./alarm.groups.component.html')

    this.controller = AlarmGroupsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmGroupsController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $element: any;
  public $uibModal: any;
  public helperService: any;
  public restService: RestService;
  public dataService: any;
  public listeners = [];
  public selectedAlarmGroup: AlarmGroup;
  public addressbook: PaginatedDatabasePersonForAlarmGroups;
  public isDirty = false;
  public alarmGroupsSimple: AlarmGroupSimple[] = [];
  public isLoadingAlarmGroups = true;
  public isLoadingAlarmGroupDetail:boolean= true;
  public isLoadingPersons = false;
  public params = {
    sortType: 'displayName', // set the default sort type
    sortReverse: false, // set the default sort order
    searchFilter: '', // set the default search/filter term
    currentPage: 0,
    totalElements: 0,
    pageSize: 25
  } as SortParams;
  public priv:PrivilegeService;
  public hasAlarmGrCreate:boolean=false;
  public hasAlarmGrEdit:boolean=false;
  public hasAlarmGrDelete:boolean=false;
  public hasAddrPers:boolean=false;
  public ageVerificationSetting: AgeVerificationSetting;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $element, $uibModal, helperService, restService: RestService, dataService, privilegeService:PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$element = $element;
    this.$uibModal = $uibModal;
    this.helperService = helperService;
    this.restService = restService;
    this.dataService = dataService;
    this.priv = privilegeService;

    this.listeners.push(this.$rootScope.$on('addressbook.view.tab', (event, source) => {
      if (source === 1) {
        this.hasAlarmGrEdit= this.priv.has(RolePrivilege.Addressbook_AlarmGroup_Edit);
        this.hasAlarmGrCreate= this.priv.has(RolePrivilege.Addressbook_AlarmGroup_Create);
        this.hasAlarmGrDelete= this.priv.has(RolePrivilege.Addressbook_AlarmGroup_Delete);
        this.loadAlarmGroups();
        this.loadPersons();

      }
    }));
    this.loadAgeVerificationSetting();
    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  loadAgeVerificationSetting() {
    this.restService.getAgeVerificationSetting().then((ageVerification) => {
      this.ageVerificationSetting = ageVerification;
    }).catch(error => {
    }).finally(() => {
    })
  }

  getAgeClass(age: number){
    if (this.ageVerificationSetting != null && this.ageVerificationSetting.useAgeVerification) {
      var styleByAge = this.helperService.getStyleByAge(age, this.ageVerificationSetting.minAge, this.ageVerificationSetting.maxAge);
      return "label label-" + styleByAge;
    }
    return "label";
  }



  /**
   * Load all alarm groups
   */
  loadAlarmGroups() {
    this.isLoadingAlarmGroups = true;
    this.restService.loadAlarmGroupsSimple().then(alarmGroups => {
      this.alarmGroupsSimple = alarmGroups;
      if (this.alarmGroupsSimple.length > 0) {
        this.loadAndSelectAlarmGroup(this.alarmGroupsSimple[0]);
      }
    }).finally(() => {
      this.isLoadingAlarmGroups = false;
      this.$scope.$applyAsync();
    });
  }

  private loadAlarmGroupsWithOldSelected(alarmGroupSimple: AlarmGroup) {
    this.isLoadingAlarmGroups = true;
    this.restService.loadAlarmGroupsSimple().then(alarmGroups => {
      this.alarmGroupsSimple = alarmGroups;
      this.selectGroup(alarmGroupSimple);

    }).finally(() => {
      this.isLoadingAlarmGroups = false;
      this.$scope.$applyAsync();
    });
  }

  loadAndSelectAlarmGroup(alarmGroupSimple: AlarmGroupSimple){
    this.isLoadingAlarmGroupDetail = true;
    this.restService.loadSingleAlarmGroup(alarmGroupSimple.id).then((group)=>{
      this.selectGroup(group);
    }).finally(()=>{
      this.isLoadingAlarmGroupDetail = false;
      this.$scope.$applyAsync();
    })

  }

  saveSingleAlarmGroup(){
    this.isLoadingAlarmGroups = true;
    this.isLoadingAlarmGroupDetail =true;
    this.restService.saveSingleAlarmGroupWithPersons(this.selectedAlarmGroup).then(()=>{
      this.isDirty =false;
    }).finally(()=>{
      this.isLoadingAlarmGroupDetail = false;
      this.isLoadingAlarmGroups = false;
      this.$scope.$applyAsync();
    })
  }

  loadPersons() {
    // just necessary for privilege edit of alarm group
    if (this.hasAlarmGrEdit) {
      this.isLoadingPersons = true;
      this.restService.loadPersonsAlarmGroupRep(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1,
        this.params.searchFilter,
        this.params.sortReverse ? 'DESC' : 'ASC',
        this.params.pageSize,
        undefined,
        false)
        .then((result: PaginatedDatabasePersonForAlarmGroups) => {
          this.addressbook = result;
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
        }).finally(() => {
        this.isLoadingPersons = false;
        this.$scope.$applyAsync();
      });
    }
  }

  /**
   * Select a group and open modal popup
   */
  openGroup(groupSimple: AlarmGroupSimple) {
      this.$uibModal.open({
        template: require('../../../modals/availability/single.group.modal/single.group.modal.html'),
        controller: 'SingleGroupModalController',
        controllerAs: 'ctrl',
        size: 'sm',
        backdrop: 'static',
        resolve: {
          group: () => {
            return groupSimple;
            },
          okFunction: () => {
            return (refresh) => {
              // do not reload if cancel was clicked (no changes to display)
              if (refresh) {
                // Refresh selection
                this.loadAlarmGroupsWithOldSelected(this.selectedAlarmGroup);
              }
          }
        }
      }
    });
  }

  /**
   * Add a person to a group
   * @param {} person
   */
  addToGroup(person: DataBasePersonForAlarmGroups) {
    // Add to data model
    if (!this.personIsInSelectedGroup(person)) {
      this.selectedAlarmGroup.persons.push({
        personID: person.id,
        displayName: person.displayName,
        note: person.note
      } as PersonSimpleResponse);
      this.isDirty = true;
    }
  }

  addAllVisible() {
    this.addressbook.content.forEach(person => this.addToGroup(person));
  }


  personIsInSelectedGroup(person: DataBasePersonForAlarmGroups) {
    if (angular.isUndefined(this.selectedAlarmGroup)){
      return false;
    }
    if (angular.isUndefined(this.selectedAlarmGroup.persons)){
      return false;
    }
    var list = this.selectedAlarmGroup.persons.filter(pers => person.id === pers.personID);
    return list.length === 1;
  }

  /**
   * Remove a person from a group
   */
  removeFromGroup(person: PersonSimple) {
    const foundElement = this.selectedAlarmGroup.persons.filter(entry => entry.personID === person.personID);

    if (foundElement.length > 0) {
      this.selectedAlarmGroup.persons.splice(this.selectedAlarmGroup.persons.indexOf(foundElement[0]), 1);
      this.isDirty = true

    }
  }

  /**
   * Select a group
   */
  selectGroup(group: AlarmGroup) {
    // set dirty to false on switch to not have an incorrect display in new alarm group edit
    if (angular.isDefined(this.selectedAlarmGroup)) {
      if ( this.selectedAlarmGroup.id === group.id) {
        // catch if someone clicks on group edit (e.g. name or color) while editing persons to not lose progress
        group.persons = this.selectedAlarmGroup.persons;
        this.selectedAlarmGroup = group;
        return;
      }
    }
      this.isDirty=false;
      this.selectedAlarmGroup = group;
  }


  /**
   * Add a new alarmGroup to the adressbook
   * */
  addAlarmGroup() {

    this.$uibModal.open({
      template: require('../../../modals/addressbook/add.alarm.group.modal/add.alarm.group.modal.html'),
      controller: 'AddAlarmGroupModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name: string, color: string) => {

            if (angular.isUndefined(name)) {
              //Invalid values, nothing to do
              this.$log.warn('Invalid values. Groupname not defined');
              return;
            }

            this.isLoadingAlarmGroups = true;
            var data = {
              name: name,
              color: color
            } as AlarmGroup;

            this.restService.addAlarmGroup(data, () => {
              this.loadAlarmGroups();
            }, (response) => {
              this.$log.error(response);
            });
          }
        }
      }
    });
  }


  /**
   * Remove an existing alarm group
   * */
  deleteAlarmGroup(alarmGroupSimple: AlarmGroupSimple) {
    this.isLoadingAlarmGroups = true;
    this.restService.deleteAlarmGroup(alarmGroupSimple.id,
      () => {
        this.isLoadingAlarmGroups = false;
        this.loadAlarmGroups();
      },
      (response) => {
        //Error occured
        this.isLoadingAlarmGroups = false;
        this.$log.error(response);
      }
    );
  }

  search() {
    this.params.currentPage = 0;
    this.loadPersons();
  }

  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.search();
  }


}
