'use strict';

import angular = require("angular");
import { not } from "angular-ui-router";
import { EEventFeedback, ETimeUnit, EventFeedback, EventFeedbackResponse, EventNotification } from "../../../../../data/event.data";

require('./event.feedback.component.css');

export default class EventFeedbackComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.scope = {
      feedback: '='
    };
    this.template = require('./event.feedback.component.html');

    this.controller = EventFeedbackController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class EventFeedbackController {
  public feedback: EventFeedbackResponse;
  private $scope: angular.IScope;

  public positive: number;
  public negative: number;
  public reservation: number;

  public lstPositive: EventFeedback[] = [];
  public lstNegative: EventFeedback[] = [];

  constructor($scope: angular.IScope) {
    this.$scope = $scope;
    this.$scope.$watch('ctrl.feedback', (feedback: EventFeedbackResponse) => {
      this.feedback = feedback;

      if (feedback) {
        // Calculate percentage
        this.positive = feedback.stats.positive / feedback.stats.total * 100;
        this.negative = feedback.stats.negative / feedback.stats.total * 100;
        this.reservation = feedback.stats.reservation / feedback.stats.total * 100;

        this.lstPositive = feedback.feedbacks.filter(person => person.feedback === EEventFeedback.YES || person.feedback === EEventFeedback.RESERVATION);
        this.lstNegative = feedback.feedbacks.filter(person => person.feedback === EEventFeedback.NO);
      }
    });
  }

  getFunctionsAsText(person: EventFeedback): string {
    return person.functions.join('\n');
  }

  getBackgroundColor(person: EventFeedback): string {
    if (person) {
      switch (person.feedback) {
        case EEventFeedback.NO:
          return '#992f2f';
        case EEventFeedback.YES:
          return '#53933f';
        case EEventFeedback.RESERVATION:
          return '#ff6600';
        case EEventFeedback.UNKNOWN:
          return '#333333';
      }
    }
    return '';
  }
}