'use strict';

import RestService from "../../../../../services/rest.service";
import { IScope } from "angular";
import { ReplacementSettings } from "../../../../../data/admin.data";

require('./admin.replacements.component.scss');

export default class AdminReplacementsSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.replacements.component.html');
    this.scope = {
    };
    this.controller = AdminReplacementsSettingsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminReplacementsSettingsController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;

  private settings: ReplacementSettings;


  constructor($scope: IScope, restService: RestService) {
    this.restService = restService;
    this.$scope = $scope;
    this.load();
  }

  private load(): void {
    this.isLoading = true;

    this.restService.loadReplacementSettings().then(settings => {
      this.settings = settings;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  public save(): void {
    this.isLoading = true;
    this.restService.saveReplacementSettings(this.settings).then(settings => {
      this.settings = settings;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}

