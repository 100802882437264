'use strict';

/* @ngInject */
export default class ChangeNumberModalController {
  private $uibModalInstance;
  public num: number;
  private okFunction: Function;

  constructor($uibModalInstance, num: number, okFunction: Function) {
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.num = num;
  }

  cancel() {
    this.$uibModalInstance.close();
  }
  save() {
    this.$uibModalInstance.close('cancel');
    this.okFunction(this.num);
  }
}