import { LicenceNumbers } from "../../../../../data/account.data";
import { AdjustLicenceType } from "../../../../../data/admin.data";

require('./admin.licence.settings.number.scss');

export default class AdminLicenceSettingsNumberComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
        this.restrict = 'A';
        this.template = require('./admin.licence.settings.number.html');
        this.scope = {
            numbers: '=',
            translation: '=',
            adjustLicenceType: '='
        };
        this.controller = LicenceNumberController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class LicenceNumberController {

    public numbers: LicenceNumbers;
    public translation: string;
    public adjustLicenceType?: AdjustLicenceType;

    constructor(private readonly $uibModal: any) { }

    public getAvailable() {
        return this.numbers.total - this.numbers.used;
    }

    /**
     * 
     * @returns label-danger if none are available, 
     */
    public getAvailableLabelClass() {
        const available = this.getAvailable();
        if (this.numbers.total === 0) return "label-danger";
        const fraction = available / this.numbers.total;
        if (fraction > 0.1) return "label-success";
        if (fraction > 0) return "label-warning";
        return "label-danger";
    }

    public hasAdjustLicenceType(): boolean {
        return !!this.adjustLicenceType;
    }

    public openAdjustLicenceModal() {
        if (!this.hasAdjustLicenceType()) return;


        this.$uibModal.open({
            template: require('../../../../modals/admin/licence.distribution.modal/licence.distribution.modal.html'),
            controller: 'LicenceDistributionModalController',
            controllerAs: 'ctrl',
            size: 'md',
            backdrop: 'static',
            resolve: {
                licence: () => this.adjustLicenceType,
                translation: () => this.translation
            }
        });
    }
}