'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import PrivilegeService from "../../../../services/privilege.service";

require('./layers.component.css');

export default class LayersComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {

      }
    this.template = require('./layers.component.html');

    this.controller = LayersController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityAccess
/* @ngInject */
class LayersController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $uibModal: any;
  public FileUploader: any;
  public $log: ILogService;
  public dataService: any;
  public layers = [];
  public listeners = [];
  public isLoading = false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModal, $log: ILogService, dataService, FileUploader,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.FileUploader = FileUploader;
    this.$log = $log;
    this.dataService = dataService;
    this.initListeners();
  }

  /**
   * Load all layers from server
   */
  load(forceReload: boolean) {
    this.isLoading = true;
    this.dataService.getLayers(forceReload, (resp) => {
      this.isLoading = false;
      this.layers = resp;
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });

  };

  /**
   * Add a new layer
   */
  addLayer() {
    const modalInstance = this.$uibModal.open({
      template: require('../../../modals/layers/import.layer.modal/import.layer.modal.html'),
      controller: 'ModalImportLayerInstanceController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          return new this.FileUploader();
        }
      }
    });

    modalInstance.result.then(() => {
      this.load(true);
    });
  }

  initListeners() {
    // Layers updated
    this.listeners.push(this.$rootScope.$on('layers.updated', (event, newLayers) => {
      this.layers = newLayers;
    }));

    // Layer update needed
    this.listeners.push(this.$rootScope.$on('layers.reload', (event) => {
      this.$log.debug('Reload event triggered');
      this.load(true);
    }));


    //Unregister listener
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}
