import RestService from "../../../../services/rest.service";
import angular = require("angular");
import {SimpleBuildingControllerResponse, BuildingControllerResponse} from "../../../../data/controller.data";

require('./admin.controller.component.scss')

export default class AdminControllerComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.controller.component.html');
    this.scope = {
      user: '='
    };
    this.controller = AdminControllerComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
/* @ngInject */
class AdminControllerComponentController {
  public controllers: SimpleBuildingControllerResponse[] = [];
  public isLoading: boolean;
  public isRefreshing = false;

  constructor(
    private readonly restService: RestService,
    private readonly $log: any,
    private readonly $scope: angular.IScope,
    private $uibModal: any) {
    this.loadControllers();
  }

  add(){
    const modalInstance = this.$uibModal.open({
      template: require('../../../modals/admin/add.controller.modal/add.controller.modal.html'),
      controller: 'AddControllerModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        okFunction: ()=> {
          return (buildingController) => {
            if (angular.isDefined(buildingController)) {
              this.openEditController(buildingController);
            }
          }
        }
    }
    });
  }

  /**
   * Load all building controllers
   */
  loadControllers() {
    this.isLoading = true;
    this.restService.getBuildingControllers()
      .then(result => this.controllers = result)
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  openSingleController(controller: SimpleBuildingControllerResponse) {
    this.isLoading = true;
    this.restService.getBuildingControllerById(controller.id).then(fullControllerResponse => {
      this.isLoading = false;
      this.openEditController(fullControllerResponse);
    }).catch(err => {
      this.isLoading = false;
    });
  }

  private openEditController(fullControllerResponse: BuildingControllerResponse) {
    const modalInstance = this.$uibModal.open({
      template: require('../../../modals/admin/edit.controller.modal/edit.controller.modal.html'),
      controller: 'EditControllerModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        controller: () => {
          return fullControllerResponse;
        }
      }
    });
    modalInstance.result.then(() => this.loadControllers());
  }

  deleteController(controller: SimpleBuildingControllerResponse) {
    this.isLoading = true;
    this.restService.deleteBuildingController(controller.id)
      .then(() => this.loadControllers())
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  /**
   * Refresh IOsatellite
   */
  refreshIOsattellites(): void {
    this.isRefreshing = true;
    this.restService.refreshIOsattellites()
      .then(result => this.controllers = result)
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isRefreshing = false;
        this.$scope.$applyAsync();
      });
  }
}