import {ILogService, IScope, IWindowService} from 'angular';
import {Calendar} from '../../../../data/calendar.data';
import {PersonSearchQueryResult} from '../../../../data/person.data';
import CalendarService from '../../../../services/calendar.service';
import PrivilegeService from '../../../../services/privilege.service';
import RestService from '../../../../services/rest.service';
import {
  EEventState,
  EEventType,
  ESerialAppointmentWeekday,
  EventAccess,
  EventFeedbackResponse
} from './../../../../data/event.data';

'use strict';

require('./event.modal.css');

/* @ngInject */
export default class EventModalController {
  public $uibModalInstance;
  public $uibModal;
  public $scope: IScope;
  public $window: IWindowService;
  public $http: angular.IHttpService;
  public calendarService: CalendarService;
  public $log: ILogService;
  public okFunction;
  public activeTabIndex = 0;
  public isNew = false;
  public isLoading = false;
  public isFeedbackLoading = false;
  public isSeries = false;
  public showAdvancedFormFields = false;
  public mode = CalendarEventMode.OVERALL;
  public fieldsDisabled = false;
  public eSerialAppointmentWeekday: typeof ESerialAppointmentWeekday = ESerialAppointmentWeekday;

  public rulesMax = {
    max: Number.MAX_SAFE_INTEGER,
    min: 0
  } as SpinnerRules;
  public rulesMin = {
    max: Number.MAX_SAFE_INTEGER,
    min: 0
  } as SpinnerRules;

  public restService: RestService;

  // Temporary data
  public startTime: Date;
  public endTime: Date;
  public latestPossibleFeedback: Date;

  public eventAccess: EventAccess;
  public calendars: Calendar[] = [];

  public feedback: EventFeedbackResponse;

  public eventWithFeedbackLimit: boolean;

  constructor($uibModalInstance, $uibModal, $window, $http, $scope: IScope, restService: RestService, $log: ILogService, calendarService: CalendarService, public privilegeService: PrivilegeService, okFunction, isNew: boolean, calendars: Calendar[], event: EventAccess, public helperService) {
    this.calendarService = calendarService;
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.$window = $window;
    this.$http = $http;
    this.restService = restService;
    this.okFunction = okFunction;
    this.isNew = isNew;
    this.eventAccess = event;
    this.calendars = calendars;
    // Init start and end
    this.startTime = new Date(event.event.startDate);
    this.endTime = new Date(event.event.endDate);

    this.eventWithFeedbackLimit = event.event.latestPossibleFeedback !== undefined && event.event.latestPossibleFeedback !== null;

    if (!event.event.latestPossibleFeedback) {
      this.latestPossibleFeedback = new Date(event.event.startDate);
      this.latestPossibleFeedback.setHours(this.latestPossibleFeedback.getHours() - 1);
    } else {
      this.latestPossibleFeedback = new Date(event.event.latestPossibleFeedback)
    }

    // when opening a full day event, we must subtract one calendar day to fix visualization of end date
    if (this.eventAccess.event.fullDay) {
      this.endTime.setDate(this.endTime.getDate() - 1);
    }

    if (!isNew) {
      this.isSeries = event.event.serialAppointmentId !== null;
      this.loadFeedbackForEvent();
      this.fieldsDisabled = this.eventAccess.event.status === EEventState.CANCELLED || this.eventAccess.event.fromIcalSync;
    }

    this.selectType(event.event.type);

  }

  everyXthSpecificDay() {
    if (this.eventAccess.event.serialAppointmentWeekday === this.eSerialAppointmentWeekday.NONE) {
      this.eventAccess.event.serialAppointmentWeekday = this.mapDayIntsToEnum(this.startTime.getDay());
    } else {
      this.eventAccess.event.serialAppointmentWeekday = this.eSerialAppointmentWeekday.NONE;
    }

  }

  getStartTimeDayTranslation() {
    const language = this.helperService.getFromStorage('language', 'de');
    if (language === 'en') {
      return this.startTime.toLocaleDateString("en-US", {weekday: "long"});
    }
    return this.startTime.toLocaleDateString("ger-DE", {weekday: "long"});
  }

  onParticipantsChanged() {
    if (this.eventAccess.event.minParticipants > 0) {
      this.rulesMax.max = Number.MAX_SAFE_INTEGER;
      if (this.eventAccess.event.maxParticipants !== 0) {
        this.rulesMax.min = this.eventAccess.event.minParticipants;
      } else {
        this.rulesMax.min = 0;
      }
    } else {
      this.rulesMax.max = Number.MAX_SAFE_INTEGER;
      this.rulesMax.min = 0;
    }

    if (this.eventAccess.event.maxParticipants > 0) {
      this.rulesMin.max = this.eventAccess.event.maxParticipants;
      this.rulesMin.min = 0;
    } else {
      this.rulesMin.max = Number.MAX_SAFE_INTEGER;
      this.rulesMin.min = 0;
    }
  }

  /**
   * The start time has been changed, adjust end time
   */
  onStartTimeChanged() {
    if (this.startTime > this.endTime) {
      const oldHour = this.endTime.getHours();
      const oldMinute = this.endTime.getMinutes();
      this.endTime = new Date(this.startTime.getTime());
      this.endTime.setHours(oldHour);
      this.endTime.setMinutes(oldMinute);
    }
  }

  /**
   * Close window
   */
  cancel() {
    this.okFunction();
    this.$uibModalInstance.close();
  }

  private convertTimes() {
    this.eventAccess.event.startDate = this.calendarService.dateToIsoString(this.startTime);
    this.eventAccess.event.endDate = this.calendarService.dateToIsoString(this.endTime);

    if (this.eventWithFeedbackLimit) {
      this.eventAccess.event.latestPossibleFeedback = this.calendarService.dateToIsoString(this.latestPossibleFeedback);
    } else {
      this.eventAccess.event.latestPossibleFeedback = "";
    }
  }

  saveAndEditFutures() {
    this.convertTimes();

    this.isLoading = true;
    // Update
    this.calendarService.saveEvent(this.eventAccess, false, true).then(() => {
      this.okFunction();
      this.$uibModalInstance.close();
    }).catch(error => {
      this.$log.error('Could not save event', error);
      this.isLoading = false;
    });
  }

  /**
   * Create or save new or existing event
   */
  save(notify: boolean) {
    this.convertTimes();

    this.isLoading = true;
    if (this.isNew) {
      // Create
      this.calendarService.createEvent(this.eventAccess, notify).then(() => {
        this.okFunction();
        this.$uibModalInstance.close();
      }).catch(error => {
        this.$log.error('Event creation failed', error);
        this.isLoading = false;
      });
    } else {
      // Update
      this.calendarService.saveEvent(this.eventAccess, notify, false).then(() => {
        this.okFunction();
        this.$uibModalInstance.close();
      }).catch(error => {
        this.$log.error('Could not save event', error);
        this.isLoading = false;
      });
    }
  }

  /**
   * Load feedback for a given event
   */
  loadFeedbackForEvent() {
    this.isFeedbackLoading = true;
    this.calendarService.getFeedbacksForEvent(this.eventAccess.event.calendarId, this.eventAccess.event.id).then(result => {
      this.isFeedbackLoading = false;
      this.$log.info('Feedback', result);
      this.feedback = result;
      this.$scope.$apply();
    }).catch(err => {
      this.isFeedbackLoading = false;
    });
  }

  changeCalendar(calendar: Calendar) {
    this.eventAccess.event.calendarId = calendar.id;
    this.eventAccess.event.calendarName = calendar.name;
    this.eventAccess.event.color = calendar.color;
  }

  /**
   * Delete an existing event
   */
  delete() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;

            this.calendarService.deleteEvent(this.eventAccess).then(() => {
              this.okFunction();
              this.$uibModalInstance.close();
            }).catch(err => {
              this.$log.error('Error deleting event', err);
              this.isLoading = false;
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  }

  /**
   * Delete an existing event
   */
  deleteSeries() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;

            this.calendarService.deleteEventSeries(this.eventAccess).then(() => {
              this.okFunction();
              this.$uibModalInstance.close();
            }).catch(err => {
              this.$log.error('Error deleting event series', err);
              this.isLoading = false;
            });
          };
        },
        additionalText: () => {
          return 'CALENDAR.EVENT.DELETE_SERIES_WARNING';
        }
      }
    });
  }

  export() {
    this.$window.open(this.restService.getBaseUrl() + '/eventPlanning/calendars/' + this.eventAccess.event.calendarId + '/events/' + this.eventAccess.event.id + '/export?Authorization=' + this.$http.defaults.headers.common.Authorization, '_blank');

  }

  /**
   * Toggle between full day and no full day
   */
  toggleFullDay() {
    this.eventAccess.event.fullDay = !this.eventAccess.event.fullDay;
  }

  toggleWithFeedback() {
    if (this.fieldsDisabled) {
      return;
    }
    this.eventAccess.event.withFeedback = !this.eventAccess.event.withFeedback;
  }

  toggleWithFeedbackLimit() {
    if (this.fieldsDisabled) {
      return;
    }
    this.eventWithFeedbackLimit = !this.eventWithFeedbackLimit;
  }

  /**
   * Select to correct event type
   * @param type EEventType
   */
  selectType(type: EEventType) {
    this.eventAccess.event.type = type;
    switch (type) {
      case EEventType.LESSON:
      case EEventType.TRAINING:
      case EEventType.COURSE:
        this.showAdvancedFormFields = true;
        break;
      default:
        this.showAdvancedFormFields = false;
        break;
    }
  }

  /**
   * Cancel existing event
   */
  cancelEvent() {
    this.$uibModal.open({
      template: require('./../cancel.event.modal/cancel.event.modal.html'),
      controller: 'CancelEventModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return (reason: string) => {
            this.isLoading = true;
            this.eventAccess.event.status = EEventState.CANCELLED;
            this.eventAccess.event.cancelledReason = reason;
            this.save(false);
          };
        }
      }
    });
  }

  /**
   * Select person
   */
  personSelected(person: PersonSearchQueryResult) {
    this.eventAccess.event.responsiblePerson = person.displayName;
  }

  /**
   * Auto complete persons
   */
  getPersons(val: string) {
    return this.restService.searchForPersons(val);
  }

  /**
   * Map the day of weak int from Date value to Used Enum constant
   * @param day number representing the selected day from start date
   * @private
   */
  private mapDayIntsToEnum(day: number): ESerialAppointmentWeekday {
    // indexing of int rep of weekdays starts with 0 on sunday
    if (day === 0) {
      return ESerialAppointmentWeekday.SUNDAY;
    }
    if (day === 1) {
      return ESerialAppointmentWeekday.MONDAY;
    }
    if (day === 2) {
      return ESerialAppointmentWeekday.TUESDAY;
    }
    if (day === 3) {
      return ESerialAppointmentWeekday.WEDNESDAY;
    }
    if (day === 4) {
      return ESerialAppointmentWeekday.THURSDAY;
    }
    if (day === 5) {
      return ESerialAppointmentWeekday.FRIDAY;
    }
    if (day === 6) {
      return ESerialAppointmentWeekday.SATURDAY;
    }
    return ESerialAppointmentWeekday.NONE;
  }

  duplicate() {
    this.isLoading = true;
    this.restService.duplicateEvent(this.eventAccess.event)
      .then(newEvent => {
        this.eventDuped(newEvent);
      })
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  eventDuped(event: EventAccess) {
    this.$uibModal.open({
      template: require('../../../modals/calendar/event.modal/event.modal.html'),
      controller: 'EventModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        event: () => {
          return event;
        },
        calendars: () => {
          return this.calendars;
        },
        isNew: () => {
          return false;
        },
        okFunction: () => {
          return () => {
            this.okFunction();
            return;
          };
        }
      }
    });
    this.$uibModalInstance.close();
  }

}

export interface SpinnerRules {
  max: number,
  min: number
}

export enum CalendarEventMode {
  OVERALL = 'OVERALL',
  DETAILS = 'DETAILS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  FEEDBACK = 'FEEDBACK'
}