'use strict';

require('./currentEvents.shiftbook.modal.css');

export default class CurrentEventsShiftbookModalController {
	public $uibModalInstance: any;
	public dashboardService: any;
	public $log: any;
	public events: any;
	public isLoading: any;

  constructor($uibModalInstance, $log, dashboardService) {
    this.$uibModalInstance = $uibModalInstance;
    this.dashboardService = dashboardService;
    this.$log = $log;

    this.events = [];

    this.getCurrentEventsFromServer();
  }

  close() {
    this.$uibModalInstance.close();
  }

  /**
   * Load current events from server
   */
  getCurrentEventsFromServer() {
    this.$log.debug('Loading todays events...');
    this.isLoading = true;
    this.dashboardService.getEventsInTimeFrame((response) => {
      this.events = response;
      this.$log.debug(this.events);
      this.isLoading = false;
    }, (error) => {
      this.$log.error('Could not load todays events', error);
      this.isLoading = false;
    });
  }
}
