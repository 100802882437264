import {Unit} from "../../../../data/unit.data";
import {IScope} from "angular";
import * as angular from "angular";
import {BaseField} from "../../../../data/basefield.data";

require('./code.map.base.field.scss')
export default class UnitCodeMapBaseFieldComponent{
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./code.map.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '=',
      units: '='
    };
    this.controller = UnitCodeMapBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
class UnitCodeMapBaseFieldController{
  public users: string[] = [];
  public field: BaseField;
  public searchFilter: string = '';
  public units: Map<string, Unit[]>;
  public $scope: IScope;
  public valueTemp:string;
  public editUnit:Unit;
  public mapping: Map<string, string>;
  public isLoading:boolean= false;

  constructor($scope: IScope) {
    this.users = [];
    this.$scope= $scope;
    $scope.$watch('ctrl.units', (newValue, oldValue) => {
      this.users = [];
      if (newValue) {
        this.users = Object.keys(newValue);
        this.mapping = new Map(Object.entries(this.field.value));
      }
    });



  }
  resetSearchAndReload(){
    this.searchFilter = '';
  }

  edit(unit:Unit){
    this.editUnit= unit;
    this.valueTemp = this.mapping.get(unit.code);
  }
  finishEdit(){
    this.editUnit = undefined;
    this.valueTemp= undefined;
  }

  shouldBeDisplayed(userName: string, unit: Unit): boolean {
    if (!this.searchFilter) {
      return true;
    }
    const searchFilterLowercase = this.searchFilter.toLowerCase();
    if (userName.toLowerCase().includes(searchFilterLowercase)) return true;
    if (unit.name.toLowerCase().includes(searchFilterLowercase)) return true;
    if (unit.code.toLowerCase().includes(searchFilterLowercase)) return true;
    return false;
  }

  getValue(unit:Unit):string{
    var value = this.mapping.get(unit.code);
    return value;
  }

  addValueToField(unit:Unit){
    if (angular.isUndefined(this.valueTemp)|| this.valueTemp===''){
       this.mapping.delete(unit.code);
    }else {
      this.mapping.set(unit.code, this.valueTemp);
    }
    // reverse map back to object, otherwise no values will be saved
    this.field.value= Object.fromEntries(this.mapping);
    this.$scope.$applyAsync();
  }
}