'use strict';

require('./list.entry.css');

import angular = require('angular');
export default class PersonListEntryComponent {
	public restrict: any;
	public scope: any;
	public template: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'EA',
      this.scope = {
        person: '='
      }
    this.template = require('./list.entry.html');

    this.controller = PersonListEntryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityList
/* @ngInject */
class PersonListEntryController {
	public $scope: any;
	public $rootScope: any;
	public $log: any;
	public $uibModal: any;
	public helperService: any;
	public dataService: any;
	public person: any;
	public isLoading: any;

  constructor($scope, $rootScope, $log, $uibModal, helperService, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.helperService = helperService;
    this.dataService = dataService;
  }

  /**
   * Returns true, if this function is excluded or not
   * @param {*} func
   */
  isFunctionExcluded(func) {
    if (angular.isDefined(this.person.excludedFunctions)) {
      return this.person.excludedFunctions.indexOf(func) !== -1;
    }
    return false;
  }

  /**
   * Returns true, if this group is excluded or not
   * @param {*} func
   */
  isGroupExcluded(group) {
    if (angular.isDefined(this.person.excludedGroups)) {
      return this.person.excludedGroups.indexOf(group) !== -1;
    }
    return false;
  }

  /**
   * Returns a css class for last updated state
   */
  lastUpdated() {
    var now = (new Date()).getTime();
    var diff = now - this.person.timeOfUpdate;
    var days = Math.round(diff / (1000 * 60 * 60 * 24));

    var faIcon = 'fa-circle';
    //Days
    if (days <= 7) {
      return 'fas ' + faIcon;
    } else {
      if (days > 31) {
        return 'far ' + faIcon;
      }
    }
    return 'fal ' + faIcon;
  }


  humanReadableDateTime() {
    return this.helperService.humanReadableDateTime(this.person.timeOfUpdate);
  }

  deletePerson($event) {
    $event.stopPropagation();

    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deleteOnlineServicePerson(this.person, () => {
                this.isLoading = false;
                this.$rootScope.$emit('os.person.deleted', this.person);
              },
              (response) => {
                //Error occured
                this.isLoading = false;
                this.$log.error(response);
              });
          };
        },
        additionalText: () => {
          return 'AV.DELETE_PERSON_HINT';
        }
      }
    });
  }
}
