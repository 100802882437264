'use strict'

import { IScope } from "angular";
import { IvenaMapping } from "../../../../data/ivena.data";
import RestService from "../../../../services/rest.service";
import { EHospitalServices } from "../../../../data/objects.data";

require("./ivena.mapping.modal.scss");

export default class IvenaMappingModalController {

    public mappings: IvenaMapping[] = [];
    public isLoading: boolean = false;

    public services: EHospitalServices[] = Object.values(EHospitalServices);

    constructor(
        private readonly $scope: IScope,
        private readonly restService: RestService,
        private readonly $uibModalInstance: any
    ) {
        this.services.sort((a, b) => a.localeCompare(b));
        this.loadMappings();
    }
    
    private async loadMappings() {
        this.isLoading = true;
        try {
            this.mappings = await this.restService.loadIvenaMappings();
            this.isLoading = false;
            this.$scope.$applyAsync();
        } catch (e) {
            // TODO handle exception with notification + modal close   
        }
    }

    public async save() {
        try {
            this.isLoading = true;
            await this.restService.saveIvenaMappings(this.mappings);
            this.isLoading = false;
            this.close();
        } catch (e) {
            // TODO handle
        }

    }

    public close() {
        this.$uibModalInstance.close();
    }

    public getClassForDropdown(mapping: IvenaMapping) {
        if (mapping.services === null) {
            return "btn-default";
        } else {
            return "btn-primary";
        }
    }

}