'use strict';

import { ILogService, IScope } from "angular";
import angular = require("angular");
import { RolePrivilege } from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./layer.row.component.css');

//imageAssetRow
export default class LayerRowComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./layer.row.component.html');
    this.scope = {
      layer: '=',
      base: '=',
      auth: '=',
      uploader: '='
    };
    this.controller = LayerRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class LayerRowComponentController {
  public $log: ILogService;
  public $scope: any;
  public dataService: any;
  public restService: RestService;
  public $uibModal: any;
  public errorService: any;
  public FileUploader: any;
  public userId: string;
  public isLoading = false;
  public isOwn = false;
  public isUploading = false;
  public isDeleting = false;
  public layer: any;
  public uploader: any;
  public hasLayerEdit:boolean=false;

  constructor($scope: IScope, $log: ILogService, restService: RestService, dataService, errorService, $uibModal, FileUploader, public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$scope = $scope;
    this.dataService = dataService;
    this.restService = restService;
    this.$uibModal = $uibModal;
    this.errorService = errorService;
    this.FileUploader = FileUploader;
    this.isLoading = false;
    this.userId = this.dataService.getAccount().id;
    this.isOwn = true;

    this.$scope.$watch('ctrl.layer', (newValue, oldValue) => {
      if (newValue) {
        this.hasLayerEdit = privilegeService.has(RolePrivilege.Objects_Layers_Edit);
        this.isOwn = this.layer.ownerId === this.userId;
      }
    });

    this.$scope.$on('colorpicker-closed', () => {
      this.onColorChange();
    });

  }

  showLayer() {
    this.$uibModal.open({
      template: require('../../../modals/layers/show.layer.modal/show.layer.modal.html'),
      controller: 'ModalShowLayerInstanceController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'hu',
      resolve: {
        layer: () => {
          return this.layer;
        }
      }
    });
  }


  /**
   * Delete image asset
   */
  delete() {
    this.isDeleting = true;
    this.dataService.deleteLayerByName(this.layer, () => {
      this.isDeleting = false;
    }, () => {
      this.isDeleting = false;
    });
  };

  /**
   * Toggle the public state of the layer
   */
  togglePublicState() {
    if (!this.hasLayerEdit) {
      return;
    }


    this.layer.publicLayer = !this.layer.publicLayer;

    this.isLoading = true;
    this.dataService.updateLayer(this.layer, (resp) => {
      this.isLoading = false;
      this.layer = resp;
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });
  }

  /**
   * Update color layer
   */
  onColorChange() {
    if (!this.hasLayerEdit) {
      return;
    }
    this.isLoading = true;
    this.dataService.updateLayer(this.layer, (resp) => {
      this.isLoading = false;
      this.layer = resp;
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });
  }

  /**
   * Update Layer
   */
  update() {
    this.$log.info('Queue: ' + this.uploader.queue.length);
    if (this.uploader.queue.length === 0) {
      return;
    }
    this.isUploading = true;

    this.uploader.onBeforeUploadItem = (item) => {
      //Change upload path
      item.url = item.url + '/' + encodeURIComponent(this.layer.name);
    };

    //Success
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.$log.info('Upload completed: ' + status);
      this.$log.info(response);
      angular.copy(response, this.layer);
      this.isUploading = false;
      // Add timestamp to force reload
    };
    //Error
    this.uploader.onErrorItem = (item, response, status) => {
      this.$log.error('Upload failed: ' + status);
      this.$log.error(response);
      this.isUploading = false;
      this.errorService.notifyWithText(response.message);
    };
    this.uploader.uploadAll();
  };


}
