export default class PageControlsComponent {
    public restrict: string;
    public scope;
    public template;
    public controller;
    public controllerAs: string;
    public bindToController: boolean;
    constructor() {
        this.restrict = 'E'
        this.scope = {
            model: "=",
            callback: "="
        }
        this.template = require('./page.controls.component.html');

        this.controller = Controller;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class Controller {

    public model: PageControlModel;
    public callback: Function;

    public next() {
        if (!this.model.last) {
            this.model.page++;
            this.callback();
        }
    }

    public previous() {
        if (!this.model.first) {
            this.model.page--;
            this.callback();
        }
    }

}

export interface PageControlModel {
    page: number;
    first: boolean;
    last: boolean;
}