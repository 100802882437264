import { EAdditionalLicence } from "./amobile.data";

export enum PluginCategory {
    SMS = 'SMS',
    PUSH = 'PUSH',
    TEXT = 'TEXT',
    MISC = 'MISC',
    RECOMMENDED = 'RECOMMENDED',
    VISUAL = 'VISUAL',
    RULES = 'RULES',
    FEEDBACK = 'FEEDBACK',
    EXPERT = 'EXPERT',
    INTERFACE = 'INTERFACE',
    BUILDING = 'BUILDING',
    FIRST_AIDER = 'FIRST_AIDER'
}
