'use strict'

import angular = require("angular");
import { AlarmObject, Coords, LockingSystem, LockingSystemType } from "../../../../data/objects.data";

require("./locking.system.component.scss");

export default class LockingSystemComponent {
    public restrict: any;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: any;
    public bindToController: any;

    constructor() {
        this.restrict = 'E'
        this.template = require('./locking.system.component.html');
        this.scope = {
            alarmObject: "=",
            isEditable: "="
        };
        this.controller = LockingSystemComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class LockingSystemComponentController {

    public isEditable: boolean;
    public alarmObject: AlarmObject;

    constructor(private $uibModal: any) { }

    public add() {
        this.alarmObject.lockingSystem.push({
            type: LockingSystemType.CYLINDER,
            hint: "",
            placeOfInstallation: "",
            serialNumber: "",
            coordinates: {
                lat: this.alarmObject.address.coords.lat,
                lng: this.alarmObject.address.coords.lng,
                showOnMap: true
            }
        });
    }

    public getTypes() {
        return Object.keys(LockingSystemType);
    }

    public showOnMap(element: LockingSystem) {

        if (!this.isEditable) return;

        if (angular.isUndefined(element.coordinates)) {
            element.coordinates = {
                lat: this.alarmObject.address.coords.lat,
                lng: this.alarmObject.address.coords.lng,
                showOnMap: true
            }
        }
        this.$uibModal.open({
            template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
            controller: 'ChooseOnMapCtrl',
            controllerAs: 'ctrl',
            size: 'lg',
            resolve: {
                coords: () => {
                    return element.coordinates;
                },
                okFunction: () => {
                    return (coords) => {
                        element.coordinates = coords;
                    }
                }
            }
        });
    }

    public delete(element: LockingSystem) {
        const index = this.alarmObject.lockingSystem.indexOf(element);
        this.alarmObject.lockingSystem.splice(index, 1);
    }
}