'use strict';

import { ILogService, IScope } from "angular";
import { ComingHomeSettings } from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";

require("./admin.coming.home.component.scss")

export default class AdminComingHomeComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.coming.home.component.html');
    this.scope = {
    };
    this.controller = AdminComingHomeComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class AdminComingHomeComponentController {

  public settings: ComingHomeSettings;
  public isLoading: boolean = false;

  constructor(private $scope: IScope,
    private $log: ILogService,
    private restService: RestService) {

    this.load();
  }

  save() {
    this.isLoading = true;
    this.restService.saveHomeComingSettings(this.settings)
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      })
  }

  load() {
    this.isLoading = true;
    this.restService.loadComingHomeSettings()
      .then(settings => this.settings = settings)
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }
}