'use strict';

//statusFilter
/* @ngInject */
export default class StatusFilter {
  static filter(status) {
    if (angular.isDefined(status)) {
      return "Status " + status.split("_")[1];
    }
    return status;
  }
}
