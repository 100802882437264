'use strict';

import { IScope } from "angular";
import { AAO, AAOEntryResponseElement, AAOVehicleAssignment } from "../../../../data/aao.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {EVehicleType, Vehicle, VehiclesSimplePaginatedResponse} from "../../../../data/vehicles.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";
import { VehicleModes } from "../vehicle.modal/vehicle.modal";

require('./aao.vehicles.css');

/* @ngInject */
export default class AAOVehiclesModalController {
  public $scope: IScope;
  public $uibModal;
  public $uibModalInstance: any;
  public restService: RestService;
  public isLoading: boolean = false;

  public vehicles: VehiclesSimplePaginatedResponse;
  public vehicleParams = {
    searchFilter: '',
    currentPage: 0,
    totalElements: 0,
    pageSize: 10
  } as SortParams;

  public entry: AAOEntryResponseElement;
  public aao: AAO;
  public okFunction;

  public orders: AAOVehicleAssignment[] = [];


  constructor($scope: IScope, $uibModalInstance, $uibModal, restService: RestService, aao: AAO, entry: AAOEntryResponseElement, okFunction,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.entry = entry;
    this.aao = aao;
    this.okFunction = okFunction;
    this.load();
  }

  load() {
    this.isLoading = true;
    this.restService.getAAOAssignments(this.aao, this.entry).then(assignments => {
      this.orders = assignments;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  addVehicleToAAO(vehicle: Vehicle) {
    const index = this.orders.filter(entry => entry.vehicle.id === vehicle.id).length;
    if (index > 0) return; // Vehicle is already part of AAO

    this.orders.push({
      order: 5,
      vehicle: vehicle,
      subOrder: this.orders.length
    });
    this.sort(this.orders);
  }

  setVehicleOrder(vehicle: AAOVehicleAssignment, newOrder: number) {
    vehicle.order = newOrder;
    vehicle.subOrder = -1;
    this.sort(this.orders);
  }

  removeVehicleOrder(vehicle: AAOVehicleAssignment) {
    const index = this.orders.indexOf(vehicle);
    if (index > -1) {
      this.orders.splice(index, 1);
    }
  }

  sort(orders: AAOVehicleAssignment[]): AAOVehicleAssignment[] {
    orders.sort((a, b) => {
      if (a.order !== b.order) return a.order - b.order;
      if (a.subOrder !== b.subOrder) return a.subOrder - b.subOrder;
      return a.vehicle.name.localeCompare(b.vehicle.name);
    });
    for (let i = 0; i < orders.length; i++) {
      orders[i].subOrder = i;
    }
    return orders;
  }

  searchForVehicle() {
    this.isLoading = true;

    this.restService.loadVehiclesPaginatedSimple(this.vehicleParams.currentPage === 0 ? 0 : this.vehicleParams.currentPage - 1,
      this.vehicleParams.pageSize,
      this.vehicleParams.searchFilter,
      'ASC').then(vehicles => {
        this.vehicles = vehicles;
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  openVehicle(vehicle: Vehicle) {
    if (vehicle.vehicleType != EVehicleType.SIREN && !this.privilegeService.has(RolePrivilege.Station_Vehicles_ReadDetails)) {
      return;
    }
    if (vehicle.vehicleType == EVehicleType.SIREN && !this.privilegeService.has(RolePrivilege.Station_Sirens_ReadDetails)) {
      return;
    }
    this.$uibModal.open({
      template: require('../vehicle.modal/vehicle.modal.html'),
      controller: 'VehicleModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicle: () => {
          return vehicle;
        },
        vehicleId: () => {
          return undefined;
        },
        okFunction: () => {
          return;
        },
        mode: () => VehicleModes.OVERVIEW
      }
    });
  }

  public moveVehicleUp(index: number) {
    this.moveVehicleInOrder(index, -1);
  }

  public moveVehicleDown(index: number) {
    this.moveVehicleInOrder(index, 1);
  }

  private moveVehicleInOrder(index: number, direction: -1 | 1) {
    if (direction === 1 && this.orders.length <= index + 1) {
      return;
    }
    if (direction === -1 && index - 1 < 0) {
      return;
    }

    const vehicle1 = this.orders[index];
    const vehicle2 = this.orders[index + direction];

    const temp = vehicle1.subOrder;
    vehicle1.subOrder = vehicle2.subOrder;
    vehicle2.subOrder = temp;

    this.sort(this.orders);
  }

  save() {
    this.isLoading = true;
    this.restService.saveAAOAssignments(this.aao, this.entry, this.orders).finally(() => {
      // Create new tooltip
      const vehicleHtmlPreview = this.orders.map(order => `<b>${order.order}.</b> ${order.vehicle.name}`).join('<br>');
      this.okFunction(vehicleHtmlPreview);
      this.$uibModalInstance.close();
    });
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  isSelected(vehicle: Vehicle): boolean {
    return this.orders.filter(e => e.vehicle.id === vehicle.id).length > 0;
  }
}
