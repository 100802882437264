'use strict';

//personFilter
/* @ngInject */
export default class PersonFilter {
  static filter(person) {
    var tmp = '';

    for (var property in person.data) {
      if (person.data.hasOwnProperty(property)) {
        // do stuff
        tmp = tmp + ', ' + person.data[property];
      }
    }
    return tmp;
  }
}
