'use strict';

/* @ngInject */
export default class ConfirmQueueCloseModalController {
	public $uibModalInstance: any;
	public okFunction: any;
	public reportMessage: string = '';

  constructor($uibModalInstance, okFunction) {

    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
  }


  cancel() {
    this.$uibModalInstance.close();
  };

  ok() {
    this.$uibModalInstance.close();
    this.okFunction(this.reportMessage);
  };

}
