import {Vehicle, VehicleTrackingMode} from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";
import {IScope, ILogService, IRootScopeService} from "angular";
import PrivilegeService from "../../../../services/privilege.service";
import * as angular from "angular";
import {RolePrivilege} from "../../../../data/privileges.enum";
require('./vehicle.trackingSettings.component.scss')
export default class VehicleTrackingSettingsComponent{
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.scope = {
      vehicle: '='
    }
    this.template = require('./vehicle.trackingSettings.component.html');

    this.controller = VehicleTrackingSettingsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
class VehicleTrackingSettingsController{
  public vehicle: Vehicle;
  private restService: RestService;
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public hasEditPermission: boolean;

  public isLipPolling = false;

  constructor(
    restService: RestService,
    $scope: angular.IScope,
    $log: angular.ILogService,
    $rootScope: angular.IRootScopeService,
    public privilegeService: PrivilegeService,
    private Notification,
    private $translate) {
    this.hasEditPermission = privilegeService.has(RolePrivilege.Station_Vehicles_Edit);
    this.restService = restService;
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
  }

  setTrackingMode(mode: VehicleTrackingMode) {
    this.vehicle.trackingMode = mode;
  }

  doLipPolling() {
    this.isLipPolling = true;
    this.restService.doLipPolling(this.vehicle.id).then(result => {
      this.vehicle.hasLocation = true;
      this.$translate('VEHICLES.TRACKING.NEW_POSITION').then((translation) => {
        this.Notification.success({
          message: translation,
          title: this.vehicle.name
        });
      });
    }).catch(err => {
      this.Notification.error({
        message: err.message
      });
    }).finally(() => {
      this.isLipPolling = false;
      this.$scope.$applyAsync();
    });
  }
}