
'use strict';

import {ILogService} from "angular";
import {WachbuchCategory, WachbuchMessage} from "../../../../data/wache.data";
import RestService from "../../../../services/rest.service";
import angular = require("angular");
import PrivilegeService from "../../../../services/privilege.service";


require('./messages.modal.css');

export default class MessagesModalController {
  public $uibModalInstance: any;
  public $log: ILogService;
  public dashboardService: any;
  public dataService: any;
  public restService: RestService;
  public oldCategoryId: any;
  public categories: WachbuchCategory[];
  public category: WachbuchCategory;
  public message: WachbuchMessage;
  public isAdding: boolean;
  public copyOfModel: any;
  public isLoading: boolean;

  constructor($uibModalInstance, $log: ILogService, dashboardService, restService: RestService, dataService,
    categories: WachbuchCategory[], category: WachbuchCategory, message: WachbuchMessage, public privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.dashboardService = dashboardService;
    this.dataService = dataService;
    this.restService = restService;

    this.oldCategoryId = undefined;
    this.categories = categories;
    this.category = category;
    if (angular.isDefined(message)) {
      // Editing
      this.message = message;
      this.isAdding = false;
      this.copyOfModel = angular.copy(this.message);
    } else {
      // Add new message
      this.isAdding = true;
      var date = new Date();
      date.setMilliseconds(0);
      date.setSeconds(0);

      this.message = {
        categoryId: this.category.categoryId,
        author: '',
        messageText: '',
        creationDate: date
      };
    }
  }

  close() {

    if (angular.isDefined(this.copyOfModel)) {
      angular.forEach(this.copyOfModel, (value, key) => {
        this.message[key] = value;
      });
    }

    this.$uibModalInstance.close();
  }

  // Autocomplete

  /**
   * Auto complete persons
   */
  getPersons(val) {
    return this.restService.searchForPersons(val);
  }

  /**
   * Select announcer
  */
  authorSelected(person) {
    this.message.author = person.displayName;
  }

  /**
   * Select a new category
   * @param {} cat
   */
  changeCategory(cat) {
    this.category = cat;
    this.oldCategoryId = this.message.categoryId;
    this.message.categoryId = this.category.categoryId;
  }

  /**
   * Delete existing message
   */
  delete() {
    this.isLoading = true;
    this.dashboardService.deleteShiftbookMessage(this.message, () => {
      this.isLoading = false;
      this.close();
    }, (errorResponse) => {
      this.isLoading = false;
      this.$log.error('Could not delete message', errorResponse);
    });
  }


  /**
   * Save the message
   */
  save() {
    this.isLoading = true;

    if (this.isAdding) {
      this.dashboardService.addNewShiftbookMessage(this.message, (response) => {
        this.isLoading = false;
        this.$uibModalInstance.close();
      }, (errorResponse) => {
        this.isLoading = false;
        this.$log.error('Could not add message', errorResponse);
      });
    } else {
      this.dashboardService.updateShiftbookMessage(this.message, this.oldCategoryId, (response) => {
        this.isLoading = false;
        this.$uibModalInstance.close();
      }, (errorResponse) => {
        this.isLoading = false;
        this.$log.error('Could not save message', errorResponse);
      });
    }
  }
}