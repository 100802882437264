'use strict';

import { IScope } from "angular";
import { AlarmObject } from "../../../../data/objects.data";

require('./object.links.component.css');

//objectRadio
export default class ObjectLinksComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.links.component.html');
    this.scope = {
      alarmObject: '='
    };
    this.controller = ObjectLinksComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectLinksComponentController {
  public $scope: IScope;
  public alarmObject: AlarmObject;

  constructor($scope: IScope) {
    this.$scope = $scope;

  }
}
