'use strict';

import { IScope } from "angular";
import { AlarmObject, AlarmObjectChange } from "../../../../data/objects.data";
import RestService from "../../../../services/rest.service";

require('./object.protocol.component.css');

//objectProtocol
export default class ObjectProtocolComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.protocol.component.html');
    this.scope = {
      alarmObject: '='
    };

    this.controller = ObjectProtocolController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectProtocolController {
  public alarmObject: AlarmObject;
  public changes: AlarmObjectChange[] = [];
  public isLoading = true;

  constructor($scope: IScope, restService: RestService) {


    $scope.$watch('ctrl.alarmObject', (oldValue, newValue) => {
      if (newValue) {
        restService.loadChangesForAlarmObject((newValue as AlarmObject).id).then(changes => {
          this.changes = changes;
        }).finally(() => {
          this.isLoading = false;
          $scope.$applyAsync();
        });
      }
    });

  }
}