'use strict';

import { IRootScopeService, IAngularEvent, IWindowService, IScope } from "angular";
import { BaseField } from "../../../../data/basefield.data";


require('./alarmtext.base.field.css');

export default class AlarmTextBaseFieldComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./alarmtext.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = AlarmTextBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}




/* @ngInject */
class AlarmTextBaseFieldController {
  field: BaseField;
  currentPosition: any;
  public listeners: any = new Array();


  constructor($rootScope: IRootScopeService, $scope: IScope, $window: IWindowService) {
    this.listeners.push($rootScope.$on('add.parameter.to.alarmtext', (event: IAngularEvent, param: string) => {
      // Got a new parameter < add to view value
      const oldValue = this.field.value;
      const position = $window.document.getElementById('itemTextArea')['selectionStart'];

      const newVal = [oldValue.slice(0, position), param, oldValue.slice(position)].join('');
      this.field.value = newVal;
    }));

    $scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}