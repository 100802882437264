'use strict';

/* @ngInject */
export default class FixedLengthFilter {
  static filter(input, fixedLength) {
    var num = parseInt(input, 10);
    fixedLength = parseInt(fixedLength, 10);
    if (isNaN(num) || isNaN(fixedLength)) {
      return n;
    }
    num = '' + num;
    while (num.length < fixedLength) {
      num = '0' + num;
    }
    return num;
  }
}
