import {IScope} from "angular";

export default class SavedeleteRolesModalController{
  public $scope: any;
  public $uibModalInstance: any;
  public okFunction: any;

  constructor($scope:IScope, $uibModalInstance, okFunction) {
    this.$scope= $scope;
    this.$uibModalInstance= $uibModalInstance;
    this.okFunction= okFunction;
  }

  cancel() {
    this.$uibModalInstance.close();
    this.okFunction(false);
  }

  ok() {
    this.$uibModalInstance.close();
    this.okFunction(true);
  }
}