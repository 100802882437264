'use strict'

import {ILogService, IRootScopeService, IScope} from "angular";
import RestService from "../../../../services/rest.service";
import MapService from "../../../../services/map.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RestProtocol} from "../../../../data/admin.data";

require("./rest.protocol.modal.scss");

/* @ngInject */
export default class RestProtocolModalController {

  public protocol: RestProtocol;

  public mode: ProtocolModalMode = ProtocolModalMode.MAIN_INFO;

  constructor(private $scope: IScope,
              private $rootScope: IRootScopeService,
              private $log: ILogService,
              private $uibModalInstance: any,
              public $uibModal: any,
              private restService: RestService,
              private $translate: any,
              private entryId: string,
              private mapService: MapService,
              public privilegeService: PrivilegeService) {

    this.loadProtocol(entryId);

  }

  parseDate(): Date {
    if (!this.protocol) return new Date();
    return new Date(this.protocol.timestamp);
  }

  private loadProtocol(entryId: string) {
    this.restService.getRestProtocolById(entryId, (response) => {
      this.protocol = response.data;
    }, (error) => {
      this.$log.error(error);
    });

  }

  cancel() {
    this.$uibModalInstance.close();
  }

  getResponseStatusClass(status) {
    if (status < 200) {
      return "class-status-1xx";
    }
    if (status >= 200 && status < 300) {
      return "class-status-2xx";
    }
    if (status >= 300 && status < 400) {
      return "class-status-3xx";
    }
    if (status >= 400 && status < 500) {
      return "class-status-4xx";
    }
    if (status >= 500) {
      return "class-status-5xx";
    }
  }

}

export enum ProtocolModalMode {
  MAIN_INFO = 'MAIN_INFO',
  RESPONSE_HEADERS = 'RESPONSE_HEADERS',
  REQUEST_PROPERTIES = 'REQUEST_PROPERTIES',
  RESPONSE_BODY = 'RESPONSE_BODY',
  REQUEST_HEADERS = 'REQUEST_HEADERS',
  REQUEST_BODY = 'REQUEST_BODY',
}