import { IScope } from 'angular';
'use strict'

import { DeviceResponse, DeviceStateChangeResponse, EDeviceState, EDeviceType } from "../../../../data/device.data";
import { Vehicle } from "../../../../data/vehicles.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import { SortParams } from '../../../views/addressbook.view.component/addressbook.view.component';
import { Page, Slice } from '../../../../data/misc.data';
import { RolePrivilege } from '../../../../data/privileges.enum';

require("./vehicle.devices.component.scss")



export default class VehicleDevicesComponent {

  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./vehicle.devices.component.html');
    this.scope = {
      vehicle: '='
    }
    this.controller = VehicleDevicesComponentController
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class VehicleDevicesComponentController {

  public devices: Page<DeviceResponse>;
  public vehicle: Vehicle;

  public isLoading = false;
  public isLoadingHistory = false;
  public isSavingStateChanges = false;

  public deviceTypes: EDeviceType[] = Object.values(EDeviceType);

  public isSelectionMode: boolean = false;
  public selectionModeTarget: EDeviceState = EDeviceState.USABLE;
  public selectedDevices: string[] = [];

  public history: Slice<DeviceStateChangeResponse>;

  // Page
  private params = {
    currentPage: 1,
    totalElements: 0,
    pageSize: 15
  } as SortParams;

  // History Page
  private historyParams = {
     currentPage: 1,
    totalElements: 0,
    pageSize: 5
  } as SortParams;

  constructor(private $scope: IScope,private $uibModal, private privilegeService: PrivilegeService, private restService: RestService) {
    $scope.$watch('ctrl.vehicle', (newVehicle) => {
      if (newVehicle) {
        this.load();
        this.loadHistory();
      }
    });
  }

  addDevice(): void {
    this.isLoading = true;
    this.restService.addDevice(this.vehicle.id).finally(() => {
      this.load();
      this.loadHistory();

    });
  }

  setType(type: EDeviceType, device: DeviceResponse): void {
    this.isLoading = true;
    device.deviceType = type;
    this.restService.saveDevice(device).finally(() => {
      this.load();
    });
  }

  setName(device: DeviceResponse): void {
    if (!this.privilegeService.has(RolePrivilege.Devices_Edit)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/misc/change.string.modal/change.string.modal.html'),
      controller: 'ChangeStringModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        str: () => {
          return device.name;
        },
        required: () => {
          return true;
        },
        okFunction: () => {
          return (newName: string) => {
            if (newName === '') return;
            device.name = newName;
            this.isLoading = true;
            this.restService.saveDevice(device).finally(() => {
              this.load();
            });
          }
        }
      }
    });
  }

  deleteDevice(entry: DeviceResponse) {
    if (this.isSelectionMode) return;
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.restService.deleteDevice(this.vehicle.id, entry.id)
              .then(() => this.load())
              .then(()=> this.loadHistory());
          }
        },
        additionalText: () => {
          return;
        }
      }
    });
  }

  load(): void {
    this.isLoading = true;
    this.restService.getDevicesForVehicle(this.vehicle.id, this.params.currentPage - 1, this.params.pageSize).then(devices => {
      this.devices = devices;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }


  loadHistory(): void {
    this.isLoadingHistory = true;
    this.restService.getDevicesHistoryForVehicle(this.vehicle.id, this.historyParams.currentPage - 1, this.historyParams.pageSize).then(history => {
      this.history = history;
    }).finally(() => {
      this.isLoadingHistory = false;
      this.$scope.$applyAsync();
    });
  }

  previousHistoryPage(): void {
    this.historyParams.currentPage--;
    this.loadHistory();
  }

  nextHistoryPage(): void {
    this.historyParams.currentPage++;
    this.loadHistory();
  }

  saveStateChange() {
    const newState = this.selectionModeTarget === EDeviceState.USABLE ? EDeviceState.NOT_USABLE : EDeviceState.USABLE;
    this.isSavingStateChanges = true;
    this.restService.changeDeviceState(this.vehicle.id, this.selectedDevices, newState)
      .then(res => {
        this.isSavingStateChanges = false;
        this.exitSelectionMode();
        this.$scope.$applyAsync();
        this.load();
        this.loadHistory();
      })
      .catch(() => {
        this.isSavingStateChanges = false;
        this.$scope.$applyAsync();
      });
  }

  deviceStateClicked(device: DeviceResponse) {
    if (!this.privilegeService.has(RolePrivilege.Devices_Change_State)) {
      return;
    }
    if (this.isSelectionMode) {
      this.toggleSelection(device);
    } else {
      this.enterSelectionMode(device);
    }
  }

  private enterSelectionMode(device: DeviceResponse) {
    this.isSelectionMode = true;
    this.selectionModeTarget = device.state;
    this.selectedDevices = [device.id];
    this.$scope.$applyAsync();
  }

  private exitSelectionMode() {
    this.isSelectionMode = false;
    this.selectedDevices = [];
    this.$scope.$applyAsync();
  }

  isSelected(device: DeviceResponse) {
    if (!this.isSelectionMode) return false;
    return this.selectedDevices.indexOf(device.id) !== -1;
  }

  private toggleSelection(device: DeviceResponse) {
    if (device.state !== this.selectionModeTarget) return;
    const index = this.selectedDevices.indexOf(device.id);
    if (index > -1) {
      this.selectedDevices.splice(index, 1);
    } else {
      this.selectedDevices.push(device.id);
    }
    if (this.selectedDevices.length === 0) {
      this.exitSelectionMode();
    }
  }
}