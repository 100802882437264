import { IScope } from "angular";
import { GamificationBadgeResult, LeaderboardResultEntry } from "../../../../data/leaderboard.data";
import { Person } from "../../../../data/person.data";
import RestService from "../../../../services/rest.service";

require('./person.badges.component.scss');
export default class PersonBadgesComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./person.badges.component.html');
    this.scope = {
      person: '='
    };
    this.controller = PersonBadgesController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class PersonBadgesController {
  private isLoading = false;
  public person: Person;
  private badges: GamificationBadgeResult[] = [];

  constructor(private $scope: IScope, private restService: RestService) {

    this.$scope.$watch("ctrl.person", ((newValue: Person) => {
      if (newValue) {
        this.loadBadges();
      }
    }));
  }

  /**
   * Load all badges for given person
   */
  private loadBadges() {
    this.isLoading = true;
    this.restService.getBadgesForPerson(this.person.personID).then((badges) => {
      this.badges = badges;
      console.table(this.badges);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  public getToolTip(badge: GamificationBadgeResult): string {
    if (badge.hasBadge) {
      return ' | ' + new Date(badge.dateOfAchievement).toLocaleDateString();
    }
    return '';
  }

  /**
   * Open the icon in a new window
   */
  public openBadge(badge: GamificationBadgeResult): void {
    const full = location.protocol + '//' + location.host;
    window.open(`${full}/img/static/badges/hd/${badge.icon}`, '_blank').focus();
  }
}