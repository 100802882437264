'use strict';

//conditionString
/* @ngInject */
export default class ConditionStringFilter {
  static filter(condition) {
    if (condition.comparison === 'FUNCTION') {
      if (condition.value === 1) {
        return 'AV.CONDITION_ONE_FUNCTION';
      }
      return 'AV.CONDITION_MULTIPLE_FUNCTION';
    } else if (condition.comparison === 'GROUP') {
      if (condition.value === 1) {
        return 'AV.CONDITION_ONE_GROUP';
      }
      return 'AV.CONDITION_MULTIPLE_GROUP';
    } else {
      if (condition.value === 1) {
        return 'AV.CONDITION_ONE';
      }
      return 'AV.CONDITION_MULTIPLE';
    }
  }
}