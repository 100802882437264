'use strict';

import { IRootScopeService, IScope } from "angular";
import angular = require("angular");
import { UserAccount } from "../../../../data/account.data";
import { AlarmData, AlarmFlow } from "../../../../data/alarm.data";

require('./alarm.table.component.css');

export default class AlarmTableComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./alarm.table.component.html');
    this.scope = {
      alarm: '='
    };
    this.controller = AlarmTableComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  };
};
class AlarmTableComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public listeners = [];
  public data: AlarmParam[] = [];
  public filteredData: AlarmParam[] = [];
  public alarm: AlarmData;
  public dataService;
  public searchFilter: string = '';

  private account: UserAccount;

  constructor($scope: IScope, $rootScope: IRootScopeService, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.listeners = [];
    this.dataService = dataService;
    this.account = this.dataService.getAccount();

    this.listeners.push(this.$scope.$watch('ctrl.alarm', (newValue, oldValue) => {
      if (newValue) {
        this.data = [];

        angular.forEach(this.alarm.parameters, (value: string, key: string) => {
          if (key.startsWith('hd_')) {
            // All parameter with hd_ are hidden fields
            return;
          }
          this.data.push({
            param: key,
            value: value
          } as AlarmParam);
          this.resetSearchFilter();
        });
      }
    }, false));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  displayAsOwnUnit(alarmFlow:AlarmFlow){
    return this.isOwnUnit(alarmFlow) && !this.isCurrentUnit(alarmFlow) && !this.isAlarmInput(alarmFlow);
  }

  displayAsCurrentUnit(alarmFlow:AlarmFlow){
    return this.isOwnUnit(alarmFlow) &&this.isCurrentUnit(alarmFlow)&& !this.isAlarmInput(alarmFlow);
  }
  displayAsDefault(alarFlow:AlarmFlow){
    return !this.isOwnUnit(alarFlow) && !this.isAlarmInput(alarFlow);
  }

  isAlarmInput(alarmFlow:AlarmFlow){
    return alarmFlow.organisation === 'Alarmeingang';
  }

  isCurrentUnit(alarmFlow: AlarmFlow) {
    if (angular.isDefined(alarmFlow)) {
      return alarmFlow.name === this.alarm.unit;
    }
  }

  isOwnUnit(alarmFlow: AlarmFlow) {
    if (angular.isDefined(alarmFlow)) {
      if (alarmFlow.organisation) {
        return alarmFlow.organisation === this.account.username;
      }
    }
    return false;
  }

  onSearchFilterChanged() {
    const tmp = this.searchFilter.toLocaleLowerCase();
    this.filteredData = this.data.filter(entry => {
      return entry.value.toLocaleLowerCase().includes(tmp) || entry.param.toLocaleLowerCase().includes(tmp);
    });
  }

  resetSearchFilter() {
    this.searchFilter = '';
    this.filteredData = [];
    this.data.forEach(entry => this.filteredData.push(entry));
  }

}

interface AlarmParam {
  param: string;
  value: string;
}
