'use strict';

import angular = require("angular");
import { KbaRequest } from "../../../../../data/admin.data";

require('./admin.kba.tablerow.component.scss');

export default class KbaTablerowComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      request: '='
    }
    this.template = require('./admin.kba.tablerow.component.html');
    this.controller = KbaTablerowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//parking
/* @ngInject */
class KbaTablerowComponentController {
  public $scope: angular.IScope;
  public $translate: any;
  public request: KbaRequest;
  constructor($scope: angular.IScope) {
    this.$scope = $scope;
  }
}
