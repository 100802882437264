import { AlarmData } from './../../../../data/alarm.data';
'use strict';

export default class AlarmErrorsComponent {
  public restrict: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;
  public scope: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./alarm.errors.component.html');
    this.controller = AlarmErrorsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.scope = {
      alarm: '='
    };
  }
}

/* @ngInject */
class AlarmErrorsComponentController {
  public $scope: any;
  public errorLst: any;
  public alarm: AlarmData;
  public warningLst: any;

  constructor($scope: angular.IScope) {
    this.$scope = $scope;
    this.errorLst = [];
    //Watch for changes
    this.$scope.$watch('ctrl.alarm', (newValue, oldValue) => {
      if (newValue) {
        this.createListOfErrors();
        this.createListOfWarnings();
      }
    }, false);
  }

  createListOfErrors() {
    this.errorLst = [];
    var lst = this.alarm.errorList;
    for (var i = 0; i < lst.length; i++) {
      var element = lst[i]

      for (var ii = 0; ii < element.errors.length; ii++) {
        var singleError = element.errors[ii];
        var errno = '';
        if (singleError.startsWith('ERRNO#')) {
          errno = singleError.substring(6, 10);
          singleError = singleError.substring(10);
        }
        this.errorLst.push({
          source: element.pluginName,
          text: singleError,
          hasHelp: errno !== '',
          errno: errno
        });
      }
    }
  }

  createListOfWarnings() {
    this.warningLst = [];
    var lst = this.alarm.warningList;
    for (var i = 0; i < lst.length; i++) {
      var element = lst[i]

      for (var ii = 0; ii < element.warnings.length; ii++) {
        var singleWarning = element.warnings[ii];
        var warnNo = '';
        if (singleWarning.startsWith('WARNNO#')) {
          warnNo = singleWarning.substring(6, 10);
          singleWarning = singleWarning.substring(10);
        }
        this.warningLst.push({
          source: element.pluginName,
          text: singleWarning,
          hasHelp: warnNo !== '',
          errno: warnNo
        });
      }
    }
  }
}
