'use strict';

import { ILogService, IScope } from "angular";
import { AlarmDataSimple } from "../../../../data/alarm.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";

require('./alarm.row.entry.component.css');

export default class AlarmRowEntryComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A';
    this.template = require('./alarm.row.entry.component.html');
    this.scope = {
      alarm: '=',
      deletionOfAlarmsDeactivated: '=',
      isShiftBookActive: '='
    };
    this.controller = AlarmRowEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  };
};
class AlarmRowEntryComponentController {
  public $log: ILogService;
  public dataService: any;
  public helperService: any;
  public alarm: AlarmDataSimple;
  public isDeleting = false;
  public css: string = 'success';

  constructor($log: ILogService, $scope: IScope, dataService, helperService, public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;

    $scope.$watch('ctrl.alarm', (() => {
      if (this.alarm) {
        if (this.alarm.error) {
          this.css = 'danger'
        } else if (this.alarm.warning) {
          this.css = 'warning';
        } else if (this.alarm.revisionActive) {
          this.css = 'warning';
        } else {
          this.css = 'success';
        }
      }
    }));
  }

  /**
   * Toggle the important flag
   */
  toogleImportantState($event) {
    $event.stopPropagation();
    if (!this.privilegeService.has(RolePrivilege.Home_Alarms_MarkImportant)) {
      return;
    }
    var isImportant = !this.alarm.important;
    this.dataService.updateAlarmImportantState(this.alarm, isImportant, () => {
      // Success
      this.$log.info('Important flag updated');
    }, (errorResponse) => {
      // Error updating state
      this.$log.error(errorResponse);
    });
  }

  /**
   * Delete an existing alarm
   */
  deleteAlarm($event) {
    this.isDeleting = true;
    $event.stopPropagation();
    this.dataService.deleteAlarm(this.alarm, () => {
      this.$log.info('Alarm deleted!');
      this.isDeleting = false;
    }, (errorResponse) => {
      //Error occured
      this.$log.error(errorResponse);
      this.isDeleting = false;
    });
  };


}
