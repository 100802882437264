'use strict';

//filterDropdown
/* @ngInject */
export default class DropdownFilter {
  static filter(input) {
    if (angular.isUndefined(input)) {
        return input;
    }
    if (input.indexOf('#-#') !== -1){
        return input.substring(0, input.indexOf('#-#'));
    }
    return input;
  }
}