import { ILogService, IScope } from 'angular';
import { IRootScopeService } from 'angular';
import { RolePrivilege } from '../../../../data/privileges.enum';
import CalendarService from '../../../../services/calendar.service';
import PrivilegeService from '../../../../services/privilege.service';
import { ECalendarType, CalendarAccess, CalendarSimple } from './../../../../data/calendar.data';
'use strict';

require('./calendar.list.component.css');


export default class CalendarListComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./calendar.list.component.html');
    this.controller = CalendarListController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class CalendarListController {

  public calendars: CalendarSimple[] = [];
  public $uibModal;
  public $log: ILogService;
  public calendarService: CalendarService;
  public isLoading: boolean;
  public creatingCalendar: boolean;
  public $scope: IScope;

  constructor($uibModal, $scope: IScope, $log: ILogService, calendarService: CalendarService, public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.calendarService = calendarService;
    this.loadCalendars()
  }

  loadCalendars() {
    this.isLoading = true;
    this.calendarService.getCalendars().then(response => {
      this.calendars = response;
      this.isLoading = false;
      this.$scope.$apply();
    }).catch(error => {
      this.$log.error('Error getting simple calendars:', error);
      this.isLoading = false;
    });
  }

  addCalendar(type: ECalendarType) {
    this.creatingCalendar = true;
    this.calendarService.getCalendarTemplate(type).then((calendarAccess: CalendarAccess) => {
      this.creatingCalendar = false;
      this.openModalForCalendar(calendarAccess, true);
    }).catch(error => {
      this.$log.error(error);
    });
  }

  /**
   * Create a new ICal Calendar
   */
  addICalCalendar() {
    this.creatingCalendar = true;
    this.calendarService.getCalendarTemplate(ECalendarType.GENERAL).then((calendarAccess: CalendarAccess) => {
      calendarAccess.calendar.icalSyncCalendar = true;
      this.creatingCalendar = false;
      this.openModalForCalendar(calendarAccess, true);
    }).catch(error => {
      this.$log.error(error);
    });
  }

  editCalendar(calendar: CalendarSimple) {
    if (!this.privilegeService.has(RolePrivilege.EventPlanning_Calendar_Edit)) {
      return;
    }
    // Open Modal to edit calendar
    this.openModalForCalendar({ calendar: calendar } as CalendarAccess, false);
  }


  openModalForCalendar(cal: CalendarAccess, isNewCalendar: boolean) {
    this.$uibModal.open({
      template: require('../../../modals/calendar/calendar.modal/calendar.modal.html'),
      controller: 'CalendarModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        calendar: () => {
          return cal;
        },
        isNew: () => {
          return isNewCalendar;
        },
        okFunction: () => {
          return () => {
            this.loadCalendars();
          };
        }
      }
    });
  }
}