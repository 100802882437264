import RestService from "../../../../services/rest.service";
import {ILogService, IRootScopeService, IScope} from "angular";
import {UserAccount} from "../../../../data/account.data";
import {Vehicle, VehiclesPaginatedResponse} from "../../../../data/vehicles.data";
import {SortParams} from "../../../views/addressbook.view.component/addressbook.view.component";
import angular = require("angular");
import {User, UserAdminContext} from "../../../../data/admin.data";

require('./admin.users.vehicles.component.css')

export default class UserVehicleAccessComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.vehicles.component.html');
    this.scope = {
      user: '='
    };
    this.controller = UserVehicleAccessController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
/* @ngInject */
class UserVehicleAccessController{
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public user: User;
  public vehicles: Vehicle[] =[];
  public selectedVehicles: Vehicle[]= [];
  public isLoadingVehicles:boolean;
  public isLoadingVehiclesWithAccess: boolean;
  public isSaving = false;
  public isRemoving = false;
  //parameters for all vehicles
  public params = {
    sortType: 'name', // set the default sort type
    sortReverse: false, // set the default sort order
    searchFilter: '', // set the default search/filter term
    currentPage: 0,
    totalElements: 0,
    pageSize: 15
  } as SortParams;
  // parameters for vehicles selected for organisatin
  public paramsSelected = {
    sortType: 'name', // set the default sort type
    sortReverse: false, // set the default sort order
    searchFilter: '', // set the default search/filter term
    currentPage: 0,
    totalElements: 0,
    pageSize: 15
  } as SortParams;


  constructor(restService: RestService, $scope: angular.IScope, $rootScope: angular.IRootScopeService) {
    this.restService = restService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.selectedVehicles = [];
    this.vehicles = [];
    // get user when it is set, otherwise user will always be undefined
    $scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (angular.isDefined(newValue)) {
        this.user = newValue
        if (this.user.admin) {
          return; // Don't need to load enything for admin
        }
        this.loadVehiclesWithAccess();
        this.loadVehicles();
      }
    });
  }

  /**
   * make Http call to add a vehicle to an organisation.
   * @param vehicle
   */
  saveVehiclesAccess(vehicle:Vehicle){
    this.restService.addAccessToVehicle(this.user, vehicle).then((success) => {
      this.loadVehiclesWithAccess();
      }
    );
  }

  /**
   * remove access of vehicle from organisation.
   * @param vehicle
   */
  removeFromOrganisation(vehicle:Vehicle){
    const foundVehicle= this.selectedVehicles.filter(vehicleL => vehicleL.id === vehicle.id);

    if (foundVehicle.length>0){
      this.selectedVehicles.splice(this.selectedVehicles.indexOf(foundVehicle[0]), 1);
    }

    this.restService.removeAccessToVehicle(this.user, vehicle).then((success) => {
      this.$scope.$applyAsync();
    })

  }
  removeAllVisible(){
    this.selectedVehicles.forEach(vehicle=> {
      this.restService.removeAccessToVehicle(this.user, vehicle).then(() => {
        this.loadVehiclesWithAccess();
      });
    });
  }

  removeAllVehiclesToOrganisation() {
    this.isRemoving = true;
    this.restService.removeAccessToAllVehicles(this.user).then(()=>{
      this.loadVehiclesWithAccess();
    }).finally(() => {
      this.isRemoving = false;
      this.$scope.$applyAsync();
    });

  }

  /**
   * add all vehicles currently displayed in all vehicles list
   */
  addAllVisible(){
    this.vehicles.forEach(vehicle=> this.addToOrganisation(vehicle));
  }

  addAllVehiclesToOrganisation() {
    this.isSaving = true;
    this.restService.addAccessToAllVehicles(this.user).then(()=>{
      this.loadVehiclesWithAccess();
    }).finally(() => {
      this.isSaving = false;
      this.$scope.$applyAsync();
    });

  }

  /**
   * method handling adding a vehicle to an organisation.
   * @param vehicle
   */
  addToOrganisation(vehicle:Vehicle){
    if(!this.vehicleIsSelectedInOrganisation(vehicle)){
      this.selectedVehicles.push(vehicle);
    }

    this.saveVehiclesAccess(vehicle);

  }

  /**
   * check if access is already set for a vehicle
   * @param vehicle
   */
  vehicleIsSelectedInOrganisation(vehicle:Vehicle){
    if (angular.isUndefined(this.selectedVehicles)){
      return;
    }
    var list = this.selectedVehicles.filter(vehicleL => vehicleL.id === vehicle.id);
    return list.length ===1;
  }

  /**
   * load all vehicles paginated from backend
   */
  loadVehicles(){
    this.isLoadingVehicles = true;
    this.restService.loadVehiclesPaginatedAsPromise(this.params.currentPage === 0 ? 0 :this.params.currentPage - 1,
      this.params.pageSize,
      this.params.searchFilter,
      this.params.sortReverse ? 'DESC' : 'ASC').then((result:VehiclesPaginatedResponse) =>{
      if (angular.isDefined(result.data)) {
        this.vehicles = result.data;
      }
          this.params.totalElements = result.totalElements;
          this.params.totalPages = result.totalPages;
    }).finally(() => {
      this.isLoadingVehicles = false;
      this.$scope.$applyAsync();
    });

  }

  /**
   * load vehicles paginated that organisation has access to from backend
   */
  loadVehiclesWithAccess(){
    if(angular.isUndefined(this.user)){
      return;
    }
    this.isLoadingVehiclesWithAccess = true;
    this.restService.loadVehiclesPaginatedAsPromiseWithOrganisationAccess(this.paramsSelected.currentPage === 0 ? 0 :this.paramsSelected.currentPage - 1,
      this.paramsSelected.pageSize,
      this.paramsSelected.searchFilter,
      this.paramsSelected.sortReverse ? 'DESC' : 'ASC',
      this.user)
      .then((result:VehiclesPaginatedResponse) =>{
        if (angular.isDefined(result.data)) {
          this.selectedVehicles = result.data;
        }
        this.paramsSelected.totalElements = result.totalElements;
        this.paramsSelected.totalPages = result.totalPages;
      }
    ).finally(()=>{
      this.isLoadingVehiclesWithAccess = false;
      this.$scope.$applyAsync();
    })

  }

  /**
   * search in all vehicles colum
   */
  search() {
    this.params.currentPage = 0;
    this.loadVehicles();
  }

  /**
   * search in accessible vehicles colum
   */
  searchSelected(){
    this.paramsSelected.currentPage = 0;
    this.loadVehiclesWithAccess();

  }

  /**
   * reset search in accessible vehicles colum
   */
  resetSearchAndReloadForSelected(){
    this.paramsSelected.searchFilter = '';
    this.searchSelected();
  }
  /**
   * reset search in all vehicles colum
   */
  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.search();
  }
}