'use strict';

import angular = require('angular');

export default class ObjectDangerObjectsComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.danger.objects.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectDangerObjectsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectDangerObjectsComponentController {
	public $uibModal: any;
	public alarmObject: any;
	public isEditable: any;

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  /**
   * Add a new object
   */
  addObject() {
    var data = {
      location: '',
      type: '',
      note: ''
    }
    if (angular.isUndefined(this.alarmObject.risks.objects)) {
      this.alarmObject.risks.objects = [];
    }
    this.alarmObject.risks.objects.push(data);
  };

  /**
   * Delete existing object
   * @param {*} object
   */
  deleteObject(data) {
    this.alarmObject.risks.objects.splice(this.alarmObject.risks.objects.indexOf(data), 1);
  };


  /**
   * Show on map
   * @param {*} element
   */
  showOnMap(element) {
    if (this.isEditable) {
      if (angular.isUndefined(element.coords)) {
        element.coords = {
          lat: this.alarmObject.address.coords.lat,
          lng: this.alarmObject.address.coords.lng,
          showOnMap: true
        }
      }
      this.$uibModal.open({
        template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
        controller: 'ChooseOnMapCtrl',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          coords: () => {
            return element.coords;
          },
          okFunction: () => {
            return (coords) => {
              element.coords = coords;
            }
          }
        }
      });
    };
  }
}