'use strict';

import { AMobileMapBaseLayer, EFE2Features, Settings } from "../../../../data/account.data";
import AccountService from "../../../../services/account.service";


require('./settings.amobile.layers.component.scss');

export default class SettingsAmobileLayersComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'E'
    this.scope = {
      settings: "="
    }
    this.template = require('./settings.amobile.layers.component.html');

    this.controller = SettingsAmobileLayersComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class SettingsAmobileLayersComponentController {

    public settings: Settings;

    constructor(private readonly accountService: AccountService) {}

    public getAvailableLayers() {
        let layers = Object.values(AMobileMapBaseLayer);
        if (!this.accountService.isFeatureEnabled(EFE2Features.HERE_MAPS)) {
            layers = layers.filter(layer => !layer.startsWith("Here_"));
        }
        if (!this.accountService.isFeatureEnabled(EFE2Features.NAVLOG)) {
            layers = layers.filter(layer => !layer.startsWith("NavLog_"));
        }
        
        return layers;
    }

    public toggleLayer(layer: AMobileMapBaseLayer) {
        if (layer === AMobileMapBaseLayer.OpenStreetMap) {
            // Not allowed to toggle
            return;
        }
        const index = this.settings.amobileBaseLayers.indexOf(layer);
        if (index === -1) {
            this.settings.amobileBaseLayers.push(layer);
        } else {
            this.settings.amobileBaseLayers.splice(index, 1);
        }
    }

    public isLayerSelected(layer: AMobileMapBaseLayer): boolean {
        return this.settings.amobileBaseLayers.indexOf(layer) !== -1;
    }

}
