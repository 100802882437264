'use strict';

import {
  template
} from 'babel-core';
import { AlarmTemplate } from '../../../../data/template.data';
import {stat} from "copy-webpack-plugin/types/utils";

require('./alarm.template.button.component.css');

//alarmTemplateButton
export default class AlarmTemplateButtonComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.template.button.component.html');
    this.scope = {
      template: '='
    };
    this.controller = AlarmTemplateButtonComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmTemplateButtonComponentController {
  public $uibModal: any;
  public template: AlarmTemplate;

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  getToolTip() {
    if (this.template.note) {
      return this.template.note.replaceAll(/\n/g, '<br>');
    }
    return '';
  }

  /**
   * Alert a template
   */
  alert() {
    this.$uibModal.open({
      template: require('./../../../modals/pipeline/confirm.template.modal/confirm.template.modal.html'),
      controller: 'ConfirmTemplateModalController',
      backdrop: 'static', // make it static so editing is easier on detailed view
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        template: () => {
          return this.template;
        }
      }
    });
  };
}