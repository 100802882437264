'use strict';

/* @ngInject */
export default class CopyFromVehicleController {
	public $scope: any;
	public $uibModalInstance: any;
	public $log: any;
	public dataService: any;
	public vehicle: any;
	public vehicles: any;

    constructor($scope, $uibModalInstance, $log, dataService) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$log = $log;
        this.dataService = dataService;

        this.vehicle = undefined;

        this.dataService.getVehicles(false, (vehicles) => {
            this.vehicles = vehicles;
        }, (errorResponse) => {
            //Error occured
            this.$log.error(errorResponse);
        });
    }

    selectVehicle(vehicle) {
        this.vehicle = vehicle;
    }

    cancel() {
        this.$uibModalInstance.close();
    }

    copy() {
        this.$uibModalInstance.close(this.vehicle);
    }
}