'use strict'

import {ILogService, IRootScopeService, IScope} from "angular";
import {AlarmDataSimple, AlarmVehicle} from "../../../../data/alarm.data";
import {Emergency} from "../../../../data/emergency.data";
import RestService from "../../../../services/rest.service";
import MapService from "../../../../services/map.service";
import * as L from 'leaflet';
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require("./mission.modal.scss");

/* @ngInject */
export default class MissionModalController {

  public emergency: Emergency;
  public alarmDataForMap: any;

  private map: L.Map;
  private isSavingReport = false;
  private listeners = [];
  private isLoading = true;

  public mode: MissionModalMode = MissionModalMode.ALARM;
  private NO_VALUE: string = "Keine Daten";

  constructor(private $scope: IScope,
    private $rootScope: IRootScopeService,
    private $log: ILogService,
    private $uibModalInstance: any,
    public $uibModal: any,
    private restService: RestService,
    private $translate: any,
    private externalId: string,
    private parentUserId: string | undefined,
    private allAlarmsDeletedFunction,
    private mapService: MapService,
    public privilegeService: PrivilegeService) {

    this.loadEmergency(externalId, parentUserId);

    this.$translate(['MISSIONS.NO_VALUE']).then((translations) => {
      this.NO_VALUE = translations['MISSIONS.NO_VALUE'];
      this.$scope.$applyAsync();
    });

    this.initListeners();
  }

  private loadEmergency(externalId: string, userId?: string) {
    this.restService.getEmergencyByExternalId(externalId, userId)
      .then(emergency => {
        if (emergency.externalId == null) { // all alarms for this emergency were deleted, thus we don't have any data remaining
          this.$uibModalInstance.close();
          this.allAlarmsDeletedFunction();
          return;
        }
        this.emergency = emergency;
        this.alarmDataForMap = this.getAlarmDataForMap();
        this.emergency.vehicles = this.distinctVehicles(this.emergency.vehicles);
        this.buildMapImage();

        if (this.emergency.hasLocationMap) {
          this.getImages();
        }

      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });

  }

  displayExportTab(): boolean {
    if (this.emergency?.allAlarmsDeleted){
      // never display export if no alarms exist any longer
      return false;
    }
    return this.privilegeService.privileges['Home_Emergency_Details_Export_Protocol'] ||
    this.privilegeService.privileges['Home_Emergency_Details_Export_Diary'] ||
    this.privilegeService.privileges['Home_Emergency_Details_Export_LocationMap'];
  }

  getImages() {
    this.restService.downloadEmergencyImages(this.emergency.mapId, false)
      .then(images => {
        this.emergency.images = images;
        for (let i = 0; i < this.emergency.images.length; i++) {
          this.emergency.images[i].isOpen = false;
        }
      }).finally(() => this.$scope.$applyAsync());
  }

  initListeners() {
    //Wait for new alarms
    this.listeners.push(this.$rootScope.$on('new.alarm', (event, alarm: AlarmDataSimple) => {
      if (alarm.externalId !== this.externalId) return;
      this.loadEmergency(this.externalId, this.parentUserId);
    }));

    // Wait for deletion of an alarm
    this.listeners.push(this.$rootScope.$on('single.alarm.deleted', (event, alarm: AlarmDataSimple) => {
      if (alarm.externalId !== this.externalId) return;
      this.loadEmergency(this.externalId, this.parentUserId);
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * makes given list of vehicles distinct by id and uses the values with the oldest timestamp in case of duplicates
   * @returns a new list with distinct values
   */
  distinctVehicles(vehicles: AlarmVehicle[]): AlarmVehicle[] {
    let map = {};
    for (let vehicle of vehicles) {
      const key = vehicle.name;
      let current = map[key];
      if (!current) {
        map[key] = vehicle;
        continue;
      }
      if (!current.hasVehicleId && vehicle.hasVehicleId) {
        map[key] = vehicle;
        continue;
      }
      if (vehicle.timestamp < current.timestamp) {
        map[key] = vehicle;
      }
    }
    return Object.values(map);
  }

  selectLocationMap() {
    this.mode = MissionModalMode.LOCATION_MAP;
  }

  buildMapImage() {
    let lat = this.emergency.lat;
    let lng = this.emergency.lng;
    if (!lat || !lng) return;
    let coords = [lat, lng];

    if (!this.map) {
      this.map = L.map("header-map", {
        zoomControl: false,
        dragging: false,
        doubleClickZoom: false,
        boxZoom: false
      });
    }

    // calculate center point with offset
    let zoom = 14;
    let x_offset = 300;
    let targetPoint = this.map.project(coords, zoom).subtract([x_offset, 0]);
    let center = this.map.unproject(targetPoint, zoom);

    this.map.setView(center, zoom);

    let layers = this.mapService.getBaseLayers();
    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    L.Icon.Default.imagePath = '/img/static'; // missing setup

    L.marker(coords, {draggable: false}).addTo(this.map);

  }

  cancel() {
    this.$uibModalInstance.close();
  }

  closeMission() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write)) {//TO DO Privilege
      return;
    }
    this.restService.closeMission(this.emergency.externalId).then(() => {
      this.emergency.state = 'CLOSED';
      this.$scope.$applyAsync();
    });
  }

  parseDate(): Date {
    if (!this.emergency) return new Date();
    return new Date(this.emergency.timestamp);
  }


  public valueOrTranslation(value: string) {
    if (value) return value;
    return this.NO_VALUE;
  }

  public getAlarmDataForMap() {
    let ad = {
      parameters: {}
    };
    if (this.emergency.location_dest) ad.parameters["location_dest"] = this.emergency.location_dest;
    if (this.emergency.lat) ad.parameters["lat"] = this.emergency.lat;
    if (this.emergency.lng) ad.parameters["lng"] = this.emergency.lng;
    if (this.emergency.lat_routing) ad.parameters["lat_routing"] = this.emergency.lat_routing;
    if (this.emergency.lng_routing) ad.parameters["lng_routing"] = this.emergency.lng_routing;
    if (this.emergency.lat_foreign) ad.parameters["lat_foreign"] = this.emergency.lat_foreign;
    if (this.emergency.lng_foreign) ad.parameters["lng_foreign"] = this.emergency.lng_foreign;
    if (this.emergency.emoji) ad.parameters["keyword_category"] = this.emergency.emoji;
    if (this.emergency.color) ad.parameters['keyword_color'] = this.emergency.color;
    return ad;
  }

  /**
   * Save the new report
   */
  save() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write)) {
      return;
    }
    this.isSavingReport = true;
    this.restService.saveMissionReport(this.emergency).finally(() => {
      this.isSavingReport = false;
      this.$scope.$applyAsync();
    });
  }

}

export enum MissionModalMode {
  ALARM = 'ALARM',
  ALARMS = 'ALARMS',
  MAP = 'MAP',
  LOCATION_MAP = 'LOCATION_MAP',
  VEHICLES = 'VEHICLES',
  REPORT = 'REPORT',
  EXPORT = 'EXPORT',
  TIMELINE = 'TIMELINE',
  IMAGES = 'IMAGES'
}