'use strict';

import { IRootScopeService, IScope } from "angular";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import { AlarmObjectTableRowEntry } from "../../../../data/objects.data";

require('./objects.tablerow.component.css');
//objectsRow
export default class ObjectRowComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A'
    this.template = require('./objects.tablerow.component.html');
    this.scope = {
      alarmObject: '=',
      index: '=',
      account: '='
    };
    this.controller = ObjectRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectRowComponentController {
  public $uibModal: any;
  public $rootScope: IRootScopeService;
  public helperService: HelperService;
  public $scope: IScope;
  public listeners = [];
  public icon: string;
  public alarmObject: AlarmObjectTableRowEntry;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModal, helperService: HelperService, public privilegeService: PrivilegeService) {
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.helperService = helperService;
    this.$scope = $scope;

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}
