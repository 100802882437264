'use strict';

import { IScope } from "angular";
import { AdditionalDetail, AlarmObject } from "../../../../data/objects.data";

require('./object.properties.component.css')

//objectProperties
export default class ObjectPropertiesComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.properties.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectPropertiesComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectPropertiesComponentController {
  public alarmObject: AlarmObject;
  public $scope: IScope;

  constructor($scope: IScope) {
    this.$scope = $scope;
  }

  add() {
    this.alarmObject.additionalDetails.push({
      key: '',
      value: ''
    });
  }

  removeKey(entry: AdditionalDetail) {
    this.alarmObject.additionalDetails.splice(this.alarmObject.additionalDetails.indexOf(entry), 1);
  }
}
