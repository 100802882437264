'use strict';

import { ILogService, IScope } from 'angular';
import angular = require('angular');
import { AlarmData } from '../../../../data/alarm.data';

require('./apager.summary.component.css');


export default class ApagerSummaryComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./apager.summary.component.html');
    this.scope = {
      alarm: '='
    };
    this.controller = ApagerSummaryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class ApagerSummaryComponentController {
  public $scope: IScope;
  public $log: ILogService;
  public dataService: any;
  public isEditMode: boolean;
  public alarm: AlarmData;

  constructor($scope: IScope, $log: ILogService, dataService) {
    this.$scope = $scope;
    this.$log = $log;
    this.dataService = dataService;
    this.initListeners();
  }

  initListeners() {
    this.$scope.$watch('ctrl.alarm', (oldAlarm, newAlarm: AlarmData) => {
      this.isEditMode = angular.isDefined(newAlarm);
      // Init
      if (angular.isDefined(newAlarm)) {
        this.alarm = newAlarm;
      }
    });
  }

  getRounded(cnt: number) {
    return Math.round(cnt * 10) / 10;
  };

}
