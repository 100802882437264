
'use strict';

import {ILogService, IRootScopeService} from "angular";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {WachbuchCategory} from "../../../../data/wache.data";
import PrivilegeService from "../../../../services/privilege.service";

require('./categories.shiftbook.modal.css');

export default class CategoriesShiftbookModalController {
  public $uibModalInstance: any;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $uibModal: any;
  public categories: WachbuchCategory[];
  public dataService: any;
  public isEditingCategoryById: string;
  public tmpCategoryName: string;
  public hasError: boolean;
  public isLoading: boolean;
  public tmpNewName: string;

  constructor($uibModalInstance, $log: ILogService, $uibModal, $rootScope: IRootScopeService, dataService, categories: WachbuchCategory[],
    public privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.categories = categories;
    this.dataService = dataService;
    this.isEditingCategoryById = '';
    this.tmpCategoryName = '';
    this.hasError = false;
  }

  close() {
    this.$uibModalInstance.close();
  }

  editCategory(category) {
    if (!this.privilegeService.has(RolePrivilege.Station_Shiftbook_Categories_Edit)) {
      this.isEditingCategoryById = '';
      return;
    }
    if (this.isEditingCategoryById !== '') {
      //An edit was not cancelled correctly so we reset the previous category name
      var _self = this;
      this.categories.forEach(function (cat) {
        if (cat.categoryId === _self.isEditingCategoryById) {
          cat.categoryName = _self.tmpCategoryName;
        }
      });
    }

    this.isEditingCategoryById = category.categoryId;
    this.tmpCategoryName = category.categoryName;
  }

  save(category) {
    this.isLoading = true;
    if (category.categoryName.length > 0) {
      this.isEditingCategoryById = '';
      this.dataService.editShiftbookCategory(category, (response) => {
        this.isLoading = false;
      }, (errorResponse) => {
        this.isLoading = false;
        this.$log.error('Could not save category', errorResponse);
      });
    }
  }

  cancelEdit(category) {
    this.isEditingCategoryById = '';
    category.categoryName = this.tmpCategoryName;
  }

  create() {
    if (this.tmpNewName !== undefined && this.tmpNewName.length > 0) {
      this.isLoading = true;
      this.dataService.createShiftbookCategory(this.tmpNewName, (response) => {
        this.categories.push(response);
        this.tmpNewName = '';
        this.isLoading = false;
      }, (errorResponse) => {
        this.isLoading = false;
        this.$log.error('Could not create category', errorResponse);
      })
    }
  }

  delete(category) {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deleteShiftbookCategory(category, () => {
              this.isLoading = false;
              var index = this.categories.indexOf(category);
              this.categories.splice(index, 1);
              this.$rootScope.$emit('shiftbook.categoryDeleted');
              //this.$uibModalInstance.close();
            }, (response) => {
              //Error occured
              this.isLoading = false;
              this.$log.error(response);
            });
          };
        },
        additionalText: () => {
          return 'SHIFTBOOK.CATEGORY.DELETE_TEXT';
        }
      }
    });
  }
}