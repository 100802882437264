import RestService from "../../../../../services/rest.service";
import {IScope} from "angular";
import {
  EVehiclePositionProcessingState,
  InputTrackingToggle,
  VehicleGeoTrackingStateResponse
} from "../../../../../data/geotracking.data";
import {TrackingSettings} from "../../../../../data/tracking.data";

require("./admin.tracking.settings.scss")

export default class AdminTrackingSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.tracking.settings.html');
    this.scope = {};
    this.controller = AdminTrackingSettingsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/*@ngInject*/
class AdminTrackingSettingsController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private trackingStateResponse: VehicleGeoTrackingStateResponse;
  public inputToggles: InputTrackingToggle[] = [];
  public trackingSettings: TrackingSettings;
  public aMobileToggle: boolean = true;
  private isSaving: boolean;


  constructor($scope: IScope, restService: RestService) {
    this.$scope = $scope;
    this.restService = restService;
    this.reload();
  }

  reload() {
    this.loadTrackingState();
    this.loadInputToggles();
    this.loadAmobileToggle();

    this.loadTrackingSettings();
  }

  loadTrackingState() {
    this.isLoading = true;
    this.restService.getVehicleGeoTrackingState().then(result => {
      this.trackingStateResponse = result;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  loadInputToggles() {
    this.restService.getInputTrackingToggles()
      .then(toggles => {
        toggles.sort((a, b) => a.translation.localeCompare(b.translation));
        this.inputToggles = toggles;
        this.$scope.$applyAsync();
      });
  }

  loadAmobileToggle() {
    this.restService.getAmobileTrackingToggle()
      .then(value => {
        this.aMobileToggle = value;
        this.$scope.$applyAsync();
      })
  }

  stopStartTracking() {
    this.isLoading = true;
    var newState = {} as VehicleGeoTrackingStateResponse
    if (this.trackingStateResponse.vehiclePositionProcessingState === EVehiclePositionProcessingState.RUNNING) {
      newState.vehiclePositionProcessingState = EVehiclePositionProcessingState.STOPPED;
    } else if (this.trackingStateResponse.vehiclePositionProcessingState === EVehiclePositionProcessingState.STOPPED) {
      newState.vehiclePositionProcessingState = EVehiclePositionProcessingState.RUNNING;
    } else {
      newState.vehiclePositionProcessingState = EVehiclePositionProcessingState.RUNNING;
    }
    this.restService.stopStartTracking(newState).then(changedState => {
      this.trackingStateResponse = changedState;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }


  public changeStateForInput(inputToggle: InputTrackingToggle) {
    this.restService.setInputTrackingToggles(inputToggle.inputClassSimpleName, !inputToggle.trackingActive)
      .then(res => {
        inputToggle.trackingActive = res.trackingActive;
        this.$scope.$applyAsync();
      })
  }

  public toggleAmobileActive() {
    this.restService.setAmobileTrackingToggle(!this.aMobileToggle)
      .then(result => {
        this.aMobileToggle = result;
        this.$scope.$applyAsync();
      })
  }

  private loadTrackingSettings() {
    this.restService.loadTrackingSettings().then((response) => {
      this.trackingSettings = response;
      if (this.trackingSettings.lipPollingPerVehicleIntervall == 0) {
        this.trackingSettings.lipPollingPerVehicleIntervall = 5;
      }
    })
  }

  saveSettings() {
    this.isSaving = true;
    this.restService.saveTrackingSettings(this.trackingSettings).then(() => {
      this.isSaving = false;
      this.$scope.$applyAsync();
    });
  }
}