import { IIntervalService, IRootScopeService } from 'angular';
'use strict';

import {IScope} from 'angular';
import {DiaryEntry, ELagekarteDiaryPriority, ELagekarteDiaryType, LocationMapTaskPayload} from '../../../../data/locationmap.data';
import RestService from '../../../../services/rest.service';
import PrivilegeService from '../../../../services/privilege.service';
import {RolePrivilege} from '../../../../data/privileges.enum';
import {EmergencyImageInfo} from '../../../../data/emergency.data';
import HelperService from '../../../../services/helper.service';

require('./queue.feedback.entry.scss');

export default class QueueFeedbackEntryComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      entry: '=',
      lagekarteid: '=',
      queueentryid: '=',
      isdone: '=',
      thumbnailtoken: '='
    }
    this.template = require('./queue.feedback.entry.html');

    this.controller = QueueFeedbackEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class QueueFeedbackEntryComponentController {

  public entry: DiaryEntry;
  public lagekarteid: string;
  public queueentryid: string;
  public loading: boolean;
  public isdone: boolean;
  public image: EmergencyImageInfo;
  private thumbnailtoken: string;
  public thumbnailLink: string;
  public showBigImage: boolean;
  public locationMapError: boolean;
  private clockTicker;
  private creationTimeString = '';

  constructor(private $rootScope: IRootScopeService, private helperService: HelperService, private $interval: IIntervalService, public $scope: IScope,
    private restService: RestService, private privilegeService: PrivilegeService, private $uibModal) {
    this.$scope.$watch('ctrl.entry', () => {
      if (this.entry) {
        var newDate = new Date(this.entry.creationTime);
        this.creationTimeString = this.helperService.humanReadableDateTime(newDate.getTime());
      }
      this.$scope.$applyAsync();
    });
    this.$scope.$watch('ctrl.lagekarteid', () => {
      this.$scope.$applyAsync();
    });
    this.$scope.$watch('ctrl.queueentryid', () => {
      this.$rootScope.$on('queue.' + this.queueentryid + '.locationMapError', (event, error: any) => {
        if (error.status === 401) {
          this.locationMapError = true;
        }
      });
      this.$scope.$applyAsync();
    });
    this.$scope.$watch('ctrl.isdone', () => {
      this.$scope.$applyAsync();
    });
    this.$scope.$watch('ctrl.thumbnailtoken', () => {
      this.thumbnailLink = 'https://europe-west3-lagekarte-c361d.cloudfunctions.net/storageService/' + this.lagekarteid + '/storage/' + this.entry.payload.data?.filename + '?thumbnail=true&token=' + this.thumbnailtoken;
      this.$scope.$applyAsync();
    });

    // refreshes timestamp display every 10 seconds.
    this.clockTicker = this.$interval(() => {
      if (this.entry) {
        var newDate = new Date(this.entry.creationTime);
        this.creationTimeString = this.helperService.humanReadableDateTime(newDate.getTime());
        this.$scope.$applyAsync();
      }
    }, 1000 * 30);

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.$interval.cancel(this.clockTicker)
    });

  }

  createTaskFromDiary(withAutomaticFeedback: boolean) {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Tasks_Write) || this.isdone) {
      return;
    }
    this.loading = true;
    let prefix = this.entry.payload.priority === ELagekarteDiaryPriority.URGENT ? '[DRINGEND] ' : '';

    switch (this.entry.payload.type) {
      case ELagekarteDiaryType.CLAIM:
        prefix = 'Nachforderung: ';
        break;
      case ELagekarteDiaryType.FINAL_REPORT:
        prefix = 'Abschlussmeldung: ';
        break;
      case ELagekarteDiaryType.REPORT:
        prefix = 'Lagemeldung: ';
        break;
      case ELagekarteDiaryType.MESSAGE:
        prefix = 'Nachricht: ';
        break;
    }
    const taskPayload = {
      message: prefix + this.entry.payload.message,
      sourceDiaryEntryId: this.entry.id,
      withAutomaticFeedback
    } as LocationMapTaskPayload;


    this.restService.createTaskEntryForLagekarte(this.entry.id, this.lagekarteid, this.queueentryid, taskPayload).catch(error => {
      this.$rootScope.$emit('queue.' + this.queueentryid + '.locationMapError', error);
    });
  }

  loadImage() {
    if (this.entry.payload.type !== ELagekarteDiaryType.IMAGE_UPLOADED || !this.privilegeService.has(RolePrivilege.Alarm_Queues_Diary_Read)) {
      return;
    }
    if (this.entry.payload.data) {
      if (!this.image ||!this.image?.imgData) {
        this.restService.getImageByIdForLagekarte(this.lagekarteid, this.entry.payload.data?.filename)
          .then(image => {
            this.image = image;
            this.showBigImage = true;
        }).catch(error => {
          console.error(error);
        }).finally(() => this.$scope.$applyAsync());
      } else {
        this.showBigImage = true;
        this.$scope.$applyAsync();
      }
    }
  }

  openImageLocationPopup() {
    if (this.entry.payload.type !== ELagekarteDiaryType.IMAGE_UPLOADED) {
      return;
    }
    if (!this.image.lat) {
      return;
    }

    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.image.lat,
            lng: this.image.lng,
            accuracy: 0
          };
        },
        position: () => {
          return undefined;
        },
        okFunction: () => {
          return (coords) => {
            // Nothing to do
          };
        }
      }
    });
  }
}