'use strict';

/* @ngInject */
export default class EditHintController {
	public $uibModalInstance: any;
	public hint: any;
	public isEditable: any;
	public okFunction: any;

  constructor($uibModalInstance, hint, isEditable, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.hint = hint;
    this.isEditable = isEditable;
    this.okFunction = okFunction;
  }

  cancel() {
    this.$uibModalInstance.close();
  }
  ok() {
    if (this.hint == '') {
        return;
      }
      this.$uibModalInstance.close();
      this.okFunction(this.hint);
  }
}