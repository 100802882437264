import { ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import { AAO, AAOEntryResponse, AAOEntryResponseElement, AAOKeyword, AAOTime } from "../../../../../data/aao.data";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import PrivilegeService from "../../../../../services/privilege.service";
import RestService from "../../../../../services/rest.service";

require('./aao.editor.css');

export default class AAOEditorComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./aao.editor.html');
    this.scope = {
      aao: '=',
      index: '='
    };
    this.controller = AAOEditorComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AAOEditorComponentController {
  public restService: RestService;
  public $log: ILogService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public isLoading: boolean;

  public aao: AAO;
  public entries: AAOEntryResponse;
  public selectedAAOTime: AAOTime;


  constructor($log: ILogService, $scope: IScope, $rootScope: IRootScopeService, private $uibModal, restService: RestService,
    public privilegeService: PrivilegeService) {
    this.restService = restService;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$scope = $scope;


    this.$scope.$watch('ctrl.aao', (newValue) => {
      if (angular.isDefined(newValue)) {
        this.loadEntries((newValue as AAO).id);
      }
    });

  }

  /**
   * Load all entries for given AAO
   */
  loadEntries(id: string): void {
    this.isLoading = true;
    this.restService.loadAAOEntries(id).then((response: AAOEntryResponse) => {
      this.entries = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  addEntry(): void {
    this.isLoading = true;
    this.restService.addAAOEntry(this.aao.id).then(() => {
      this.loadEntries(this.aao.id);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  setToDefault(entry: AAOEntryResponseElement): void {
    if (!this.privilegeService.has(RolePrivilege.AAO_Edit)) {
      return;
    }
    this.aao.defaultAAO = entry.id;
    this.save();
  }

  /**
   * Delete an existing AAO
   */
  deleteAAO(): void {
    this.isLoading = true;
    this.restService.deleteAAO(this.aao.id).then(() => {
      // Update
      this.$rootScope.$emit('update.aao.list');
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  /**
   * Duplicate an existing AAO
   */
  duplicateAAO(): void {
    this.isLoading = true;
    this.restService.duplicateAAO(this.aao.id).then(() => {
      // Update
      this.$rootScope.$emit('update.aao.list');
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  /**
   * Remove an existing keyword from the list
   * @param keyword
   */
  removeKeyword(keyword: AAOKeyword) {
    const index = this.aao.keywords.indexOf(keyword);
    if (index > -1) {
      this.aao.keywords.splice(index, 1);
      this.save();
    }
  }

  /**
   * Open modal and add a new keyword
   */
  addKeyword() {
    this.$uibModal.open({
      template: require('../../../../modals/wache/add.keyword.to.aao/add.keyword.to.aao.html'),
      controller: 'AddKeywordToAAOModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        aao: () => {
          return this.aao;
        },
        okFunction: () => {
          return (keywords) => {
            (keywords as string[]).forEach(keyword => this.aao.keywords.push({
              keyword: keyword
            } as AAOKeyword));
            this.save();
          }
        }
      }
    });
  }

  /**
   * Adjust the start and end time
   * @param time
   */
  adjustTime(entry: AAOEntryResponseElement, time: AAOTime) {
    if (!this.privilegeService.has(RolePrivilege.AAO_Edit)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../../modals/wache/aao.adjust.time/aao.adjust.time.html'),
      controller: 'AAOAdjustTimeModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        time: () => {
          return time;
        },
        okFunction: () => {
          return () => {
            this.saveElement(entry);
          }
        }
      }
    });
  }


  /**
   * Open the additional conditions modal
   * @param entry
   */
  openConditionsModal(entry: AAOEntryResponseElement) {
    this.$uibModal.open({
      template: require('../../../../modals/wache/aao.conditions/aao.conditions.html'),
      controller: 'AAOConditionsModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        entry: () => {
          return entry;
        },
        okFunction: () => {
          return () => {
            this.saveElement(entry);
          }
        }
      }
    });
  }

  /**
   * Open the vehicles popup
   * @param entry
   */
  openVehicles(entry: AAOEntryResponseElement) {
    this.$uibModal.open({
      template: require('../../../../modals/wache/aao.vehicles/aao.vehicles.html'),
      controller: 'AAOVehiclesModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        entry: () => {
          return entry;
        },
        aao: () => {
          return this.aao
        },
        okFunction: () => {
          return (vehicleHtmlPreview: string) => {
            entry.vehicleHtmlPreview = vehicleHtmlPreview;
            this.$scope.$applyAsync();
          }
        }
      }
    });
  }

  deleteEntry(entry: AAOEntryResponseElement): void {
    if (!this.privilegeService.has(RolePrivilege.AAO_Edit)) {
      return;
    }
    if (entry.defaultEntry) return;
    this.isLoading = true;
    this.restService.deleteAAOEntry(this.aao.id, entry.id).finally(() => {
      this.loadEntries(this.aao.id);
    });
  }


  invertEntry(entry: AAOEntryResponseElement): void {
    if (!this.privilegeService.has(RolePrivilege.AAO_Edit)) {
      return;
    }
    entry.times.inverted = !entry.times.inverted;
    this.saveElement(entry);
  }

  /**
   * Change the entries name
   * @param entry
   */
  changeEntryName(entry: AAOEntryResponseElement) {
    if (!this.privilegeService.has(RolePrivilege.AAO_Edit)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../../modals/misc/change.string.modal/change.string.modal.html'),
      controller: 'ChangeStringModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        str: () => {
          return entry.note;
        },
        required: () => {
          return false;
        },
        okFunction: () => {
          return (newNote: string) => {
            if (newNote === '') return;
            entry.note = newNote;
            this.saveElement(entry);
          }
        }
      }
    });
  }


  /**
   * Save an existing AAO
   */
  save(): void {
    if (this.aao.name === '') return;
    this.isLoading = true;
    this.restService.saveAAO(this.aao).then((aao) => {
      this.aao.defaultAAO = aao.defaultAAO;
      this.aao.keywords = aao.keywords;
      this.aao.name = aao.name;
      this.entries.entries.forEach(entry => {
        entry.defaultEntry = aao.defaultAAO === entry.id;
      });
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
 * Save an existing AAOEntryResponseElement
 */
  saveElement(element: AAOEntryResponseElement): void {
    this.isLoading = true;
    this.restService.saveAAOEntry(this.aao, element).then(() => {
      this.loadEntries(this.aao.id);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}