'use strict';

//personSelect
/* @ngInject */
export default class PersonSelectFilter {
  static filter(persons, personDetail) {

    if (angular.isUndefined(personDetail)) {
      return persons;
    }

    var tmp = [];
    if (angular.isUndefined(persons)) {
      return tmp;
    }
    for (var i = 0; i < persons.length; i++) {
      var person = persons[i];
      if (angular.isDefined(person.data[personDetail]) && person.data[personDetail] !== '') {
        tmp.push(person);
      }
    }
    return tmp;
  }
}
