import {IScope} from "angular";
import {
  Emergency,
  EMissionReportState,
  MissionReport,
  MissionReportPaticipantsRequest
} from "../../../../../data/emergency.data";
import {PersonSearchQueryResult} from "../../../../../data/person.data";
import PrivilegeService from "../../../../../services/privilege.service";
import RestService from "../../../../../services/rest.service";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import HelperService from "../../../../../services/helper.service";

export default class MissionReportParticipantsEntryComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A';
    this.scope = {
      report: '=',
      emergency: '=',
      participant: '='
    }
    this.template = require('./mission.report.participant.entry.component.html');
    this.controller = MissionReportParticipantsEntryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class MissionReportParticipantsEntryController {
  public $scope: IScope;
  public report: MissionReport;
  public hasEditRight: boolean = false;
  public emergency: Emergency;
  public participant: PersonSearchQueryResult;
  public startDate: Date;
  public endDate: Date;
  public sendingMissionConfirm: boolean= false;
  constructor($scope: IScope, public $rootScope: any, public privilegeService: PrivilegeService, public restService: RestService, public $uibModal, public helperService: HelperService) {
    this.$scope = $scope;

    this.$scope.$watch("ctrl.participant", (newValue: PersonSearchQueryResult, oldValue) => {
      if (this.participant) {
        if (this.report) {
          this.hasEditRight = this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write);
          if (this.report.state === EMissionReportState.PUBLISHED) {
            this.hasEditRight = false;

          }
          let savedParticipant = this.findPerson(this.participant);

          if (savedParticipant) {
            this.initDates(savedParticipant);
          }
        }
      }

    })

  }

  updateEntry(person: MissionReportPaticipantsRequest) {
    if (this.endDate) {
      person.endTime = this.endDate.getTime();
    }
    if (this.startDate) {
      person.startTime = this.startDate.getTime();
    }
  }

  private initDates(savedParticipant: MissionReportPaticipantsRequest) {
    if (!savedParticipant.startTime) {
      if (this.report.generalData && this.report.generalData.startTime) {
        this.startDate = this.clearSecondsAndMillis(new Date(this.report.generalData.startTime));
      }
    } else {
      this.startDate = this.clearSecondsAndMillis(new Date(savedParticipant.startTime));

    }
    if (!savedParticipant.endTime) {
      if (this.report.generalData && this.report.generalData.endTime) {
        this.endDate = this.clearSecondsAndMillis(new Date(this.report.generalData.endTime));
      }
    } else {
      this.endDate = this.clearSecondsAndMillis(new Date(savedParticipant.endTime));

    }
  }

  /**
   * checks if person is currently selected
   * @param person {@link DataBasePersonForTracking} to check the reference in report for
   */
  personChecked(person: PersonSearchQueryResult) {
    if (person) {
      return this.doesPersonExistAlready(person.personID);
    }
  }

  /**
   * finds the person in list otherwise will return a new object which is currently not in report
   * @param person
   */
  doesPersonExistAlready(personId: string) {
    return this.report.participants.some(saved => saved.person.personId === personId)
  }

  findPerson(person: PersonSearchQueryResult) {
    if (person) {
      var participant = this.report.participants.find(participantAdded => participantAdded.person.personId === person.personID);
      if (participant) {
        return participant;
      }
      // person not added jet
      participant = {
        person: {
          personId: person.personID,
          displayName: person.displayName,
          osId: person.osId
        },
      } as MissionReportPaticipantsRequest;
      return participant;
    }
  }

  private clearSecondsAndMillis(date: Date): Date {
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  removePerson(person: PersonSearchQueryResult) {
    if (person) {
      let index = this.report.participants.findIndex(participantAdded => participantAdded.person.personId === person.personID);
      this.report.participants.splice(index, 1)
      this.$scope.$emit('reportParticipant.remove', person);
    }

  }

  createAndSendConfirmSingle(){
    if (this.report.state !== EMissionReportState.PUBLISHED){
      return;
    }
    if (!this.doesPersonExistAlready(this.participant.personID)){
      // person isn't selected
      return;
    }
    this.sendingMissionConfirm = true;
    this.restService.createAndSendMissionConfirmationForSinglePerson(this.report.externalId, this.report.reportId, this.participant.personID)
      .then(()=>{
        // nothing to do
      }).finally(()=>{
        this.sendingMissionConfirm = false;
        this.$scope.$applyAsync();}
    );
  }
  addPerson(person: PersonSearchQueryResult) {
    if (this.report && person) {
      let participant = {
        person: {
          personId: person.personID,
          displayName: person.displayName,
          osId: person.osId
        },
        agt: false
      } as MissionReportPaticipantsRequest;
      this.setDateData(participant);
      if (!this.doesPersonExistAlready(person.personID)) {
        this.report.participants.push(participant);
        this.$scope.$emit('reportParticipant.add', person);
      }
    }
  }

  private setDateData(participant: MissionReportPaticipantsRequest) {
    if (this.report.generalData && this.report.generalData.startTime) {
      this.startDate = this.clearSecondsAndMillis(new Date(this.report.generalData.startTime));
    }
    if (this.report.generalData && this.report.generalData.endTime) {
      this.endDate = this.clearSecondsAndMillis(new Date(this.report.generalData.endTime));
    }
    if (this.startDate) {
      participant.startTime = this.startDate.getTime();
    }
    if (this.endDate) {
      participant.endTime = this.endDate.getTime();
    }
  }

  /**
   * handle toggle of participants to report list by adding or removing persons from report
   * @param checked boolean if person is currently selected
   * @param person {@link DataBasePersonForTracking} to add or remove from report
   */
  toggleCheck(checked: boolean, person: PersonSearchQueryResult) {
    if (person) {
      if (checked) {
        this.removePerson(person);
      } else {
        this.addPerson(person);
      }
    }
  }


  /**
   * handle toggle of agt for an individual person.
   * @param agt boolean if agt or not, if switch to !agt the time will be reset to 0 as well
   * @param person person to put the agt value for
   */
  setAgt(agt: boolean, person: PersonSearchQueryResult) {
    if (this.report && person) {
      var participant = this.report.participants.find(participantSaved => participantSaved.person.personId === person.personID);
      if (participant) {
        participant.agt = agt;
      } else {
        // person not added jet
        participant = {
          person: {
            personId: person.personID,
            displayName: person.displayName,
            osId: person.osId
          },
          agt: agt
        } as MissionReportPaticipantsRequest;
        if (!this.doesPersonExistAlready(person.personID)) {
          this.report.participants.push(participant);
          this.$scope.$emit('reportParticipant.add', person);

        }
      }
      if (!participant.agt) {
        participant.agtTime = 0;
      }
    }
  }
}
