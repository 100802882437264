'use strict'

import { IScope } from "angular"
import { ELoginType, UserAccount } from "../../../../data/account.data";

require("./add.pipeline.protocol.modal.css")

/* @ngInject */
export default class AddPipelineProtocolModalController {
    public change: string;
    public isValid: boolean = false;
    private account: UserAccount;
    constructor(private $scope: IScope,
        private $uibModalInstance: any,
        dataService: any) {
            this.account = dataService.getAccount();
        }


    validate() {
        this.isValid = this.change.length > 0;
    }
    
    save() {
        let editor = this.getEditor();
        this.$uibModalInstance.close({change: this.change, editor});
    }

    private getEditor() {
        let editor = this.account.username;
        if (this.account.loginType == ELoginType.PERSON) {
            editor = this.account.personUsername;
        }
        if (this.account.loggedInFromAdmin) {
            editor = this.account.sourceUsername;
        }
        return editor;
    }

    cancel() {
        this.$uibModalInstance.close(false);
    }
}