'use strict';

import { Gate, ListParkingPosition, ParkingPositionUpdateRequest } from "../../../../data/building.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require("./parking.position.admin.component.css")

export default class ParkingPositionAdminComponent {

  private restrict: any;
  private scope: any;
  private controller: any;
  private template: any;
  private controllerAs: string;
  private bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      parking: '='
    }
    this.template = require('./parking.position.admin.component.html');

    this.controller = ParkingPositionAdminController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//parking
/* @ngInject */
class ParkingPositionAdminController {

  private hasTimeplanPrivilege: boolean;
  private parking: ListParkingPosition;

  public gates: Gate[];

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $log: any,
    private restService: RestService,
    private $uibModal: any,
    private privilegeService: PrivilegeService) {
    this.hasTimeplanPrivilege = this.privilegeService.has(RolePrivilege.Station_Timeplan_Edit);
    this.loadGates();
  }

  loadGates() {
    this.restService.loadAllGates()
      .then((gates: Gate[]) => this.gates = gates)
      .catch(error => this.$log.error(error))
      .finally(() => this.$scope.$applyAsync());
  }

  selectFrontGate(gate: Gate) {
    this.parking.frontGate = gate;
    this.parking.hasFrontGate = gate !== null;
    this.save();
  }

  selectBackGate(gate: Gate) {
    this.parking.backGate = gate;
    this.parking.hasBackGate = gate !== null;
    this.save();
  }

  save() {
    let gateFrontId = this.parking.frontGate !== null ? this.parking.frontGate.id : null;
    let gateBackId = this.parking.backGate !== null ? this.parking.backGate.id : null;
    let number = this.parking.number;
    
    let updateRequest: ParkingPositionUpdateRequest = {
      gateFrontId,
      gateBackId,
      number
    };
    this.restService.updateParkingPositionGates(this.parking.id, updateRequest)
      .then(() => this.$rootScope.$emit('update.parking'))
      .catch(error => this.$log.error(error));

  }


  delete() {
    this.restService.deleteParkingPosition(this.parking.id)
      .then(() => {
        this.$rootScope.$emit('update.parking');
      }).catch(error => {
        this.$log.error(error);
      });
  }

  edit() {
    if (!this.hasTimeplanPrivilege) {
      return;
    }

    this.$uibModal.open({
      template: require('../../../modals/wache/parking.modal/parking.modal.html'),
      controller: 'ParkingModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        parking: () => {
          return this.parking;
        }
      }
    });
  }

  changeNumber() {
    this.$uibModal.open({
      template: require('../../../modals/misc/change.number.modal/change.number.modal.html'),
      controller: 'ChangeNumberModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        num: () => {
          return this.parking.number;
        },
        okFunction: () => {
          return (num: number) => {
            this.parking.number = num;
            this.save();
          }
        }
      }
    });
  }

}