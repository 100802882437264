import angular from 'angular';
import 'angular-ui-bootstrap'

import services from '../../../services'
import LayersController from './layers.component/layers.component';
import LayerRowComponentController from './layer.row.component/layer.row.component';

export default angular.module('FE2.components.layers', ['ui.bootstrap', services])
  .directive('layers', () => new LayersController())
  .directive('layerRow', () => new LayerRowComponentController())
  .name;