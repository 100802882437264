'use strict';

require('./list.component.css');

import angular = require('angular');
export default class AvailabilityListComponent {
	public restrict: any;
	public scope: any;
	public template: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '=',
        settings: '='
      }
    this.template = require('./list.component.html');

    this.controller = AvailabilityListController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityList
/* @ngInject */
class AvailabilityListController {
	public $scope: any;
	public $rootScope: any;
	public $log: any;
	public $uibModal: any;
	public helperService: any;
	public availabilities: any;
	public filteredLst: any;
	public statistic: any;
	public params: any;
	public settings: any;
	public onlineservice: any;

  constructor($scope, $rootScope, $log, $uibModal, helperService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.helperService = helperService;

    this.availabilities = ['AVAILABLE', 'NOT_AVAILABLE', 'TEMP_NOT_AVAILABLE', 'NONE'];
    this.filteredLst = [];
    this.statistic = {
      nbrOfFiltered: 0
    }

    this.params = {
      sortType: this.helperService.getFromStorage('availability_sortType', 'name'), // set the default sort type
      sortReverse: this.helperService.getFromStorage('availability_sortReverse', false) // set the default sort order
    }

    this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      //Watch for changes
      this.update();
    });
  }

  /**
   * Filter table
   * @param {} type
   * @param {*} val
   */
  filter(type, val) {
    var lst = undefined;
    if (type === 'FUNCTION') {
      lst = this.settings.filteredFunctions;
    } else if (type === 'GROUP') {
      lst = this.settings.filteredGroups;
    } else if (type === 'AVAILABLITY') {
      lst = this.settings.filteredAvailability;
    }
    if (angular.isUndefined(lst)) {
      return;
    }
    if (lst.indexOf(val) === -1) {
      // Not yet filtered
      lst.push(val);
      this.$log.info('Filtered ' + type + ': ' + val);
    } else {
      lst.splice(lst.indexOf(val), 1);
      this.$log.info('UnfFiltered ' + type + ': ' + val);
    }
    this.update();

    this.$rootScope.$emit('availability_filtered', this.settings);
  }

  /**
   * Returns true if given element is filtered
   * @param {} type
   * @param {*} val
   */
  isNotFiltered(type, val) {
    var lst = undefined;
    if (type === 'FUNCTION') {
      lst = this.settings.filteredFunctions;
    } else if (type === 'GROUP') {
      lst = this.settings.filteredGroups;
    } else if (type === 'AVAILABLITY') {
      lst = this.settings.filteredAvailability;
    }
    if (angular.isUndefined(lst)) {
      return true;
    }
    return lst.indexOf(val) === -1;
  }

  /**
   * Update sorting
   */
  updateSorting(type, isReverse) {
    this.params.sortType = type;
    this.params.sortReverse = isReverse;
    this.update();
  }

  /**
   * Save settings in local storage
   */
  saveSettings() {

    //Save
    this.helperService.saveInStorage('availability_settings', this.settings);
    this.helperService.saveInStorage('availability_sortType', this.params.sortType);
    this.helperService.saveInStorage('availability_sortReverse', this.params.sortReverse);
  };

  /**
   * Create scope data from online services
   */
  update() {
    this.$log.debug('Updating availability list...');
    this.filteredLst = [];
    this.statistic = {
      nbrOfFiltered: 0
    }

    //Iterate over every person
    for (var i = 0; i < this.onlineservice.data.lstOfAvailabilities.length; i++) {
      var person = this.onlineservice.data.lstOfAvailabilities[i];

      // Check filtered availability
      if (this.settings.filteredAvailability.includes(person.state)) {
        //Availability filtered
        this.statistic.nbrOfFiltered++;
        continue;
      }

      // Check filtered functions
      if (this.settings.filteredFunctions.length > 0) {
        var nbrOfFilteredFunctions = 0;
        for (var ii = 0; ii < person.functionsAsList.length; ii++) {
          if (this.settings.filteredFunctions.includes(person.functionsAsList[ii])) {
            //Availability filtered
            nbrOfFilteredFunctions++;
          }
        }
        if (nbrOfFilteredFunctions === person.functionsAsList.length) {
          // All functions filtered
          this.statistic.nbrOfFiltered++;
          continue;
        }
      }

      // Check filtered groups
      if (this.settings.filteredGroups.length > 0) {
        var nbrOfFilteredGroups = 0;
        for (var iii = 0; iii < person.groupsAsList.length; iii++) {
          if (this.settings.filteredGroups.includes(person.groupsAsList[iii])) {
            //Availability filtered
            nbrOfFilteredGroups++;
          }
        }
        if (nbrOfFilteredGroups === person.groupsAsList.length) {
          // All functions filtered
          this.statistic.nbrOfFiltered++;
          continue;
        }
      }
      // Not filtered
      this.filteredLst.push(person);
    }

    // Sort
    if (this.params.sortType === 'state') {
      // Sorty by state
      this.filteredLst.sort((a, b) => {
        if (a.stateLiteral === b.stateLiteral) {
          return a.name.localeCompare(b.name) * -1;
        } else if (a.stateLiteral > b.stateLiteral) {
          return -1;
        }
        return 1;
      });
    } else {
      // Sort by name
      this.filteredLst.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }
    if (this.params.sortReverse) {
      this.filteredLst.reverse();
    }

    this.saveSettings();
  }

  getClassForTableRowEntry(person) {
    if (person.syncHealth === 'MISSING') {
      return 'health-unknown active';
    }
    if (person.state === 'AVAILABLE') {
      return 'success';
    }
    if (person.state === 'NONE') {
      return 'info';
    }
    if (person.state === 'TEMP_NOT_AVAILABLE') {
      return 'warning';
    }
    if (person.state === 'NOT_AVAILABLE') {
      return 'danger';
    }
    return '';
  }


  openPerson(person) {
    this.$uibModal.open({
      template: require('../../../modals/availability/person.availability.modal/person.availability.modal.html'),
      controller: 'PersonAvailabiltyModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        person: () => {
          return person;
        }
      }
    });
  }
}
