import RestService from "../../../../services/rest.service";
import angular = require("angular");
import {User} from "../../../../data/admin.data";
import { ListParkingPosition } from "../../../../data/building.data";

require('./admin.users.parking.component.css')

export default class UserParkingComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.parking.component.html');
    this.scope = {
      user: '='
    };
    this.controller = UserParkingController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
/* @ngInject */
class UserParkingController {
  public user: User;
  public positions: ListParkingPosition[] =[];
  public selectedPositions:ListParkingPosition[]= [];
  public isLoading:boolean;
  public isLoadingWithAccess: boolean;
  public isSaving = false;

  constructor(
    private readonly restService: RestService, 
    private readonly $log: any,
    private readonly $scope: angular.IScope, 
    private readonly $rootScope: angular.IRootScopeService) {

    this.positions = [];
    this.selectedPositions = [];
    // get user when it is set, otherwise user will always be undefined
    $scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (angular.isDefined(newValue)) {
        this.user = newValue
        if (this.user.admin) {
          return; // Don't need to load anything for admin
        }
        this.loadPositionsWithAccess();
        this.loadPositions();
      }
    });
  }

  removeFromOrganisation(parkingPosition:ListParkingPosition){
    this.isSaving = true;
    this.restService.removeAccessToParkingPosition(this.user, parkingPosition)
      .then(() => {
        this.isSaving = false;
        this.$scope.$applyAsync();
        this.loadPositionsWithAccess();
      })
  }

  addToOrganisation(parkingPosition:ListParkingPosition){
    this.isSaving = true;
    this.restService.addAccessToParkingPosition(this.user,parkingPosition)
    .then(() => {
        this.isSaving = false;
        this.$scope.$applyAsync();
        this.loadPositionsWithAccess();
      }
    );
  }

  isSelected(position:ListParkingPosition){
    if (angular.isUndefined(this.selectedPositions)){
      return;
    }
    var list = this.selectedPositions.filter(compareTo => position.id === compareTo.id);
    return list.length ===1;
  }

  /**
   * load all parking positions from backend
   */
  loadPositions(){
    this.isLoading = true;
    this.restService.loadParkingPositionsAsPromise()
      .then(result => this.positions = result)
      .catch(err => this.$log.error(err))
      .finally(()=>{
        this.isLoading = false;
        this.$scope.$applyAsync();
      })
  }

  /**
   * load parking positions that organisation has access to from backend
   */
  loadPositionsWithAccess(){
    this.isLoadingWithAccess = true;
    if(angular.isUndefined(this.user)){
      this.isLoadingWithAccess = false;
      return;
    }
    this.restService.loadParkingPositionsForUser(this.user)
      .then((result:ListParkingPosition[]) =>{
        if (angular.isDefined(result)) {
          this.selectedPositions = result;
        }
      })
      .catch(err => this.$log.error(err))
      .finally(()=>{
        this.isLoadingWithAccess = false;
        this.$scope.$applyAsync();
      })

  }

}