'use strict'

import { ILocationService, IScope } from "angular";
import angular = require("angular");
import { ExaminationObject, ExaminationObjectEditRequest } from "../../../data/examination.data";
import ErrorService from "../../../services/error.service";
import RestService from "../../../services/rest.service";

require("./external.object.view.component.css")

/* @ngInject */
export default class ExternalObjectController {

    public examinationId: string;
    public examinationObject: ExaminationObject;

    
    public mode: string = "CONTACTS";
    public saveEnabled: boolean = false;
    public tokenIsValid: boolean = false;
    public isLoading: boolean = true;
    public isSaving: boolean = false;

    constructor($location: ILocationService,
        private $scope: IScope,
        private restService: RestService,
        private errorService: ErrorService,
        private Notification: any,
        private $translate: any) {

        this.examinationId = $location.search().id;
        if (!angular.isDefined(this.examinationId)) {
            this.errorService.notify(this.errorService.ERROR_IDS.INVALID_ARGUMENTS);
            return;
        }

        this.isLoading = true;
        this.restService.loadExaminationObjectById(this.examinationId)
            .then(examinationObject => {
                this.examinationObject = examinationObject;
                this.tokenIsValid = true;
            })
            .catch(err => this.$translate(['EXAMINATIONS.NOTIFICATIONS.ERROR_LOADING']).then(translations => {
                this.Notification.error({ title: translations['EXAMINATIONS.NOTIFICATIONS.ERROR_LOADING'], message: err.data.message })
            }))
            .finally(() => {
                this.isLoading = false;
                this.$scope.$applyAsync();
            });
    }


    public save() {
        const request: ExaminationObjectEditRequest = {
            contacts: this.examinationObject.contacts
        }
        this.isSaving = true;
        this.restService.editObjectExamination(this.examinationId, request)
            .then(res => this.examinationObject = res)
            .then(() => this.isSaving = false)
            .then(() => this.$scope.$applyAsync())
            .then(() => {
                this.$translate(['EXAMINATIONS.NOTIFICATIONS.APPROVED_TITLE', 'EXAMINATIONS.NOTIFICATIONS.APPROVED']).then(translations => {
                    this.Notification.success({ title: translations['EXAMINATIONS.NOTIFICATIONS.APPROVED_TITLE'], message: translations['EXAMINATIONS.NOTIFICATIONS.APPROVED'] })
                })
            })
            .catch(err => this.$translate(['EXAMINATIONS.NOTIFICATIONS.ERROR_SAVING']).then(translations => {
                this.Notification.error({ title: translations['EXAMINATIONS.NOTIFICATIONS.ERROR_SAVING'], message: err.data.message })
            }));
    }

}