import {IRootScopeService, IScope} from "angular";

export default class AddVehicleController{
  public $uibModalInstance: any;
  public $scope:IScope;
  public $rootScope:IRootScopeService;
  public name:String;
  public code:String;
  public okFunction: any;


  constructor($uibModalInstance: any, $scope:IScope, $rootScope:IRootScopeService, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.okFunction = okFunction;
    this.name = '';
    this.code = '';
  }

  cancel(){
    this.$uibModalInstance.close();
  }
  ok(){
    if (this.name === ''){
      return;
    }
    if (this.code === ''){
      return;
    }

    this.okFunction(this.name, this.code);
    this.$uibModalInstance.close('cancel');

  }
}