export interface DeviceResponse {
    id: string,
    vehicleId: string,
    deviceType: EDeviceType,
    typeColor: string,
    name: string,
    created: Date,
    lastChanged: Date,
    lastChangedFormatted: string;

    stateId: string,
    state: EDeviceState,
    stateChanged: Date,
    changedBy: String
}

export interface DeviceStateChangeResponse {
    id: string,
    vehicleId: string,
    deviceType: EDeviceType,
    typeColor: string,
    name: string,
    stateId: string,
    state: EDeviceState,
    stateChanged: string,
    changedBy: String
}

export interface VehicleDeviceResponse {
    vehicleId: string;
    vehicleName: string;
    shortName?: string;
    devices: DeviceResponse[];
}

export interface CreateDeviceRequest {
    deviceType: EDeviceType,
    quantity: number,
    name: string
}

export interface ChangeDeviceStateRequest {
    newState: EDeviceState
}

export enum EDeviceState {
    USABLE = 'USABLE',
    NOT_USABLE = 'NOT_USABLE'
}

export enum EDeviceType {
    BELEUCHTUNGSSATZ = 'BELEUCHTUNGSSATZ',
    EISRETTER = 'EISRETTER',
    EXWARNGERAET = 'EXWARNGERAET',
    GASMESSGERAET = 'GASMESSGERAET',
    ABSTURZSICHERUNG = 'ABSTURZSICHERUNG',
    SPERRWERKZEUG = 'SPERRWERKZEUG',
    WBK = 'WBK',
    PRESSLUFTATMER = 'PRESSLUFTATMER',
    PULSOXIMETER = 'PULSOXIMETER',
    REANIMATIONSGERAET = 'REANIMATIONSGERAET',
    RETTUNGSSATZ = 'RETTUNGSSATZ',
    SPRUNGPOLSTER = 'SPRUNGPOLSTER'
}