'use strict';

import { IHttpService, IRootScopeService, IScope, IWindowService } from "angular";
import {  QueueEntryResponse } from "../../../../data/queue.data";
import RestService from "../../../../services/rest.service";

require('./queue.finished.container.scss');

export default class QueueFinishedContainerComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      queue: '='
    }
    this.template = require('./queue.finished.container.html');

    this.controller = QueueContainerComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class QueueContainerComponentController {
  public isLoading = false;
  public queueEntryResponses: QueueEntryResponse[] =[];
  private listeners = [];
  constructor(public $scope: IScope,
    public $rootScope: IRootScopeService,
    public restService: RestService,
    public $window: IWindowService,
    public $http: IHttpService) {
    this.initListeners();
    this.load();
  }

  load() {
    this.isLoading = true;
    this.restService.loadAllFinishedQueueEntries()
      .then(queueEntryResponses => {
        this.queueEntryResponses = queueEntryResponses;
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  exportPdf() {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/queues/entries/finished/export?Authorization=' +
      this.$http.defaults.headers.common.Authorization, '_blank'
    );
  }

  initListeners() {

    this.listeners.push(this.$rootScope.$on('queue.QUEUE_ENTRY_REMOVED', (event, entry) => {
      this.load();
    }));

    this.$scope.$on('$destroy', () => {
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

}
