'use strict';

import angular = require("angular");
import { EFE2Features, UserAccount } from "../../../../data/account.data";
import {Person, PersonsAddressbookResponse, PaginatedDatabasePersonForTracking} from "../../../../data/person.data";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";


require('./tracking.component.css');

export default class TrackingComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.scope = {
    };
    this.template = require('./tracking.component.html');

    this.controller = TrackingController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class TrackingController {
  public $scope: angular.IScope;
  public $rootScope: angular.IRootScopeService;
  public $log: angular.ILogService;
  public restService: RestService;
  public listeners = [];
  public hasTrackingFeature = false;
  public dataService;
  public isLoading = true;

  public addressbook: PaginatedDatabasePersonForTracking;
  private params = {
    sortType: 'displayName',
    searchFilter: '',
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;


  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, $log: angular.ILogService, restService: RestService, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.dataService = dataService;


    this.listeners.push(this.$rootScope.$on('addressbook.view.tab', (event, source) => {
      if (source === 5) {
        const account: UserAccount = this.dataService.getAccount();
        this.hasTrackingFeature = account.features.includes(EFE2Features.TRACKING);
        // Load persons
        this.pageChanged();
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Change the page
   * @returns A promise which resolves after changing the page
   */
  pageChanged() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadPersonsTrackingRep(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1,
        this.params.searchFilter,
        this.params.sortReverse ? 'DESC' : 'ASC',
        this.params.pageSize,
        'aPagerPro',
        false)
        .then((result: PaginatedDatabasePersonForTracking) => {
          this.addressbook = result;
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }

  update() {
    this.$rootScope.$broadcast('load.person.locations');
  }
}