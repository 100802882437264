'use strict';
//Controller for isDirty check
/* @ngInject */
require('./confirm.alarm.modal.css');

export default class ConfirmAlarmModalController{
	public $scope: any;
	public $uibModalInstance: any;
	public okFunction: any;

  constructor($scope, $uibModalInstance, okFunction){
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
  }

  cancel (){
    this.$uibModalInstance.close();
  }
  ok () {
    this.$uibModalInstance.close();
    this.okFunction();
  };
}

