'use strict';

import { ILocationService, ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import {RolePrivilege} from "../../../data/privileges.enum";
import PrivilegeService from "../../../services/privilege.service";

/* @ngInject */
export default class AlarmController {
  public $log: ILogService;
  public $location: ILocationService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $state: any;
  public dataService: any;
  public listeners: any;
  public users: any;
  public mode: any;
  public gridLoading: boolean;
  public grids: any;
  public account: any;
  public addressbook: any;
  public persons: any;
  public isLoading: boolean;

  constructor($rootScope: IRootScopeService, $scope: IScope, $state, $log: ILogService, $location: ILocationService, dataService,
    public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$location = $location;
    this.$log.debug('AlarmController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.dataService = dataService;

    this.listeners = [];
    this.users = [];
    this.mode = AlarmTabs.ALARM;
    this.gridLoading = false;

    this.grids = [];
    this.initListeners();

    if (this.dataService.hasAccount()) {
      this.init();
    }
  }

  init() {

    this.account = this.dataService.getAccount();

    this.loadGrids(false);

    // State
    var currentMode = this.$location.search()['mode'];
    if (angular.isUndefined(currentMode)) {
      currentMode = AlarmTabs.ALARM;
    }
    this.selectTab(currentMode);
    this.$log.debug('AlarmCtrl initialized! Active tab: ' + currentMode);

  };

  /**
   * Select tab
   */
  selectTab(mode) {

    this.$log.debug('Selected Mode: ' + mode);
    let tmpMode = (mode as string).startsWith(AlarmTabs.CENTRAL) ? AlarmTabs.CENTRAL : mode;
    this.$log.debug('Selected Mode: ' + tmpMode);

    if (this.hasPermissionForTab(tmpMode)) {
      tmpMode = mode;
    } else {
      if (this.hasPermissionForTab(AlarmTabs.ALARM)) {
        tmpMode = AlarmTabs.ALARM;
      } else if (this.hasPermissionForTab(AlarmTabs.MESSAGES)) {
        tmpMode = AlarmTabs.MESSAGES;
      } else if (this.hasPermissionForTab(AlarmTabs.TEMPLATES)) {
        tmpMode = AlarmTabs.TEMPLATES;
      } else {
        this.$log.warn('No permission for any tab, going to home')
        this.$state.go('main.home');
      }
    }

    this.$log.debug('Selected Mode: ' + tmpMode);
    this.$location.search('mode', tmpMode);
    this.mode = tmpMode;

  };

  hasPermissionForTab(mode: AlarmTabs): boolean {
    switch (mode) {
      case AlarmTabs.ALARM:
        return this.privilegeService.has(RolePrivilege.Alarm_Alerting);
      case AlarmTabs.MESSAGES:
        return this.privilegeService.has(RolePrivilege.Alarm_Messages);
      case AlarmTabs.TEMPLATES:
        return this.privilegeService.has(RolePrivilege.Alarm_Alerting_Templates) || this.privilegeService.has(RolePrivilege.Alarm_Alerting_TemplateTable);
      case AlarmTabs.CENTRAL:
        return this.privilegeService.has(RolePrivilege.Alarm_Alerting_FromTemplateOnly);
      default:
        return false;
    }
  }

  /**
   * Load all grids
   */
  loadGrids(forceUpdate: boolean) {
    if (!(this.privilegeService.has(RolePrivilege.Alarm_Alerting_TemplateTable) || this.privilegeService.has(RolePrivilege.Alarm_Alerting_FromTemplateOnly))) {
      return;
    }
    this.gridLoading = true;
    this.dataService.getAlarmTemplateGrids(forceUpdate, (data) => {
      this.$log.debug(data);
      this.grids = data;
      this.gridLoading = false;
    }, (err) => {
      this.$log.error(err);
      this.gridLoading = false;
    });
  };

  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      //Init controller
      this.init();
    }));
    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.account = undefined;
    }));
    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('grid.updated', (event, grid) => {
      this.grids = grid;
    }));

    this.listeners.push(this.$rootScope.$on('tab.change.alarm', (event, data) => {
      switch (data) {
        case AlarmTabs.ALARM:
        case AlarmTabs.MESSAGES:
        case AlarmTabs.TEMPLATES:
          this.selectTab(data);
          break;
        default:
          break
      }
    }));


    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}

export enum AlarmTabs {
  ALARM = 'ALARM',
  MESSAGES = 'MESSAGES',
  TEMPLATES = 'TEMPLATES',
  CENTRAL = 'CENTRAL'
}
