'use strict';
import angular = require("angular");
import {AlarmObject} from "../../../../data/objects.data";

//objectAlarmReasons
export default class ObjectAlarmReasonsComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.alarm.reasons.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectAlarmReasonsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectAlarmReasonsComponentController {
	public types: any;
	public alarmObject: AlarmObject;

  constructor() {
    this.types = ['NO_OBVIOUS_REASON', 'BURNED_FOOD', 'DUST', 'FALSE_ALARM', 'MISC', 'ALARM', 'TECHNICAL_ISSUE', 'WILFUL_ALARM'];
  }

  /**
   * Add a new reason
   */
  addReason() {
    var data = {
      author: '',
      note: '',
      type: 'FALSE_ALARM',
      timestamp: (new Date()).getTime(),
      detectorGroup: ''
    }

    if (angular.isUndefined(this.alarmObject.bma.reasons)) {
      this.alarmObject.bma.reasons = [];
    }
    this.alarmObject.bma.reasons.push(data);
  };

  /**
   * Delete existing reason
   * @param {*} data
   */
  deleteReason(data) {
    this.alarmObject.bma.reasons.splice(this.alarmObject.bma.reasons.indexOf(data), 1);
  };
}