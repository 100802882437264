'use strict';

/* @ngInject */
export default class CancelEventModalController {
  private $uibModalInstance;
  private okFunction;

  private reason: string;

  constructor($uibModalInstance, okFunction) {

    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
  }


  cancel() {
    this.$uibModalInstance.close();
  };

  ok() {
    this.$uibModalInstance.close();
    this.okFunction(this.reason);
  };

}
