'use strict';

import {ILogService, IScope} from "angular";
import angular = require("angular");
import {RolePrivilege} from "../../../../data/privileges.enum";
import {WachbuchCategory, WachbuchMessage} from "../../../../data/wache.data";
import PrivilegeService from "../../../../services/privilege.service";

require('./category.management.component.css');

export default class CategoryManagementComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA',
      this.scope = {
        category: '=',
        categories: '='
      }
    this.template = require('./category.management.component.html');

    this.controller = CategoryManagementComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class CategoryManagementComponentController {
  public $log: ILogService;
  public $scope: IScope;
  public $uibModal: any;
  public dashboardService: any;
  public isLoading: any;
  public messages: WachbuchMessage[];
  public category: WachbuchCategory;
  public categories: WachbuchCategory[];

  constructor($log: ILogService, $scope: IScope, $uibModal, dashboardService, public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.dashboardService = dashboardService;
    this.isLoading = false;
    this.messages = [];

    this.$scope.$watch('ctrl.category', (oldValue, newValue) => {
      if (angular.isDefined(newValue)) {
        this.load();
      }
    })
  }

  /**
   * Load messages from server
   */
  load() {
    this.isLoading = true;
    this.dashboardService.getShiftbookMessages(this.category.categoryId, (messages: WachbuchMessage[]) => {
      this.isLoading = false;
      this.messages = messages;
      this.$log.info('Loaded ' + this.messages.length + ' for category ' + this.category.categoryName);
    }, (errorResponse) => {
      this.isLoading = false;
      this.$log.error('Could not load messages for category', errorResponse);
    })
  }

  /**
   * Add a new message
   */
  addOrEditMessage(message) {
    if (message) {
      // message is only filled, when we are editing so we check the permission
      if (!this.privilegeService.has(RolePrivilege.Station_Shiftbook_Reports_Edit)) {
        return;
      }
    }
    this.$uibModal.open({
      template: require('../../../modals/wache/messages.modal/messages.modal.html'),
      controller: 'MessagesModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        category: () => {
          return this.category;
        },
        categories: () => {
          return this.categories;
        },
        message: () => {
          return message;
        }
      }
    });
  }
}
