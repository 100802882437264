import {IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {SinkResponse} from "../../../../data/sinks.data";
import * as angular from "angular";

export default class EditGeobyteModal {
  public $uibModalInstance: any;
  public $scope: IScope;
  public restService: RestService;
  public okFunction: any;

  public auth: string;
  public url: string;
  public isSaving: boolean = false;
  public isNew: boolean = false;
  public geoByteSink: SinkResponse;
  public data: Map<string, string>;

  constructor($uibModalInstance: any, $scope: angular.IScope, restService: RestService, sink: SinkResponse, isNew: boolean, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.restService = restService;
    this.okFunction = okFunction;
    // to display fitting text in button
    this.isNew = isNew;
    this.geoByteSink = sink;
    this.url = this.geoByteSink.data[ParametersGeobyte.url];
    this.auth = this.geoByteSink.data[ParametersGeobyte.authentication];
  }

  cancel() {
    // no reload if no change was made
    this.okFunction(false);
    this.$uibModalInstance.close();
  }

  save() {
    this.isSaving = true;
    this.geoByteSink.data[ParametersGeobyte.url] = this.url;
    this.geoByteSink.data[ParametersGeobyte.authentication] = this.auth;
    this.restService.saveSink(this.geoByteSink).then(() => {
      this.isSaving = false;
    }).finally(() => {
      // reload table on save
      this.okFunction(true);
      this.$uibModalInstance.close();

    });
  }
}

export enum ParametersGeobyte {
  url = 'url',
  authentication = 'authentication'
}