'use strict';

require('./currentAlarms.shiftbook.modal.css');

export default class CurrentAlarmShiftbookModalController {
	public listeners: any;
	public $scope: any;
	public $uibModalInstance: any;
	public $uibModal: any;
	public $rootScope: any;
	public dashboardService: any;
	public $log: any;
	public alarms: any;
	public deletionOfAlarmsDeactivated: any;
	public isShiftBookActive: any;
	public isLoading: any;

  constructor($uibModalInstance, $uibModal, $scope, $log, $rootScope, dataService, dashboardService, accountService) {
    this.listeners = [];
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.dashboardService = dashboardService;
    this.$log = $log;

    this.alarms = [];
    this.getCurrentAlarmsFromServer();
    var account = dataService.getAccount();
    this.deletionOfAlarmsDeactivated = account.deletionOfAlarmsDeactivated;
    this.isShiftBookActive = accountService.isFeatureEnabled('SHIFT_BOOK');


    this.listeners.push($rootScope.$on('alarm.deleted', (event, id) => {
      var filtered = this.alarms.filter(value => value.id === id);
      if (filtered.length === 1) {
        this.alarms.splice(this.alarms.indexOf(filtered[0]), 1);
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  close() {
    this.$uibModalInstance.close();
  }

  /**
   * Load current events from server
   */
  getCurrentAlarmsFromServer() {
    this.$log.info('Loading todays important alarms...');
    this.isLoading = true;
    this.dashboardService.getNumberOfAlarms((alarms) => {
      this.alarms = alarms;
      this.$log.info('Got ' + this.alarms.length + ' important alarms for today');
      this.isLoading = false;
    }, (error) => {
      this.$log.error('Could not load todays events', error);
      this.isLoading = false;
    });
  };

  /**
   * Open alarm
   */
  openAlarm(alarm) {
    this.$uibModal.open({
      template: require('../../alarms/alarm.modal/alarm.modal.html'),
      controller: 'AlarmModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        alarm: () => {
          return alarm;
        }
      }
    });
  };
}
