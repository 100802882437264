'use strict';

import { IScope } from "angular";
import { DeviceResponse, EDeviceState, EDeviceType, VehicleDeviceResponse } from "../../../../data/device.data";
import RestService from "../../../../services/rest.service";
import { Vehicle } from "../../../../data/vehicles.data";
import { VehicleModes } from "../../../modals/wache/vehicle.modal/vehicle.modal";
import { PageControlModel } from "../../misc/page.controls.component/page.controls.component";


require('./devices.component.scss');

export default class DevicesComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./devices.component.html');
    this.scope = {
    };
    this.controller = Controller;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class Controller {

  public vehicleDeviceCounts: VehicleDeviceCount[] = [];

  public searchFilter: string = "";
  private searchTimer: NodeJS.Timeout;
  public hideFullyUsableVehicles: boolean = true;

  public isLoading: boolean = false;
  public pageControlModel: PageControlModel = {
    page: 0,
    first: false,
    last: false
  }

  public pageChanged: Function;

  constructor(
    private readonly $scope: IScope,
    private readonly restService: RestService,
    private readonly $uibModal) {
    this.loadData();
    this.pageChanged = () => {
      this.loadData();
    }
  }

  private async loadData() {
    this.isLoading = true;
    this.$scope.$applyAsync();
    const vehicleSlice = await this.restService.getAllVehiclesWithDevices(this.searchFilter, this.pageControlModel.page, this.hideFullyUsableVehicles);
    this.vehicleDeviceCounts = vehicleSlice.content.map(mapToDeviceCount);
    this.pageControlModel.first = vehicleSlice.first;
    this.pageControlModel.last = vehicleSlice.last;
    this.isLoading = false;
    this.$scope.$applyAsync();
  }

  public searchFilterChanged() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(() => {
      this.pageControlModel.page = 0;
      this.loadData();
      this.$scope.$applyAsync();
    }, 500);
  }

  public resetSearchFilter() {
    this.pageControlModel.page = 0;
    this.searchFilter = "";
    this.loadData();
  }

  public toggleFullyUsableVehicles() {
    this.hideFullyUsableVehicles = !this.hideFullyUsableVehicles;
    this.pageControlModel.page = 0;
    this.loadData();
  }

  openVehicle(vehicle: VehicleDeviceCount) {
    const modalInstance = this.$uibModal.open({
      template: require('../../../modals/wache/vehicle.modal/vehicle.modal.html'),
      controller: 'VehicleModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicle: () => {},
        vehicleId: () => {
          return vehicle.vehicleId;
        },
        okFunction: () => {},
        mode: () => VehicleModes.DEVICES
      }
    });
    modalInstance.result.then(() => this.loadData());
  }

}

class DeviceCounter {
  public usable = 0;
  public notUsable = 0;
  public total = 0;
  public typeColor: string;
  public percentage = 0;
  public readonly type: EDeviceType

  constructor(device: DeviceResponse) {
    if (device) {
      this.type = device.deviceType;
      this.typeColor = device.typeColor;
    }
  }

  public add(device: DeviceResponse) {
    if (device.state === EDeviceState.USABLE) {
      this.usable++;
    } else {
      this.notUsable++;  
    }
    this.total++;
    this.percentage = 100 * this.notUsable / this.total;
  }
}

interface VehicleDeviceCount {
  vehicleId: string;
  vehicleName: string;
  shortName?: string;
  devices: DeviceCounter[];
  usable: number;
  notUsable: number;
  total: number;
  percentage: number;
}

function mapToDeviceCount(vehicle: VehicleDeviceResponse): VehicleDeviceCount {
  const lookupMap = {};
  const totalCounter = new DeviceCounter(undefined);
  for (let device of vehicle.devices) {
    const counter = lookupMap[device.deviceType] ?? (lookupMap[device.deviceType] = new DeviceCounter(device));
    counter.add(device);
    totalCounter.add(device);
  }
  const devices = Object.values(lookupMap) as DeviceCounter[];
  return {
    vehicleId: vehicle.vehicleId, 
    vehicleName: vehicle.vehicleName,
    shortName: vehicle.shortName,
    usable: totalCounter.usable,
    notUsable: totalCounter.notUsable,
    total: totalCounter.total,
    percentage: totalCounter.percentage,
    devices
  };
}