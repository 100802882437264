export interface WidgetData {
    count: number,
    countError: number,
    state: EWidgetState;
}

export enum EWidgetState {
    LOADING = 'LOADING',
    DANGER = 'DANGER',
    READY = 'READY',
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS'
}