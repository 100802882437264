import { IHttpService, ILogService, IRootScopeService, IScope, IWindowService } from 'angular';
import RestService from '../../../../services/rest.service';
import { AlarmData, AlarmDataSimple, AlarmRequestSimple } from '../../../../data/alarm.data';
import { VehicleSimple } from '../../../../data/vehicles.data';
import PrivilegeService from '../../../../services/privilege.service';
import { RolePrivilege } from '../../../../data/privileges.enum';
import { EInputFallbackCategory } from "../../../../data/input.data";
import angular = require('angular');

'use strict';

require('./alarm.modal.css');

/* @ngInject */
export default class AlarmModalController {
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $window: IWindowService;
  public $http: IHttpService;
  public $uibModal: any;
  public $uibModalInstance: any;
  public $translate: any;
  public accountService: any;
  public dataService: any;
  public Notification: any;
  public restService: RestService;
  public alarm: AlarmData;
  public $log: ILogService;
  public isFeedbackLoading = false;
  public isShiftBookActive = false;
  public isStateUpdating = false;
  public isLoading = true;
  public feedback: any;
  public hasMap = false;
  public mode = AlarmDetailMode.ALARM;
  public fallbackCategory: typeof EInputFallbackCategory = EInputFallbackCategory;


  constructor($rootScope: IRootScopeService, $scope: IScope, $log: ILogService, $window: IWindowService, $http: IHttpService, $uibModal, $uibModalInstance, $translate, dataService, Notification, restService: RestService, alarm: AlarmDataSimple, accountService,
    public privilegeService: PrivilegeService) {
    $log.debug('AlarmModalController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$window = $window;
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.$translate = $translate;
    this.accountService = accountService;
    this.dataService = dataService;
    this.Notification = Notification;
    this.restService = restService;
    this.$log = $log;
    this.isFeedbackLoading = false;
    alarm.isNew = false;
    this.isShiftBookActive = this.accountService.isFeatureEnabled('SHIFT_BOOK');


    this.restService.loadAlarm(alarm.id).then(alarmData => {
      this.alarm = alarmData;
      this.hasMap = !(alarmData.parameters['location_dest'] === undefined && alarmData.parameters['lat'] === undefined);
      this.loadFeedback();

    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  cancel() {
    this.$uibModalInstance.close();
  };

  /**
   * Toggle the important flag
   */
  toogleImportantState() {
    this.isStateUpdating = true;
    var isImportant = !this.alarm.important;
    this.dataService.updateAlarmImportantState(this.alarm, isImportant, () => {
      // Success
      this.isStateUpdating = false;
    }, (error) => {
      // Error updating state
      this.isStateUpdating = false;
      this.$log.error(error);
    });
  }

  /**
   * Update the current alarm state
   */
  updateAlarmState(state) {
    this.$uibModal.open({
      template: require('../close.alarm.modal/close.alarm.modal.html'),
      controller: 'CloseAlarmModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        alarm: () => {
          return this.alarm;
        },
        okFunction: () => {
          return (date, selectedVehicleIds) => {
            var request = {
              state: state,
              timestamp: date.getTime(),
              vehicleIds: selectedVehicleIds
            };
            this.isStateUpdating = true;
            this.dataService.updateAlarmState(this.alarm, request, () => {
              // Success
              this.isStateUpdating = false;
            }, (error) => {
              // Error updating state
              this.isStateUpdating = false;
              this.$log.error(error);
            });
          }
        }
      }
    });
  };

  openProtocolDownloadUrl() {
    this.$window.open(this.restService.getBaseUrl() + '/alarm/protocol/' + this.alarm.id + '?Authorization=' + this.$http.defaults.headers.common.Authorization, '_blank');
  }

  downloadPrintedFile() {
    this.$window.open(this.restService.getBaseUrl() + '/alarm/print/' + this.alarm.id + '?Authorization=' + this.$http.defaults.headers.common.Authorization, '_blank');
  }

  /**
    Verschickt einen "alten" Alarm nochmals
  */
  sendAlarm() {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Alerting)) {
      return;
    }

    this.$uibModal.open({
      template: require('../confirm.alarm.modal/confirm.alarm.modal.html'),
      controller: 'ConfirmAlarmModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            var date = new Date();
            var copyAlarm = {} as AlarmData;
            angular.copy(this.alarm, copyAlarm);
            copyAlarm.parameters['timestamp'] = date.getTime();
            var request = {
              units: copyAlarm.code,
              data: copyAlarm.parameters,
              vehicles: copyAlarm.vehicles.map(vehicle => {
                return {
                  id: vehicle.vehicleId,
                  name: vehicle.name,
                  status: vehicle.status
                } as VehicleSimple;
              }),
              externalId: copyAlarm.externalId,
              retriggered: true
            } as AlarmRequestSimple;

            this.restService.sendAlarm(request,
              () => {
                this.isLoading = false;
                //Show popup
                this.$translate(['ALARM.SUCCESS_MESSAGE', 'ALARM.SUCCESS_TITLE']).then((translations) => {
                  this.Notification.success({
                    message: translations['ALARM.SUCCESS_MESSAGE'],
                    title: translations['ALARM.SUCCESS_TITLE']
                  });
                });
              },
              (response) => {
                //Error occured
                this.isLoading = false;
                this.$log.error(response);
              });
          };
        }
      }
    });
  };

  /**
   * Trigger a update map event
   */
  mapSelected() {
    this.$rootScope.$emit('updateMap', this.alarm);
  }

  /**
    Load the feedback from appengine
  */
  loadFeedback() {
    if (!this.privilegeService.has(RolePrivilege.Home_Alarms_Feedback)) {
      return;
    }

    if (this.alarm.parameters['dbId'] || this.alarm.parameters['ids']) {
      //Only load feedback, if ID exists
      this.isFeedbackLoading = true;

      this.restService.loadFeedback(this.alarm.id).then(result => {
        this.feedback = result;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isFeedbackLoading = false;
        this.$scope.$applyAsync();
      });
    }


  }
}

export enum AlarmDetailMode {
  ALARM = 'ALARM',
  FEEDBACK = 'FEEDBACK',
  MAP = 'MAP',
  VEHICLE = 'VEHICLE',
  MONEY = 'MONEY',
  ERROR = 'ERROR',
  APAGER = 'APAGER',
  DETAILS = 'DETAILS',
  PROTOCOL = 'PROTOCOL',
  CLOSE_ACTIONS = 'CLOSE_ACTIONS',
  VIDEO_CAMERA = 'VIDEO_CAMERA'
}
