'use strict';

import { IScope } from "angular";
import {EProvider, MailAccountSettings} from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";

require('./admin.mailaccount.component.css');

export default class AdminMailAccountSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.mailaccount.component.html');
    this.scope = {
    };
    this.controller = AdminMailAccountComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

enum ESendingMailStatus {
  NO='NO',
  IN_PROGRESS='IN_PROGRESS',
  DONE='DONE',
  ERROR = 'ERROR'
}

/* @ngInject */
class AdminMailAccountComponentController {
  public isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private settings: MailAccountSettings;
  private receiversToText: string;
  public providers: EProvider[] = [];
  public googleLink: string;
  public azureLink: string;
  public tokenType: string;
  public sendMailResultMessage: string;
  public statusEmailSending:ESendingMailStatus=ESendingMailStatus.NO;

  constructor($scope: IScope, restService: RestService) {
    this.restService = restService;
    this.$scope = $scope;
    this.providers=[EProvider.User,EProvider.Azure];
    this.load();
    this.updateTokenType();
    let updateTokenType = setInterval(() => this.updateTokenType(), 15 * 1000);
    this.$scope.$on('$destroy', () => {
      clearInterval(updateTokenType);
    });
  }


  /**
   * Load map settings
   */
  load() {
    this.isLoading = true;
    this.restService.loadMailAccountSettings().then(settings => {
      this.settings = settings;
      this.receiversToText = this.settings.receiver.join('\n');
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
      this.updateTokenType();
    });
  }

  /**
   * Save map settings
   */
  save(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoading = true;
      if(!this.settings.auth){
        this.settings.password=null;
        this.settings.username=null;
      }
      this.settings.receiver = this.receiversToText.split('\n')
      this.restService.saveMailAccountSettings(this.settings).then(response => {
        this.settings = response;
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
        resolve();
      });
    });
  }

  goGoogle(){
    if(this.settings.provider!==EProvider.Google) {
      return;
    }
    this.restService.getGoogleLink(this.settings.sender).then((response)=>{
      this.googleLink = response;
      window.open(this.googleLink, "_blank");
    })

  }

  goAzure(){
    if(this.settings.provider!==EProvider.Azure) {
      return;
    }
    this.restService.getAzureLink(this.settings.sender).then((response)=>{
      this.azureLink = response;
      window.open(this.azureLink, "_blank");
    })

  }


  updateTokenType(){
    if(this.settings==null)return;
    if(this.settings.provider!==EProvider.User && this.tokenType!==this.settings.provider){
      let email = this.settings.sender
      if(email!=null)
        this.restService.updateTokenType(email).then((response)=>{
          this.tokenType = response;
          this.$scope.$apply();
        })
    }
  }

  sendTestEmail(){
    this.save().then(() => {
      this.statusEmailSending = ESendingMailStatus.IN_PROGRESS;
      this.restService.sendTestEmail(this.settings.sender, this.settings.receiver[0]).then(()=>{
        this.statusEmailSending = ESendingMailStatus.DONE;
      }).catch((error) => {
        this.sendMailResultMessage = error.message;
        this.statusEmailSending = ESendingMailStatus.ERROR;
      }).finally(() => {
        this.$scope.$applyAsync();
      });
    });
  }

  isAllRequiredFilled(): boolean {
    if (!this.settings) {
      return false;
    }
    let req = this.settings.sender != "" && this.settings.server != "" && this.settings.port != null
      && this.settings.receiver.length > 0 && this.settings.receiver[0] != ""
      && this.settings.emailTemplateImgPath != "";
    if (this.settings.provider !== EProvider.User) {
      return req && this.tokenType === this.settings.provider;
    } else {
      if (this.settings.auth) {
        return req && this.settings.username != "" && this.settings.password != ""
      }
      return req;
    }
  }

  changeProvider(prov:EProvider){
    this.settings.provider=prov
    this.updateHost(prov);
    this.updateTokenType();
  }

  updateHost(prov:EProvider){
    if(prov===EProvider.Google) {
      this.settings.server='smtp.gmail.com'
      this.settings.port=587
      this.settings.ssl=true
    }
    if(prov===EProvider.Azure) {
      this.settings.server='smtp.office365.com'
      this.settings.port=587
      this.settings.ssl=true
    }
  }
}
