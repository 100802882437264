'use strict';

require('./dropdown.base.field.css');

export default class DropdownBaseFieldComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./dropdown.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = DropdownBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class DropdownBaseFieldController {
  constructor() {}
}
