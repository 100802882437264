'use strict';

import {ILogService, IScope} from "angular";
import {RolePrivilege} from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import angular = require("angular");

/* @ngInject */
export default class ParkingModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $uibModal: any;
  public $uibModalInstance: any;
  public helperService: HelperService;
  public parking: any;
  public dataService: any;
  public isLoading: boolean;
  public selectionModeAll: any;
  public timeplan: any;
  public relais: any;
  public selectedRelais: any;
  public selectedRelaisTimeplan: any;

  constructor($scope: IScope, $log: ILogService, $uibModal, $uibModalInstance, helperService: HelperService, parking, dataService,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModalInstance = $uibModalInstance;
    this.helperService = helperService;
    this.parking = parking;
    this.dataService = dataService;

    this.selectionModeAll = false;

    if (this.privilegeService.has(RolePrivilege.Station_Parking_Relais_Read)) {
      this.isLoading = true;
      //Load times
      this.dataService.loadTimesForParkingPosition(this.parking, (timeplan) => {
        this.timeplan = timeplan;
        //Load relais
        this.dataService.getRelais(false, true, (relais) => {
          this.relais = relais;
          this.isLoading = false;
        }, (err) => {
          this.isLoading = false;
        });
      }, (err) => {
        this.isLoading = false;
      });
    }
  }

  /**
   * Select a relais to start editing
   */
  selectRelais(relais) {
    if (angular.isDefined(this.selectedRelais)) {
      this.timeplan.relaisMapping[this.selectedRelais.id] = this.selectedRelaisTimeplan;
    }
    relais.hasBeenEdited = true;
    this.selectedRelais = relais;

    if (angular.isDefined(this.timeplan.relaisMapping[relais.id])) {
      this.$log.debug('Relais has timeplan:');
      this.selectedRelaisTimeplan = this.timeplan.relaisMapping[relais.id];
    } else {
      this.selectedRelaisTimeplan = {times: []};
      for (var i = 0; i < 7; i++) {
        var arr = [];
        for (var ii = 0; ii < 24; ii++) {
          arr[ii] = 0;
        }
        this.selectedRelaisTimeplan.times[i] = arr;
      }
      this.timeplan.relaisMapping[relais.id] = this.selectedRelaisTimeplan;
    }
    this.$log.debug(this.selectedRelaisTimeplan);
  }

  /**
   * Removes all timeplans
   */
  deleteAllRelais() {
    this.timeplan.relaisMapping = {};
    //Set flag flag
    for (var i = 0; i < this.relais.length; i++) {
      this.relais[i].hasBeenEdited = true;
    }
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  save() {
    //Delete unnecessary flag
    for (var i = 0; i < this.relais.length; i++) {
      delete this.relais[i].hasBeenEdited;
    }

    this.isLoading = true;
    this.$log.debug('Update timeplan:');
    var data = this.timeplan;
    data.timeplanIsActive = this.parking.timeplanIsActive;
    this.dataService.updateTimesForParkingPosition(this.parking, data, () => {
      this.$uibModalInstance.close();
    }, (err) => {
      this.isLoading = false;
    });
  }
}