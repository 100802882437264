'use strict';

import angular = require('angular');

export default class ObjectDangerBarriersComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.danger.barriers.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectDangerBarriersComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectDangerBarriersComponentController {
	public $uibModal: any;
	public alarmObject: any;
	public isEditable: any;

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }


  /**
   * Add a new barrier
   */
  addBarrier() {
    var data = {
      location: '',
      type: '',
      note: ''
    }
    if (angular.isUndefined(this.alarmObject.risks.barriers)) {
      this.alarmObject.risks.barriers = [];
    }
    this.alarmObject.risks.barriers.push(data);
  };

  /**
   * Delete existing barrier
   * @param {*} object
   */
  deleteBarrier(data) {
    this.alarmObject.risks.barriers.splice(this.alarmObject.risks.barriers.indexOf(data), 1);
  };


  /**
   * Show on map
   * @param {*} element
   */
  showOnMap(element) {
    if (this.isEditable) {
      this.$uibModal.open({
        template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
        controller: 'ChooseOnMapCtrl',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          coords: () => {
            return element.coords;
          },
          okFunction: () => {
            return (coords) => {
              element.coords = coords;
            }
          }
        }
      });
    };
  }
}
