import {EVehicleStatus} from './vehicles.data';
import {EServerMode} from "./socket.data";
import {RoleAccountResponse} from './role.data';
import {CustomMapLayer} from './customMapLayer.data';
import {RolePrivilege} from "./privileges.enum";


export enum EFE2Features {
    SHIFT_BOOK = 'SHIFT_BOOK',
    DANGEROUS_GOODS = 'DANGEROUS_GOODS',
    KOHLHAMMER= 'KOHLHAMMER',
    VEHICLE_CARDS= 'VEHICLE_CARDS',
    DEBUG = 'DEBUG',
    HERE_MAPS = 'HERE_MAPS',
    NAVLOG = 'NAVLOG',
    TRACKING = 'TRACKING',
    BOSCH_FORREST = 'BOSCH_FORREST',
    VEHICLE_TRACKING = 'VEHICLE_TRACKING',
    QUEUES = 'QUEUES',
    FAKER = 'FAKER',
    CHAT ='CHAT',
    SCHEDULE_ALARM ='SCHEDULE_ALARM'
}

export interface Keywords {
}

export interface Addresses {
}

export interface Types {
    MANUAL: number;
}

export interface Units {
    Test: number;
}

export interface Statistics {
    username: string;
    month: number;
    year: number;
    keywords: Keywords;
    addresses: Addresses;
    types: Types;
    dates: number[];
    times: number[];
    units: Units;
    cntAlarms: number;
}

export interface LicenceDetails {
    nbrOfPersons: number;
    nbrOfAM3s: number;
    nbrOfAMLs: number;
    nbrOfObjects: number;
    nbrOfDangerousGoods: number;
    nbrOfDangerousSubstances: number;
    nbrOfPatientManagement: number;
    nbrOfAirRescue: number;
    nbrOfHereMaps: number;
    nbrOfKohlhammer: number;
    nbrOfVehicleCards: number;
    maxNbrOfAMLsReached: boolean;
    maxNbrOfAM3sReached: boolean;
    countAlarmObjectsForUser: number;
    maxNbrOfPersonsReached: boolean;
    maxNbrOfObjectsReached: boolean;
}

export interface InstalledVersion {
    version: string;
    type: string;
}

export interface CurrentVersion {
    id: number;
    name: string;
    url: string;
    version: string;
    releaseDate: string;
    changelog: string;
    type: string;
    customerType: string;
    isDeprecated: boolean;
}

export interface Version {
    installed: InstalledVersion;
    current: CurrentVersion;
    lastCheck: Date;
    upToDate: boolean;
    mode: EServerMode;
}

export interface LogLevel {
    levelInt: number;
    levelStr: string;
}

export interface Licences {
    SHIFT_BOOK: LicenceNumbers;
    DANGEROUS_GOODS: LicenceNumbers;
    HERE_MAPS: LicenceNumbers;
    NAVLOG: LicenceNumbers;
    KOHLHAMMER: LicenceNumbers;
    VEHICLE_CARDS: LicenceNumbers;
    LOCATION_MAPS: LicenceNumbers;
}

export interface Server {
    version: string;
    javaVersion: string;
    licenceEmail: string;
    licenceVendor: string;
    type: string;
    gae: string;
    kubernetes: string;
    logLevel: LogLevel;
    persons: LicenceNumbers;
    amobiles: LicenceNumbers;
    alarmMonitors: LicenceNumbers;
    relais: LicenceNumbers;
    objects: LicenceNumbers;
    licences: Licences;
    activeWebsocketConnections: number;
    externalAMwebDNS: string;
    externalDNS: string;
    docker: boolean;
    mapTripId: string;
    mongoVersion: string;
    mongoEngine: string;
    mongoFeatureCompatibility: string
    proxyInformation: ProxyInformation;
    numberOfCustomerNotifications: number;
    pendingKbaApprovals: boolean;
    backendMode: EBackendMode;
    hostName: string;
    lastSurveillanceUpdate: string;
    surveillanceState: ESurveillanceState;
}

export enum ESurveillanceState {
    ACTIVE = 'ACTIVE',
    STARTING = 'STARTING',
    ERROR = 'ERROR'
}

export enum EBackendMode {
    GAE = 'GAE',
    K8S_PROD = 'K8S_PROD',
    K8S_DEV = 'K8S_DEV'
}

export interface ProxyInformation {
    httpProxy: string;
    socksProxy: string;
    withAuthentication: boolean;
    windowsTrustStore: boolean;
}

export interface LicenceNumbers {
    type: LicenceNumbersType,
    total: number,
    used: number
};

export enum LicenceNumbersType {
    NUMERIC = 'NUMERIC',
    STATIC = 'STATIC'
}

export interface Settings {
    wasserkarteInfoToken: string;
    roadblockAccess: string;
    lat: number;
    lng: number;
    street?: any;
    house?: any;
    city?: any;
    postalCode?: any;
    shiftBookStartHour: number;
    shiftBookStartMinutes: number;
    smsflatrateApiKey: string;
    fireboardAuthKey: string;
    geofenceStatusWithoutAlarm: EVehicleStatus;
    settingsSyncApagerPro: EFieldSyncType;
    settingsSyncEmail: EFieldSyncType;
    settingsSyncIssi: EFieldSyncType;
    settingsSyncMobil: EFieldSyncType;
    settingsSyncOsFunctions: EFieldSyncType;
    settingsSyncNote: EFieldSyncType;
    settingsSyncOsGroups: EFieldSyncType;
    settingsSyncAlarmGroup: EFieldSyncType;
    settingsSyncApiKey: string;
    settingsSyncEnabled: boolean;
    displayObjects: boolean;
    amobileObjectTabs: AMobileObjectTab[];
    amobileBaseLayers: AMobileMapBaseLayer[];
    settingsSyncEnabledAlarm: boolean;
    settingsSyncEnabledTracking: boolean;
    googleMapsApiKey: string;
    accessToSmsGatewayAllowed:boolean;
    privacyAndTermsOfUseEnforcedByAdmin: boolean;
    privacyLink: string;
    termsOfUseLink: string;
    useLeaderboard: boolean;
    useAchievements: boolean;
    examinationIntervalInMonths: number;
    forcePipelineProtocol: boolean;
    holidays: string;
    trackingDisplayMode: VehicleTrackingDisplayMode;
    apiAlarmsDisabled: boolean;
    apiKeyToxyk: string;
    ivenaCredentials: IvenaCredentials;
    missionReportExporterSettings: MissionReportExporterSettings;
    announcementLabels: AnnoucementLabels;
    allowedLicencePlate: string;
    navlogKID: string;
}

export interface AnnoucementLabels {
    labelCategory1: string;
    labelCategory2: string;
    labelCategory3: string;
    labelCategory4: string;
    labelCategory5: string;
    labelCategory6: string;
    labelCategory7: string;
    labelCategory8: string;
    labelCategory9: string;
}

export interface IvenaCredentials {
    username: string,
    password: string,
}

export interface UserAccount {
    id: string;
    username: string;
    selfRegisterKey: string;
    personUsername: string;
    personID:string
    external: string;
    statistics: Statistics;
    licenceDetails: LicenceDetails;
    licenceType: string;
    version: Version;
    server: Server;
    exportTemplates: string[];
    // general flag if licence has objects licenced, not orga specific
    hasAlarmObjectsInLicence: boolean;
    hasOnlineService: boolean;
    hasParkingPositions: boolean;
    hasVehicles: boolean;
    features: EFE2Features[];
    fosId: number;
    apiKeyReadOnly?: any;
    settings: Settings;
    inRevisionMode: boolean;
    loginType: ELoginType;
    dontAcceptNewAlarms: boolean;
    licenceInvalid: boolean;
    licenceInvalidationReason: string;
    admin: boolean;
    deletionOfAlarmsDeactivated: boolean;
    customerSmsApiKeyEnforced: boolean;
    customerSmsApiKeyEnforcedWarning: boolean;
    instanceTitle: string;
    hasMailServiceEnabled: boolean;
    personShareFromUser: boolean;
    usersWithAccess: Map<string, string>;
    roles: RoleAccountResponse[];
    privileges: Map<RolePrivilege, boolean>;
    customMapLayer: CustomMapLayer;
    loggedInFromAdmin: boolean;
    sourceUsername: string;
    hasFidoSecureData: boolean;
    hasWeakPW: boolean;
    persistAvailabilityActive: boolean;
    numberPersonsWithOldShare: number;
}

export interface UserTokenResponse{
    token:string;
    username:string;
    loggedInFromAdmin:boolean;
    usersWithAccess: UserWithAccess[];
}

export interface UserWithAccess {
    userId: string;
    name: string;
}

export interface AccountResponse {
    token: string;
    qrCodeSecretKey: string;
    oneTimeToken: string;
    firstAdminLogin: boolean;
    account: UserAccount;
    state: LoginTokenState;
    twoFactorSecretKey: string;
}

export interface SecondFactorValidation {
    key: string
    username: string;
    oneTimeToken: string;
}

export enum LoginTokenState {
    OK = 'OK',
    SECOND_FACTOR_REQUIRED = 'SECOND_FACTOR_REQUIRED',
    FIRST_SECOND_FACTOR_LOGIN = 'FIRST_SECOND_FACTOR_LOGIN'
}

export enum VehicleTrackingDisplayMode {
    NAME = 'NAME',
    SHORT_NAME = 'SHORT_NAME'
}

export interface LoginRequest {
    username: string;
    password: string;
    source: string;
}

export enum EFieldSyncType {
    NEVER = 'NEVER', ALWAYS = 'ALWAYS', INITIAL = 'INITIAL'
}

export enum ELoginType {
    APP = 'APP', USER = 'USER', PERSON = 'PERSON'
}

export enum ChangePasswordMode {
    AMOBILE_PRO = 'AMOBILE_PRO', FE2_MANAGER = 'FE2_MANAGER'
}

export enum AMobileObjectTab {
    INFORMATION = 'INFORMATION',
    HAZARD_HINTS = 'HAZARD_HINTS',
    SAFETY_HINTS = 'SAFETY_HINTS',
    CONTACTS = 'CONTACTS',
    BMA = 'BMA',
    RADIO = 'RADIO',
    EVENTS = 'EVENTS',
    DOCUMENTS = 'DOCUMENTS',
    DRIVES = 'DRIVES',
    STAGING_AREAS = 'STAGING_AREAS',
    HYDRANTS = 'HYDRANTS',
    HOME_EMERGENCY = 'HOME_EMERGENCY',
    RESCUE_SERVICES = 'RESCUE_SERVICES'
}
export interface UserContextSearchResult {
  hasPrevious: false,
  hasNext: false,
  data: UserContext[]
}
export interface UserContext {
  id: string;
  name: string;
}
export interface FidoRegistration {
    id: string;
    alias: string;
    lastLogin: Date;
}
export interface AdminLoginResetResponse{
  newAdminPw:string;
}
export interface AdminLoginResetRequest{
  licenceEMail:string;
  licencePW: string;
}

export interface RevisionState {
    pluginRevision: boolean;
    fe2Revision: boolean;
}

export enum AMobileMapBaseLayer {
    OpenStreetMap = 'OpenStreetMap',
    OpenTopoMap = 'OpenTopoMap',
    BasemapDE = 'BasemapDE',
    TopPlusOpen = 'TopPlusOpen',
    Here_Street = 'Here_Street',
    Here_Satellite = 'Here_Satellite',
    Here_Topo = 'Here_Topo',
    NavLog_Satellite = 'NavLog_Satellite',
    NavLog_Topographic = 'NavLog_Topographic',
    TomTom = 'TomTom'
}

export interface MissionReportExporterSettings {
    exportToPaperlessNgx: boolean;
    paperlessNgxHost: String;
    paperlessApiKey: String;
    exportToMPFeuer: boolean;
    mpFeuerURL:String;
    mpFeuerUser:String;
    mpFeuerPwd:String;
    exportToArigon: boolean;
}