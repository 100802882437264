'use strict';
import {IRootScopeService, IScope} from "angular";
import RestService from "../../../../../services/rest.service";
import PrivilegeService from "../../../../../services/privilege.service";
import {Emergency, EMissionReportState, MissionReport, MissionReportReduced} from "../../../../../data/emergency.data";
import {PersonSearchQueryResult} from "../../../../../data/person.data";


export default class MissionReportTablerowComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      report: "=",
      emergency: "="
    }
    this.template = require('./mission.report.tablerow.component.html');
    this.controller = MissionReportTablerowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
require('./mission.report.tablerow.component.scss')

class MissionReportTablerowController {
  public $scope: IScope;
  public $uibModal;
  public restService: RestService;
  public report: MissionReportReduced;
  public person: PersonSearchQueryResult;
  public emergency: Emergency;
  public isLoadingAMobileInfo: boolean = false;
  public hasExportRight: boolean;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModal, restService: RestService, public privilegeService: PrivilegeService, public $window:any, public $http) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.hasExportRight= privilegeService.privileges['Home_Emergency_Details_Export_Report'];

  }
  getLastEditor(){
      if (this.report){
        return this.report.lastEditorName;
      }
  }
  exportPdf(): void {
    this.$window.open(
      `${this.restService.getBaseUrl()}/missionReports/${encodeURIComponent(this.report.externalId)}/${this.report.reportId}/export?Authorization=${this.$http.defaults.headers.common.Authorization}`,
      '_blank'
    );
  }


  /**
   * get name of leader if present otherwise returns '-'
   */
  getLeaderValue() {
    if (this.report && this.report.missionData && this.report.missionData.ownMissionLeader) {
      return this.report.missionData.ownMissionLeader.displayName;
    }
    return '-'
  }

  /**
   * return right value for aMobile display
   */
  getNameForVehicle(): string {
    if (this.report.vehicle){
      return this.report.vehicle.name;
    }
    return '-';
  }

  getVersion() {
    if (this.report && this.report.revisionNumber) {
      return this.report.revisionNumber;
    } else {
      return 1;
    }
  }

  openReportEdit() {
    this.restService.loadReport(this.report.externalId, this.report.reportId).then((report) => {
      this.$uibModal.open({
        template: require('../../../../modals/alarms/mission.report.modal/mission.report.modal.html'),
        controller: 'ReportModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          missionReport: () => {
            return report;
          },
          emergency: () => {
            return this.emergency;
          },
          isNew: () => {
            return false;
          }, okFunction: () => {
            return (refresh) => {
              if (refresh) {
                this.$scope.$emit("report.changed");
              }
            }
          }
        }
      })
    });
  }
}