import { Emergency } from "../../../../../data/emergency.data";

require("./mission.alarm.component.scss")
/* @ngInject */
export default class MissionAlarmComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./mission.alarm.component.html');
    this.scope = {
      emergency: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = MissionAlarmComponentController;

  }
}

class MissionAlarmComponentController {
  public emergency: Emergency;

  constructor() {

  }
}
