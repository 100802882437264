'use strict';

import { ILogService } from "angular";


/* @ngInject */
export default class ErrorService {
  public $log: ILogService;
  public $translate: any;
  public Notification: any;


  /*This object is a map of all existing error ids and their corresponding message ids:
        key: error id
        value:
            status: error status as integer value
            msgId: id of translated message
    */
  public ERROR_IDS = {
    INVALID_ARGUMENTS: {
      status: 400,
      msgId: 'COMMON.INVALID_ARGUMENTS'
    },
    NOT_FOUND: {
      status: 404,
      msgId: 'COMMON.NOT_FOUND'
    },
    NOT_VALID: {
      status: 412,
      msgId: 'COMMON.NOT_VALID'
    },
    FORBIDDEN: {
      status: 403,
      msgId: 'COMMON.FORBIDDEN'
    },
    UNKNOWN_ERROR: {
      status: 500,
      msgId: 'COMMON.UNKNOWN_ERROR'
    },
    NOT_ACCEPTABLE: {
      status: 406,
      msgId: 'COMMON.NOT_ACCEPTABLE'
    },
    NOT_AUTHORIZED: {
      status: 401,
      msgId: 'COMMON.NOT_AUTHORIZED'
    },
    COULD_NOT_IMPORT_ALL: {
      status: 406,
      msgId: 'USERS.COULD_NOT_IMPORT_ALL'
    },
    NO_AVAILABILITY: {
      status: 401,
      msgId: 'COMMON.NO_AVAILABILITY'
    },
    NO_AVAILABILITY_RIGHT: {
      status: 403,
      msgId: 'COMMON.NO_AVAILABILITY_RIGHT'
    },
    CONFLICT_LAYER: {
      status: 409,
      msgId: 'LAYERS.CONFLICT_LAYER'
    },
    RESSOURCE_LOCKED: {
      status: 423,
      msgId: 'COMMON.MIGRATION_ONGOING'
    },
    LOGIN_NOT_VALID: {
      status: 406,
      msgId: 'LOGIN.NOT_VALID_ANYMORE'
    }
  };

  constructor($log: ILogService, $translate, Notification) {
    $log.debug('Init ErrorService');
    this.$translate = $translate;
    this.Notification = Notification;
  }

  notify(error) {
    this.$translate([error.msgId, 'COMMON.ERROR_TITLE']).then((err) => {
      this.Notification.error({
        message: err[error.msgId],
        title: err['COMMON.ERROR_TITLE']
      });
    });
  };

  notifyWithText(text: string) {
    this.$translate(['COMMON.ERROR_TITLE']).then((err) => {
      this.Notification.error({
        message: text,
        title: err['COMMON.ERROR_TITLE']
      });
    });
  };
}
