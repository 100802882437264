'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import {Person, DataBasePersonForTracking} from "../../../../../data/person.data";
import { CurrentPosition } from "../../../../../data/tracking.data";
import RestService from "../../../../../services/rest.service";
import PrivilegeService from "../../../../../services/privilege.service";
import {RolePrivilege} from "../../../../../data/privileges.enum";

require('./tracking.tablerow.component.css');

export default class TrackingTablerowComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A';
    this.template = require('./tracking.tablerow.component.html');
    this.scope = {
      person: '=',
      active: '='
    };
    this.controller = TrackingTablerowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class TrackingTablerowController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public person: DataBasePersonForTracking;
  public $uibModal;
  public hasTrackingFeature: boolean = false;
  public loading: boolean = false;
  public listeners = [];
  public priv: PrivilegeService;
  public hasLocationExact:boolean= false;

  public position: CurrentPosition;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, restService: RestService, $uibModal, public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.priv = privilegeService;

    this.hasLocationExact= this.priv.has(RolePrivilege.Addressbook_Location_Exact);
    this.listeners.push(this.$scope.$watch('ctrl.person', (person: DataBasePersonForTracking) => {
      // ToDo: check if type error could be caused
      this.person = person;
    }));

    this.listeners.push(this.$scope.$watch('ctrl.active', (hasTrackingFeature: boolean) => {
      this.hasTrackingFeature = hasTrackingFeature;
      // Inital load
      this.loadPosition();
    }));

    // Register location event
    this.listeners.push(this.$rootScope.$on('load.person.locations', () => {
      this.loadPosition();
    }));

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Load the current position from server
   */
  loadPosition() {
    if (!this.hasTrackingFeature) {
      // If feature is not active, do nothing
      return;
    }

    this.$log.info('Load position for person: ' + this.person.displayName);
    this.loading = true;
    this.$scope.$applyAsync();
    this.restService.getCurrentPositionForPerson(this.person).then(position => {
      this.position = position;
      this.loading = false;
      this.$scope.$applyAsync();
    }).catch((err) => {
      // Don't care about eny errors
      this.loading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Open modal an show current location on map
   */
  showOnMap() {
    this.$uibModal.open({
      template: require('../../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.position.lat,
            lng: this.position.lng,
            accuracy: this.position.accuracy
          }
        },
        position: () => {
          return undefined;
        },
        okFunction: () => {
          return (coords) => {
            // Nothing to do
          }
        }
      }
    });
  }
}
