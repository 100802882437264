import {Emergency} from "../../../../../data/emergency.data";
import RestService from "../../../../../services/rest.service";
import {IScope, IWindowService} from "angular";

require("./mission.images.component.scss")
/* @ngInject */
export default class MissionImagesComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./mission.images.component.html');
    this.scope = {
      emergency: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = MissionImagesComponentController;
  }
}

class MissionImagesComponentController {
  public emergency: Emergency;
  private restService: RestService;
  public $scope: IScope;
  private $window: IWindowService;
  private isLoading = false;


  constructor(private $http, $scope: IScope, restService: RestService, $window: IWindowService) {
    this.restService = restService;
    this.$scope = $scope;
    this.$window = $window;
  }

  getImages() {
    this.isLoading = true;
    this.restService.downloadEmergencyImages(this.emergency.mapId, true)
      .then(images => {
        this.emergency.images = images;
        for (let i = 0; i < this.emergency.images.length; i++) {
          this.emergency.images[i].isOpen = false;
        }
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  getImage(id: string) {
    let index = -1;
    for (let i = 0; i < this.emergency.images.length; i++) {
      if (this.emergency.images[i].fileId === id) {
        index = i;
      } else {
        this.emergency.images[i].isOpen = false;
      }
    }

    this.emergency.images[index].isOpen = !this.emergency.images[index].isOpen;
    if (this.emergency.images[index].imgData) {
      return;
    }
    this.restService.downloadEmergencyImage(id)
      .then(image => {
        this.emergency.images[index].imgData = image;
      }).then(() => this.$scope.$applyAsync());
  }

  downloadImage(id: string, type: string) {
    this.$window.open(
      `${this.restService.getBaseUrl()}/emergency/${encodeURIComponent(id)}/download/image?Authorization=${this.$http.defaults.headers.common.Authorization}&contentType=${encodeURIComponent(type)}`,
      '_blank'
    );
  }
}
