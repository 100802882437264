'use strict';

import { IScope } from "angular";

require('./alarm.message.row.component.css');

export default class AlarmMessageTableRowComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      message: '='
    }
    this.template = require('./alarm.message.row.component.html');

    this.controller = AlarmMessageTableRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;

  }
}

class AlarmMessageTableRowComponentController {
  public $scope: IScope;
  public isInPast = false;

  constructor($scope: IScope) {
    this.$scope = $scope;

    this.$scope.$watch('ctrl.message.schedule.timeOnce', (oldValue, newValue) => {
      var now = new Date();
      if (newValue) {
        this.isInPast = now.getTime() > new Date(newValue as string).getTime();
      }
    });
  }
}
