'use strict';

import angular = require('angular');

export default class MapPinComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./map.pin.component.html');
    this.scope = {
      coords: '='
    };
    this.controller = MapPinComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class MapPinComponentController {
	public $scope: any;
	public isHidden: any;
	public coords: any;
	public isSameAsObject: any;

  constructor($scope) {
    this.$scope = $scope;

    this.$scope.$watch('ctrl.coords', () => {
      this.update();
    });
    this.update();
  }

  update() {
    this.isHidden = angular.isDefined(this.coords) && !this.coords.showOnMap;
    this.isSameAsObject = angular.isUndefined(this.coords);
  };
}