'use strict';

import { IRootScopeService, IScope } from "angular";
import { EQueueEntryState, QueueEntryForBroadcast, QueueEntryResponse, QueueEntrySelectedEvent, UpdateQueuePriorityRequest, UpdateQueueStateRequest } from "../../../../data/queue.data";

import RestService from "../../../../services/rest.service";
import * as L from "leaflet";
import MapService from "../../../../services/map.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

'use strict';

import angular = require("angular");
import {UserAccount} from "../../../../data/account.data";
import HelperService from '../../../../services/helper.service';

require('./queue.entry.scss');

export default class QueueEntryComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      entry: '=',
      qact: '='
    }
    this.template = require('./queue.entry.html');

    this.controller = QueueEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class QueueEntryComponentController {
  private entry: QueueEntryResponse;
  private listeners = [];
  private expanded: boolean = false;
  private highlighted: boolean = false;
  private hasQueueEdit: boolean = false;
  public $uibModal: any;
  public marker: L.Marker;
  public map;
  public searchString: string;
  private account: UserAccount;
  public locationString: string;
  private bcQueueSelected: BroadcastChannel = new BroadcastChannel('queue.QUEUE_ENTRY_SELECTED');;

  constructor(public $scope: IScope, helperService: HelperService, public $rootScope: IRootScopeService, public mapService: MapService, public restService: RestService, private privilegeService: PrivilegeService,
    dataService, $uibModal) {
    this.$uibModal = $uibModal;
    this.hasQueueEdit = privilegeService.has(RolePrivilege.Alarm_Queues_Edit);
    this.initListeners();
    this.account = dataService.getAccount();
  }


  initListeners() {
    this.listeners.push(this.$scope.$watch('ctrl.entry', () => {
      this.listeners.push(this.$rootScope.$on(`queue.${this.entry.queueId}.update.state`, (event, queueResponse) => {
        this.entry.queueActive = queueResponse.active;
      }));

      this.listeners.push(this.$rootScope.$on(`queue.entry.${this.entry.id}.update`, (event, queueEntry) => {
        this.entry = queueEntry;
        this.$scope.$applyAsync();
      }));

      this.listeners.push(this.$rootScope.$on('new.alarm', (event, value) => {
        if (value?.externalId === this.entry?.externalId) {
          //get newest alarmdata for open entry
          this.restService.getQueueEntryWithNewestAlarmData(this.entry.id).then(entry => {
            this.entry = entry;
          }).finally(() => this.$scope.$applyAsync());
        }
      }));
    }));

    this.bcQueueSelected.onmessage = (event) => {
      const entry = event?.data?.bcEntry?.entry as QueueEntryResponse;
      if (entry?.id === undefined || entry.id !== this.entry?.id) {
        this.expanded = false;
        this.expand();
      }
    }
    // Highlight element if there is an hover event
    this.listeners.push(this.$rootScope.$on('queue.entry.mouse.enter', (event, queueResponse: QueueEntryResponse) => {
      if (this.entry.id === queueResponse.id) return;
      this.highlighted = this.entry.externalId === queueResponse.externalId;
    }));
    this.listeners.push(this.$rootScope.$on('queue.entry.mouse.leave', (event, queueResponse) => {
      if (this.highlighted) {
        this.highlighted = false;
      }
    }));

    this.$scope.$on('$destroy', () => {
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  priorityUp($event) {
    $event.stopPropagation();

    if (this.entry.state === EQueueEntryState.DONE) return;

    if (this.entry.priority === 4) {
      this.entry.priority = 1;
    } else {
      this.entry.priority++;
    }
    this.changePriority(this.entry.priority);
  }

  changePriority(newPriority: number): void {
    this.restService.updateQueueEntryPriority(this.entry.id, {
      priority: newPriority
    } as UpdateQueuePriorityRequest).then(result => {
      this.entry.priority = result.priority;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  mouseEnter(): void {
    this.$rootScope.$emit('queue.entry.mouse.enter', this.entry);
  };

  mouseLeave(): void {
    this.$rootScope.$emit('queue.entry.mouse.leave', this.entry);
  }

  assign(): void {
    if (!this.entry.queueActive || !this.hasQueueEdit) return;
    this.restService.updateQueueEntryState(this.entry.id, {
      state: EQueueEntryState.IN_PROGRESS,
      queueId: this.entry.queueId
    } as UpdateQueueStateRequest);
  }

  toggleDetailMenu($event) {
    $event.stopPropagation();
    // get current alarmdata for entry from backend again
    this.expanded = true;
    this.expand();
    this.$scope.$applyAsync();
    this.restService.getQueueEntryWithNewestAlarmData(this.entry.id).then(entry => {
      this.entry = entry;
      const bcData = {
        bcEntry: {
          entry: this.entry,
          hasNewAdditionalRequest: this.entry.hasNewAdditionalRequest,
          hasNewImportantMessage: this.entry.hasNewImportantMessage
        } as QueueEntrySelectedEvent
      } as QueueEntryForBroadcast;
      this.bcQueueSelected.postMessage(bcData);
    }).catch(error => {
      // if error occurs, use current available entry data
      const bcData = {
        bcEntry: {
          entry: this.entry,
          hasNewAdditionalRequest: this.entry.hasNewAdditionalRequest,
          hasNewImportantMessage: this.entry.hasNewImportantMessage
        } as QueueEntrySelectedEvent
      } as QueueEntryForBroadcast;
      this.bcQueueSelected.postMessage(bcData);
    });
  }

  expand(): void {
    if (this.expanded) {
      if (this.entry.hasNewAdditionalRequest || this.entry.hasNewImportantMessage) {
        this.restService.updateQueueEntryUnreadMessages(this.entry.id);
      }
    } else {
      // Hide
      let doc = document.getElementById(`${this.entry.id}_expansion`);
      if (doc) {
        doc.classList.add('swing-out-top-bck');
        this.$scope.$applyAsync();
      }
    }
  }

  /**
   * Remove hover css class to avoid weird hover effects
   */
  onMouseEnter($event): void {
    angular.element($event.target).parent().removeClass('queue-entry-hover');
  }

  /**
   * Add hover css again to parent
   */
  onMouseLeave($event): void {
    if (this.entry.state !== EQueueEntryState.ASSIGNED) return;
    angular.element($event.target).parent().addClass('queue-entry-hover');
  }
}
