import { IRootScopeService, IScope } from 'angular';
'use strict';

import { CurrentWorkload } from "../../../../data/workload.data";
import RestService from "../../../../services/rest.service";
import { StatusEntryResponse } from '../../../../data/vehicles.data';

require('./workload.component.scss');

export default class WorkloadComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./workload.component.html');
    this.scope = {
      alarm: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = WorkloadComponentController;

  }
}

class WorkloadComponentController {
  public workloads: CurrentWorkload[] = [];
  public listeners = [];



  constructor(private $scope: IScope, private $rootScope: IRootScopeService, private restService: RestService, public dataService) {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      this.loadWorkload();
    }));

    // Listen for status changes
    this.listeners.push(this.$rootScope.$on('status.change', (event, statusChange: StatusEntryResponse) => {
      if (statusChange.relevantChangeForWorkload) {
        // TODO: Perhaps this is too expensive to reload every workload, but perhaps we just try it that way
        this.loadWorkload();
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    
    // Initial load
    if (dataService.hasAccount()) {
      this.loadWorkload();
    }
  }


  private loadWorkload() {
    this.restService.getCurrentWorkload().then(result => {
      this.workloads = result.workloads;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

}