'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { ActionType, ChangeFunctionGroupForPerson, Person, Type } from "../../../../data/person.data";
import RestService from "../../../../services/rest.service";

export default class PersonSingleSelectComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./person.single.select.component.html');
    this.scope = {
      person: '=',
      selectName: '=',
      selectType: '='
    };
    this.controller = PersonSingleSelectComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class PersonSingleSelectComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $element: any;
  public restService: RestService;
  public isLoading = false;
  public selectType: any;
  public isChecked = false;
  public person: Person;
  public selectName: string;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $element, restService: RestService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$element = $element;
    this.restService = restService;

    this.isLoading = false;
    this.$scope.$watch("ctrl.selectType", () => {
      this.init();
    });
  }


  init() {
    if (this.selectType === 'function') {
      //Function
      this.isChecked = this.person.functions && this.person.functions.indexOf(this.selectName) !== -1;
    } else {
      //Group
      this.isChecked = this.person.groups && this.person.groups.indexOf(this.selectName) !== -1;
    }
  }

  selectPerson() {
    this.isLoading = true;
    this.isChecked = !this.isChecked;
    this.restService.updateFunctionsGroupsForPerson({
      personID: this.person.personID,
      name: this.selectName,
      action: this.isChecked ? ActionType.ADD : ActionType.REMOVE,
      type: this.selectType === 'function' ? Type.FUNCTIONS : Type.GROUPS
    } as ChangeFunctionGroupForPerson).then(() => {
    }).catch(error => {
      this.isChecked = !this.isChecked;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}
