'use strict';

import { ILogService, IScope } from "angular";
import { RolePrivilege } from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";

export default class ParkingPositionComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      parking: '='
    }
    this.template = require('./parking.position.component.html');

    this.controller = ParkingPositionController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//parking
/* @ngInject */
class ParkingPositionController {

  public $scope: IScope;
  public $log: ILogService;
  public dataService: any;
  public helperService: HelperService;
  public $uibModal: any;
  public hasTimeplanPrivilege: boolean;
  public parking: any;

  constructor($scope: IScope, $log: ILogService, dataService, helperService: HelperService, $uibModal,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;
    this.$uibModal = $uibModal;
  }

  edit() {

    if (!this.privilegeService.has(RolePrivilege.Station_Parking)) {
      return;
    }

    this.$uibModal.open({
      template: require('../../../modals/wache/parking.modal/parking.modal.html'),
      controller: 'ParkingModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        parking: () => {
          return this.parking;
        }
      }
    });
  }
}