'use strict';

import {ILocationService, ILogService, IRootScopeService, IScope} from "angular";
import {Version} from "../../../../data/account.data";
import {AlarmData} from "../../../../data/alarm.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import {AnnouncementDisplay} from "../../../../data/announcement.data";

require('./admin.dashboard.component.css');

export default class AdminDashboardComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {}
    this.template = require('./admin.dashboard.component.html');

    this.controller = AdminDashboardComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class AdminDashboardComponentController {
  public $log: ILogService;
  public $location: ILocationService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public adminService: any;
  public dataService: any;
  public listeners: any;
  public alarmWidgetData: any;
  public alarmSchedulesWidgetData: any;
  public inputWidgetData: any;
  public smsWidgetData: any;
  public mapsWidgetData: any;
  public announcementDisplay:AnnouncementDisplay;




  public version: Version;

  constructor($log: ILogService, $rootScope: IRootScopeService, $scope: IScope, $location: ILocationService, dataService, adminService,
    public privilegeService: PrivilegeService,public restService: RestService) {
    this.$log = $log;
    this.$location = $location;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.adminService = adminService;
    this.dataService = dataService;
    this.listeners = [];
    this.version = dataService.getAccount().version;



    this.initWidgets();
    this.reload();

    this.listeners.push(this.$scope.$on('change.to.admin', (event, tabIndex: number) => {
      if (!tabIndex) {
        tabIndex = 0;
      }
      this.$log.info('Change to admin tab ' + tabIndex);
      this.$location.path('/main/admin').search({ param: tabIndex });
    }));

    // Listen for new alarms
    this.listeners.push(this.$rootScope.$on('new.alarm', (event, alarmData: AlarmData) => {
      this.loadNumberOfAlarmSchedules();
      if (this.alarmWidgetData) {
        this.alarmWidgetData.count++;
        if (alarmData.error) {
          this.alarmWidgetData.countError++;
        }
      }
    }));

    // Listen for new alarms
    this.listeners.push(this.$rootScope.$on('delete.scheduled_alarm', (event, data: any) => {
      this.loadNumberOfAlarmSchedules();
    }));

    // Listen for input state updates
    this.listeners.push(this.$rootScope.$on('input.change', () => {
      this.loadNumberOfInputStates();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Init the widgets
   */
  initWidgets() {
    // Todays alarms
    this.alarmWidgetData = {
      count: 0,
      countError: 0,
      state: 'LOADING'
    };

    //AlarmScheduled
    this.alarmSchedulesWidgetData = {
      count: 0,
      state: 'LOADING'
    };

    // Input Plugins
    this.inputWidgetData = {
      count: 0,
      countError: 0,
      state: 'LOADING'
    };

    // SMS
    this.smsWidgetData = {
      count: 0,
      state: 'LOADING'
    };

    // Maps API
    this.mapsWidgetData = {
      count: 0,
      state: 'LOADING'
    };

  };

  /**
   * Open the current changelog
   */
  openChangelog() {
    window.open(this.version.current.changelog, '_blank').focus();
  }



  /**
   * Update all widgets
   */
  updateWidgets() {
    this.alarmWidgetData.state = 'LOADING';
    this.inputWidgetData.state = 'LOADING';
    this.smsWidgetData.state = 'LOADING';
    this.mapsWidgetData.state = 'LOADING';
    this.alarmSchedulesWidgetData.state = 'LOADING';

    this.loadNumberOfAlarms();
    this.loadNumberOfInputStates();
    this.loadNumberOfAlarmSchedules();

    if (this.privilegeService.has(RolePrivilege.Home_Dashboard_SMS_read)) {
      this.adminService.getNumberOfAvailableSMS((data => {
        this.smsWidgetData.count = data.count;
        if (data.count === 0) {
          this.smsWidgetData.state = 'DANGER';
        } else if (data.count < 50) {
          this.smsWidgetData.state = 'WARNING';
        } else {
          this.smsWidgetData.state = 'SUCCESS';
        }
      }), error => {
        this.$log.error('Could not load number of available sms', error);
      });
    }
    if (this.privilegeService.has(RolePrivilege.Announcements)){
      this.restService.loadAnnouncementForWidget().then((response)=>{
        this.announcementDisplay = response;
      }).catch(()=>{
        this.$log.debug('Error loading announcement');
      }).finally(()=>{
        this.$scope.$applyAsync();

      });
    }

    if (this.privilegeService.has(RolePrivilege.Home_Dashboard_Maps_read)) {
      this.adminService.getNumberOfAvailableMapRequests((data => {
        this.mapsWidgetData.count = data.data.remaining;
        if (data.data.remaining === 0) {
          this.mapsWidgetData.state = 'DANGER';
        } else {
          this.mapsWidgetData.state = 'SUCCESS';
        }
      }), error => {
        this.$log.error('Could not load number of available map requests', error);
      });
    }

  }

  loadNumberOfInputStates() {
    if (!this.privilegeService.has(RolePrivilege.Home_Dashboard_Inputs_read)) {
      return;
    }
    this.adminService.getStateOfInputPluginsAsNumbers((data => {
      this.inputWidgetData.count = data.count;
      this.inputWidgetData.countError = data.countError + data.countWarning;
      if (data.countError > 0) {
        this.inputWidgetData.state = 'DANGER';
      } else if (data.countWarning > 0) {
        this.inputWidgetData.state = 'WARNING';
      } else {
        this.inputWidgetData.state = 'SUCCESS';
      }
    }), error => {
      this.$log.error('Could not load input plugins states', error);
    });
  }

  loadNumberOfAlarms() {
    if (!this.privilegeService.has(RolePrivilege.Home_Dashboard_Alarms)) {
      return;
    }
    this.adminService.getNumberOfAlarms((data => {
      this.alarmWidgetData.count = data.count;
      this.alarmWidgetData.countError = data.countError;
      if (data.countError > 0) {
        this.alarmWidgetData.state = 'DANGER';
      } else {
        this.alarmWidgetData.state = 'READY';
      }
    }), error => {
      this.$log.error('Could not load number of alarms for admin dashboard', error);
    });
  }

  loadNumberOfAlarmSchedules() {
    this.adminService.getNumberOfAlarmSchedules((data => {
      this.alarmSchedulesWidgetData.count = data.count;
      this.alarmSchedulesWidgetData.state = 'READY';
    }), error => {
      this.$log.error('Could not load number of alarm schedules for admin dashboard', error);
    });
  }

  reload() {
    this.updateWidgets();
  }

  openAdminPanel(tabIndex: number) {
    this.$scope.$emit('change.to.admin', tabIndex);
  }
}
