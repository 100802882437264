'use strict'


require('./map.view.component.scss');

/* @ngInject */
export default class QueueMapController {


  constructor() {
  }

}
