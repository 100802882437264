'use strict';

import {ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {IOPrintsInfoResponse} from "../../../../data/ioprint.data";
import {isNullOrUndefined} from "angular-ui-router";

require('./ioprint.component.scss');

export default class IoprintComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        addressbook: '='
      }
    this.template = require('./ioprint.component.html')

    this.controller = IOprintController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class IOprintController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $window: IWindowService;
  public $http: any;
  public $uibModal: any;
  public Notification: any;
  public $translate: any;
  public restService: RestService;
  public listeners = [];
  public isLoading = true;
  public data: IOPrintsInfoResponse[];
  public priv: PrivilegeService;
  public hasAM4Create: boolean = false;
  public hasAM4Delete: boolean = false;
  public hasIOprint: boolean = false;
  public isTriggeringRefresh = false;
  public isConfigLoading: boolean = false;
  public testPrinting = [];

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $window: IWindowService, $http, $uibModal, $translate, Notification, restService: RestService, privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$window = $window;
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.$translate = $translate;
    this.Notification = Notification;
    this.restService = restService;
    this.priv = privilegeService;
    this.initListeners();
  }

  /**
   * Loads the entries paginated
   * @returns
   */
  pageChanged() {
    if (!this.hasIOprint) {
      return Promise.reject("Missing Permission for IOprint");
    }

    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadIOprints()
        .then((result: IOPrintsInfoResponse[]) => {
          this.data = result;
        }).catch(err => this.$log.error(err))
        .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
        resolve();
      });
    });
  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('addressbook.view.tab', (event, source) => {
      if (source === 8) {
        this.hasIOprint = this.priv.has(RolePrivilege.Addressbook_IOPrint_Read);
        // Trigger reload
        this.pageChanged();
      }
    }));
    this.listeners.push(this.$rootScope.$on('ioprint.update.state', (event, source) => {
      let index = this.data.findIndex(x => x.id === source.id);
      if (index >= 0) {
        this.data[index] = source;
        this.$scope.$applyAsync();
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  loadIOprintConfigString(id: string) {
    this.isConfigLoading = true;
    this.restService.loadIOPrintConfigString(id).then((result) => {
      var data = [new ClipboardItem({'text/plain': new Blob([result as string], {type: 'text/plain'})})];
      navigator.clipboard.write(data).then(() => {
        this.$translate(['ADMIN.CLIPBOARD_MSG', 'ADMIN.CLIPBOARD_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['ADMIN.CLIPBOARD_MSG'],
            title: translations['ADMIN.CLIPBOARD_TITLE']
          });
        });
      });

    }).catch(err => this.$log.error(err))
      .finally(() => {
      this.isConfigLoading = false;
      this.$scope.$applyAsync();
    });
  }

  parseError(ioprint: IOPrintsInfoResponse): string {
    if ("Stopped" === ioprint.status) {
      return "OLD_HB";
    }
    if ("Unknown" === ioprint.status) {
      return "NO_HB";
    }

    if ("HasError" === ioprint.status) {
      if (isNullOrUndefined(ioprint.printers) || ioprint.printers.length == 0) {
        return "NO_PRINTERS";
      } else {
        return "PRINTER_HAS_ERROR";
      }
    }
    return "OK";
  }

  printTestPage(id: string) {
    this.testPrinting.push(id);
    this.restService.printTestPage(id).then(() => {
      this.$translate(['IOPRINT_OVERVIEW.TEST_PRINT_NOTIFICATION_HEADER', 'IOPRINT_OVERVIEW.TEST_PRINT_NOTIFICATION_BODY']).then((translations) => {
        this.Notification.success({
          message: translations['IOPRINT_OVERVIEW.TEST_PRINT_NOTIFICATION_BODY'],
          title: translations['IOPRINT_OVERVIEW.TEST_PRINT_NOTIFICATION_HEADER']
        });
      });
    }).catch(err => this.$log.error(err))
      .finally(() => {
      this.testPrinting = this.testPrinting.filter(item => item !== id);
      this.$scope.$applyAsync();
    });
  }

  isTestPrinting(id: string){
    return this.testPrinting.includes(id);
  }

  public openPrintJobs(ioprint: IOPrintsInfoResponse) {
    this.$uibModal.open({
      template: require('../../../modals/addressbook/ioprint.jobs.modal/ioprint.jobs.modal.html'),
      controller: 'IOprintJobsModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        ioprintInfo: () => {
          return ioprint;
        }
      }
    });
  }

}
