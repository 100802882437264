'use strict';

/* @ngInject */
export default class ConfirmMoveModalController {
  constructor(private $uibModalInstance) {}

  cancel() {
    this.$uibModalInstance.close(false);
  };

  ok() {
    this.$uibModalInstance.close(true);
  };


}
