'use strict';

/* @ngInject */
export default class ConfirmDeleteSubObjectController {
	public $uibModalInstance: any;
	public okFunction: any;
	public name: any;

  constructor($uibModalInstance, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.name = '';
  }

  cancel() {
    this.$uibModalInstance.close();
  };
  deleteAndKeep() {
    this.$uibModalInstance.close();
    this.okFunction(true);
  };

  delete() {
    this.$uibModalInstance.close();
    this.okFunction(false);
  };
}