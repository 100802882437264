'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import PrivilegeService from "../../../../services/privilege.service";
import { RolePrivilege } from "../../../../data/privileges.enum";
import RestService from "../../../../services/rest.service";

//Controller for functions and groups modal

require('./functions.groups.modal.css');

/* @ngInject */
export default class FunctionsGroupsModalController {
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $uibModal: any;
  public $log: ILogService;
  public $uibModalInstance: any;
  public mode: any;
  public nFU: any;
  public onlineservice: any;
  public isLoading = true;
  public priv: PrivilegeService;
  public restService: RestService;
  public hasEdit: boolean = false;
  public hasAddrPersonEdit: boolean = false;

  constructor($rootScope: IRootScopeService, $scope: IScope, $uibModal, $log: ILogService, $uibModalInstance, privilegeService: PrivilegeService, restService: RestService) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.$uibModalInstance = $uibModalInstance;
    this.mode = 'FUNCTIONS';
    this.priv = privilegeService;
    this.restService = restService;

    this.nFU = {
      group: '',
      function: ''
    }
    this.hasEdit = this.priv.has(RolePrivilege.Addressbook_Functions_Groups_Edit);
    this.hasAddrPersonEdit = this.priv.has(RolePrivilege.Addressbook_Persons_Edit);
    //Init
    this.restService.loadAvailabilityForAddressbook(false).then((value => {
      this.onlineservice = value;
    })).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  close() {
    this.$rootScope.$emit('change.addressbook');
    this.$uibModalInstance.close();
  }

  /**
   * Add a new function
   */
  addFunction() {
    if (this.nFU.function === '') {
      return;
    }
    this.isLoading = true;

    this.restService.addFunction(this.nFU.function, (response) => {
      this.isLoading = false;
      this.nFU.function = '';
      this.restService.loadAvailabilityForAddressbook(true).then((value => {
        this.onlineservice = value;
        this.$scope.$applyAsync();
      }));
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  }

  /**
   * Delete a function
   */
  deleteFunction(functionToDelete: string) {
    this.isLoading = true;

    this.restService.deleteFunction(functionToDelete, (data) => {
      this.isLoading = false;
      this.restService.loadAvailabilityForAddressbook(true).then((value => {
        this.onlineservice = value;
        this.$scope.$applyAsync();
      }));
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  }

  /**
   * Add a new group
   */
  addGroup() {
    if (this.nFU.group === '') {
      return;
    }
    this.isLoading = true;

    this.restService.addGroup(this.nFU.group, (data) => {
      this.isLoading = false;
      this.nFU.group = '';
      this.restService.loadAvailabilityForAddressbook(true).then((value => {
        this.onlineservice = value;
        this.$scope.$applyAsync();
      }));
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  }

  /**
   * Delete a group
   */
  deleteGroup(groupToDelete: string) {
    this.isLoading = true;

    this.restService.deleteGroup(groupToDelete, (data) => {
      this.isLoading = false;
      this.restService.loadAvailabilityForAddressbook(true).then((value => {
        this.onlineservice = value;
        this.$scope.$applyAsync();
      }));
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  }

  /**
   * Open the modal to show all functions
   */
  openSingleSelectModal(selectName, selectType) {
    if (!this.hasAddrPersonEdit) {
      // do not open modal if Edit of Person isn't allowed to have parallel privilege handling to person modal
      return;
    }

    this.$uibModal.open({
      template: require('../../../modals/availability/single.select.modal/single.select.modal.html'),
      controller: 'SingleSelectModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        selectName: () => { return selectName },
        selectType: () => { return selectType },
        filterForApager: () => { return false }
      }
    });
  }
}
