'use strict';

import RestService from "../../../../services/rest.service";
import { IRootScopeService, IScope } from "angular";
import { User } from "../../../../data/admin.data";
import { AlarmObjectSearchResult, SimpleAlarmObject } from "../../../../data/objects.data";

require('./admin.users.alarmobjects.component.scss')


export default class AdminUsersAlarmObjectsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.alarmobjects.component.html');
    this.scope = {
      user: '=',
    };
    this.controller = AdminUsersAlarmObjectsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
/* @ngInject */
class AdminUsersAlarmObjectsController {
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public user: User;

  public isLoadingAssigned = false;
  public isLoadingObjects = false;
  public searchFilterAssigned: string = '';

  public assignedObjects: SimpleAlarmObject[] = [];
  public filteredAssignedObjects: SimpleAlarmObject[] = [];

  public currentPage = 0;
  public limit = 50;
  public searchFilter = '';
  public data = {
    hasPrevious: false,
    hasNext: false,
    data: []
  } as AlarmObjectSearchResult;


  constructor(restService: RestService, $scope: angular.IScope, $rootScope: angular.IRootScopeService) {
    this.restService = restService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;

    this.$scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (newValue) {
        this.user = newValue;
        this.load();
      }
    });
  }

  load(): void {
    this.isLoadingAssigned = true;
    this.restService.loadAssignedAlarmObjectsForUser(this.user.id).then(assignedObjects => {
      this.assignedObjects = assignedObjects;
      this.filteredAssignedObjects = [];
      this.assignedObjects.forEach(entry => this.filteredAssignedObjects.push(entry));
    }).finally(() => {
      this.isLoadingAssigned = false;
      this.$scope.$applyAsync();
    });
  }

  filterAssigned(): void {
    this.filteredAssignedObjects = [];
    if (!this.searchFilterAssigned) {
      this.assignedObjects.forEach(entry => this.filteredAssignedObjects.push(entry));
      return;
    } else {
      const tmp = this.searchFilterAssigned.toLowerCase();
      this.filteredAssignedObjects = this.assignedObjects.filter(entry => {
        if (entry.name.toLowerCase().includes(tmp)) {
          return true;
        }
        if (entry.ident.toLowerCase().includes(tmp)) {
          return true;
        }
        if (entry.city.toLowerCase().includes(tmp)) {
          return true;
        }
        if (entry.street.toLowerCase().includes(tmp)) {
          return true;
        }
        return false;
      });
    }
  }

  resetAssignedSearchFilter(): void {
    this.searchFilterAssigned = '';
    this.filterAssigned();
  }

  removeFromOrganisation(alarmObject: SimpleAlarmObject) {
    this.isLoadingAssigned = true;
    this.restService.removeAlarmObjectsAssignment(this.user.id, alarmObject).then(() => {
      this.filteredAssignedObjects.splice(this.assignedObjects.indexOf(alarmObject), 1);
      this.assignedObjects.splice(this.assignedObjects.indexOf(alarmObject), 1);
    }).finally(() => {
      this.isLoadingAssigned = false;
      this.$scope.$applyAsync();
    });
  }

  addAllVisibleToOrganisation(): void {
    this.data.data.forEach(alarmObject => {
      this.addToOrganisation(alarmObject);
    });
  }

  /**
   * Add all existing alarm objects to user
   */
  addAllExistingToOrganisation(): void {
    this.isLoadingAssigned = true;
    this.restService.addAlarmObjectsAssignment(this.user.id).then(() => {
      this.load();
    }).finally(() => {
      this.isLoadingAssigned = false;
      this.$scope.$applyAsync();
    });
  }

  isSelected(alarmObject: SimpleAlarmObject): boolean {
    return this.assignedObjects.filter(ao => ao.id === alarmObject.id).length > 0;
  }

  addToOrganisation(alarmObject: SimpleAlarmObject) {
    this.isLoadingAssigned = true;
    this.restService.addAlarmObjectsAssignment(this.user.id, alarmObject).then(() => {
      this.assignedObjects.unshift(alarmObject);
      this.filteredAssignedObjects.unshift(alarmObject);
    }).finally(() => {
      this.isLoadingAssigned = false;
      this.$scope.$applyAsync();
    });
  }

  searchAlarmObject() {
    this.isLoadingObjects = true;
    this.restService.searchForAlarmObjectForAssignments(this.currentPage, this.limit, this.searchFilter, this.user.name)
      .then((response: AlarmObjectSearchResult) => {
        this.data = response;
      }).finally(() => {
        this.isLoadingObjects = false;
        this.$scope.$applyAsync();
      });
  }

  nextPage() {
    this.currentPage++;
    this.searchAlarmObject();
  }

  previousPage() {
    if (this.currentPage === 0) return;
    this.currentPage--;
    this.searchAlarmObject();
  }

  resetSearchAndReload(): void {
    this.currentPage = 0;
    this.searchFilter = '';
    this.data = {
      hasPrevious: false,
      hasNext: false,
      data: []
    } as AlarmObjectSearchResult;
  }
}