import { IScope } from 'angular';
import { IRootScopeService } from 'angular';
import { ILogService } from 'angular';
import { RolePrivilege } from '../../../data/privileges.enum';
import PrivilegeService from '../../../services/privilege.service';
import RestService from '../../../services/rest.service';
import { UserAccount } from './../../../data/account.data';
'use strict';

require('./statistic.view.component.css');

/* @ngInject */
export default class StatisticsController {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $uibModal: any;
  public $state: any;
  public dataService: any;
  public restService: RestService;
  public listeners: any;
  public account: UserAccount;
  public startMonth: number;
  public endMonth: number;
  public startYear: number;
  public endYear: number;
  public hours: any;
  public date: any;
  public type: any;
  public keywords: any;
  public units: any;
  public isLoading: boolean;

  constructor($rootScope: IRootScopeService, $scope: IScope, $log: ILogService, $state, $uibModal, dataService, restService: RestService,
    public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$log.debug('StatisticsController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.dataService = dataService;
    this.restService = restService;

    this.listeners = [];
    this.initListeners();

    if (this.dataService.hasAccount()) {
      this.init();
    }
  }

  init() {

    if (!this.privilegeService.has(RolePrivilege.Statistics)) {
      this.$state.go('main.' + this.dataService.selectPageForPrivilege());
      return;
    }

    this.account = this.dataService.getAccount();
    var d = new Date();
    this.startMonth = d.getMonth() + 1;
    this.endMonth = this.startMonth;
    this.startYear = d.getFullYear();
    this.endYear = this.startYear;
    //Init hours
    this.hours = [];
    for (var i = 0; i < 24; i++) {
      this.hours.push({
        value: 0
      });
    }
    this.setDataToDiagram();
  };

  setDataToDiagram() {
    //Init Day-Chart
    this.date = {
      labels: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      data: [this.account.statistics.dates]
    };

    //Init Type-Chart
    var types = this.account.statistics.types;
    var keys = [];
    var values = [];
    for (var k in types) {
      keys.push(k);
      values.push(types[k]);
    }

    if (keys.length === 0) {
      keys.push('-');
      values.push(0);
    }

    this.type = {
      labels: keys,
      data: values
    };

    //Init Keyword-Chart
    var keywords = this.account.statistics.keywords;
    values = [];

    for (var k in keywords) {
      values.push({
        name: k,
        value: keywords[k]
      });
    }
    values.sort((a, b) => {
      return b.value - a.value
    });
    this.keywords = values;

    //Init Unit-Chart
    var units = this.account.statistics.units;
    values = [];

    for (var k in units) {
      values.push({
        name: k,
        value: units[k]
      });
    }
    values.sort((a, b) => {
      return b.value - a.value
    });
    this.units = values;

    //Times
    for (var i = 0; i < this.account.statistics.times.length; i++) {
      this.hours[i].value = this.account.statistics.times[i];
    }
  }

  /**
    Load the statistics for this user
  */
  loadStatistics() {

    if (this.startMonth && this.startYear && this.endMonth && this.endYear) {
      this.isLoading = true;
      this.restService.loadStatistics(this.startMonth, this.startYear, this.endMonth, this.endYear,
        (response) => {
          this.isLoading = false;
          this.account.statistics = response.data;
          this.$log.log(response.data);
          this.setDataToDiagram();
        },
        (response) => {
          //Error occured
          this.isLoading = false;
          this.$log.error(response);
        });
    }
  }

  /**
    Reset statistics
  */
  resetStatistics() {

    this.$uibModal.open({
      template: require('../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      backdrop: 'static',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.restService.resetStatistics(this.startMonth, this.startYear, this.endMonth, this.endYear, () => {
              this.isLoading = false;
              this.loadStatistics();
            }, (response) => {
              //Error occured
              this.isLoading = false;
              this.$log.error(response);
            });
          }
        },
        additionalText: () => {
          return;
        }
      }
    });
  };


  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      //Init controller
      this.init();
    }));

    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.account = undefined;
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}
