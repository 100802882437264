'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { WmsLayerData, WmsLayerDataUpdateRequest, WmsServerData } from "../../../../../data/custom.map.server.data";
import { CustomMapLayer, ECustomMapLayerType, SingleMapLayer } from "../../../../../data/customMapLayer.data";
import RestService from "../../../../../services/rest.service";

require('./admin.map.component.css');

export default class AdminMapSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.map.component.html');
    this.scope = {
    };
    this.controller = AdminMapComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminMapComponentController {
  private isLoading: boolean = false;
  private layerSettings: CustomMapLayer;
  private overlayServerData: WmsServerData;
  private overlayLayers: WmsLayerData[];

  private selectedBaseLayerIndex = 1;
  private selectedBaseLayer: SingleMapLayer;


  constructor(private $scope: IScope,
    private $rootScope: IRootScopeService,
    private restService: RestService,
    private dataService,
    private $log: ILogService
  ) {
    this.load();
  }


  /**
   * Load map settings
   */
  load() {
    this.layerSettings = this.dataService.getAccount().customMapLayer;
    if (!this.layerSettings) {
      this.layerSettings = {
        type: ECustomMapLayerType.TILE,
        name: "",
        url: "",
        type2: ECustomMapLayerType.TILE,
        name2: "",
        url2: "",
        type3: ECustomMapLayerType.TILE,
        name3: "",
        url3: "",
        type4: ECustomMapLayerType.TILE,
        name4: "",
        url4: "",
        wmsParameters: {
          layers: "",
          styles: "",
          format: "",
          version: ""
        },
        wmsParameters2: {
          layers: "",
          styles: "",
          format: "",
          version: ""
        },
        wmsParameters3: {
          layers: "",
          styles: "",
          format: "",
          version: ""
        },
        wmsParameters4: {
          layers: "",
          styles: "",
          format: "",
          version: ""
        }

      }
    }
    this.selectBaseLayer(1);
    this.restService.loadWmsServerData()
      .then(data => this.overlayServerData = data)
      .then(() => this.restService.loadAvailableWmsLayers())
      .then(layers => this.overlayLayers = layers)
      .catch(err => this.$log.error(err))
      .finally(() => this.$scope.$applyAsync());
  }

  /**
   * Save map settings
   */
  saveLayer() {
    this.persistBaseLayer();
    this.isLoading = true;
    this.dataService.getAccount().customMapLayer = this.layerSettings;
    this.restService.saveCustomMapLayer(this.layerSettings).then(response => {
      this.layerSettings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Persist the current base layer into settings datamodel
   */
  persistBaseLayer() {
    if (!this.selectedBaseLayer) {
      return;
    }
    switch (this.selectedBaseLayerIndex) {
      case 1:
        this.layerSettings.name = this.selectedBaseLayer.name;
        this.layerSettings.url = this.selectedBaseLayer.url;
        this.layerSettings.type = this.selectedBaseLayer.type;
        this.layerSettings.wmsParameters = this.selectedBaseLayer.wmsParameters;
        break;
      case 2:
        this.layerSettings.name2 = this.selectedBaseLayer.name;
        this.layerSettings.url2 = this.selectedBaseLayer.url;
        this.layerSettings.type2 = this.selectedBaseLayer.type;
        this.layerSettings.wmsParameters2 = this.selectedBaseLayer.wmsParameters;
        break;
      case 3:
        this.layerSettings.name3 = this.selectedBaseLayer.name;
        this.layerSettings.url3 = this.selectedBaseLayer.url;
        this.layerSettings.type3 = this.selectedBaseLayer.type;
        this.layerSettings.wmsParameters3 = this.selectedBaseLayer.wmsParameters;
        break;
      case 4:
        this.layerSettings.name4 = this.selectedBaseLayer.name;
        this.layerSettings.url4 = this.selectedBaseLayer.url;
        this.layerSettings.type4 = this.selectedBaseLayer.type;
        this.layerSettings.wmsParameters4 = this.selectedBaseLayer.wmsParameters;
        break;
    }
  }

  selectBaseLayer(baseLayer: number) {
    this.persistBaseLayer();
    this.selectedBaseLayerIndex = baseLayer;
    this.selectedBaseLayer = {
      type: ECustomMapLayerType.TILE,
      name: "",
      url: "",
      wmsParameters: {
        layers: "",
        styles: "",
        format: "",
        version: ""
      }
    } as SingleMapLayer;
    switch (baseLayer) {
      case 1:
        this.selectedBaseLayer.name = this.layerSettings.name;
        this.selectedBaseLayer.url = this.layerSettings.url;
        this.selectedBaseLayer.type = this.layerSettings.type;
        this.selectedBaseLayer.wmsParameters = this.layerSettings.wmsParameters;
        break;
      case 2:
        this.selectedBaseLayer.name = this.layerSettings.name2;
        this.selectedBaseLayer.url = this.layerSettings.url2;
        this.selectedBaseLayer.type = this.layerSettings.type2;
        this.selectedBaseLayer.wmsParameters = this.layerSettings.wmsParameters2;
        break;
      case 3:
        this.selectedBaseLayer.name = this.layerSettings.name3;
        this.selectedBaseLayer.url = this.layerSettings.url3;
        this.selectedBaseLayer.type = this.layerSettings.type3;
        this.selectedBaseLayer.wmsParameters = this.layerSettings.wmsParameters3;
        break;
      case 4:
        this.selectedBaseLayer.name = this.layerSettings.name4;
        this.selectedBaseLayer.url = this.layerSettings.url4;
        this.selectedBaseLayer.type = this.layerSettings.type4;
        this.selectedBaseLayer.wmsParameters = this.layerSettings.wmsParameters4;
        break;
    }
  }

  private setTypeTile(): void {
    this.setType(ECustomMapLayerType.TILE)
  }

  private setTypeWms(): void {
    this.setType(ECustomMapLayerType.WMS)
  }

  private setType(type: ECustomMapLayerType): void {
    this.selectedBaseLayer.type = type;
    if (type === ECustomMapLayerType.TILE) {
      return;
    }
    if (!this.selectedBaseLayer.wmsParameters) {
      this.selectedBaseLayer.wmsParameters = {
        layers: "",
        styles: "",
        format: "",
        version: ""
      }
    }
  }

  saveOverlay() {
    this.isLoading = true;
    this.restService.saveWmsServerData(this.overlayServerData)
      .then(res => this.overlayServerData = res)
      .then(() => this.restService.loadAvailableWmsLayers())
      .then(layers => this.overlayLayers = layers)
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }



  toggleLayer(layer: WmsLayerData) {
    let updateRequest = {
      active: !layer.active,
      category: layer.category
    }
    this.updateLayer(layer.name, updateRequest);
  }

  updateLayerCategory(layer: WmsLayerData, category: string) {
    let updateRequest = {
      active: layer.active,
      category
    }
    this.updateLayer(layer.name, updateRequest);
  }


  private updateLayer(name: string, updateRequest: WmsLayerDataUpdateRequest) {
    this.isLoading = true;
    this.restService.updateWmsLayerData(name, updateRequest)
      .then(res => this.overlayLayers.filter(l => l.name === res.name).forEach(l => l.active = res.active))
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }
}
