import {IScope} from "angular";
import { User } from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";

export class SaveDeleteOrganisationModalController{
  public $scope: IScope;
  public $uibModalInstance: any;
  public okFunction: any;
  public deleteOS: boolean;
  public user: User;
  public hasOnlineService: boolean;

  constructor($scope: IScope, $uibModalInstance, okFunction, user: User, hasOnlineService: boolean) {
    this.$scope = $scope;
    this.$uibModalInstance= $uibModalInstance;
    this.okFunction = okFunction;
    this.user = user;

    this.hasOnlineService = hasOnlineService;
    this.deleteOS = true;

  }

  cancel(){
    this.$uibModalInstance.close();
  }
  ok(){
    this.$uibModalInstance.close();
    this.okFunction(this.deleteOS);
  }
}