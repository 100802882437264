'use strict';

import {ILogService, IScope} from "angular";
import PrivilegeService from "../../../../services/privilege.service";

export default class TimeplanComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.scope = {
      timeplan: '=',
      edit: '='
    }
    this.template = require('./timeplan.component.html');

    this.controller = TimeplanController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//timeplan
/* @ngInject */
class TimeplanController {
  public $scope: IScope;
  public $log: ILogService;
  public selectionModeAll: any;
  public timeplan: any;
  public edit: any;

  constructor($scope: IScope, $log: ILogService) {
    this.$scope = $scope;
    this.$log = $log;

    this.selectionModeAll = false;
  }

  select(day, index) {
    day[index] = !day[index];
  }

  /**
   * Select or deselect all checkboxes
   */
  selectDeselectAll() {
    this.selectionModeAll = !this.selectionModeAll;
    var isSelected = this.selectionModeAll;
    for (var i = 0; i < this.timeplan.times.length; i++) {
      var arr = this.timeplan.times[i];
      for (var ii = 0; ii < arr.length; ii++) {
        arr[ii] = isSelected;
      }
    }
  }

  /**
   * Select a whole row
   */
  selectDeselectRow(day) {
    var all = !day[0];
    for (var ii = 0; ii < day.length; ii++) {
      day[ii] = all;
    }
  }
}