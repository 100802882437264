'use strict'

import {IDocumentService, ILogService, IRootScopeService, IScope} from "angular";
import RestService from "../../../../services/rest.service";
import * as L from 'leaflet';
import MapService from "../../../../services/map.service";
import {
  EVehicleStatus,
  Segment,
  Vehicle,
  VehicleLocationChangeData,
  VehicleLocationResponse,
  VehicleTrackingMode
} from "../../../../data/vehicles.data";
import VehicleService from "../../../../services/vehicle.service";
import ErrorService from "../../../../services/error.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {Settings} from "../../../../data/account.data";

require("./vehicle.location.component.scss")

export default class VehicleLocationComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./vehicle.location.component.html');
    this.scope = {
      vehicle: '='
    }
    this.controller = VehicleLocationComponentController
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class VehicleLocationComponentController {

  private map: any;
  private vehicleMarker: any;
  private lines = [];
  public history: boolean = false;
  public startTime: Date = new Date();
  public endTime: Date = new Date();

  public vehicle: Vehicle;
  public positionData: VehicleLocationResponse;

  public statusColorMapping: Map<EVehicleStatus, string>;
  public statusTextColorMapping: Map<EVehicleStatus, string>;
  public statusTranslationMapping: Map<EVehicleStatus, string>;

  private listeners = [];

  public isLoading = false;

  constructor(private $scope: IScope,
              private $rootScope: IRootScopeService,
              private $log: ILogService,
              private restService: RestService,
              private $document: IDocumentService,
              private vehicleService: VehicleService,
              private errorService: ErrorService,
              private mapService: MapService,
              private privilegeService: PrivilegeService, private dataService) {

    this.startTime.setHours(this.endTime.getHours() - 1);
    this.startTime.setSeconds(0);
    this.startTime.setMilliseconds(0);
    this.endTime.setSeconds(0);
    this.endTime.setMilliseconds(0);

    this.statusColorMapping = new Map<EVehicleStatus, string>();
    this.statusTextColorMapping = new Map<EVehicleStatus, string>();
    this.statusTranslationMapping = new Map<EVehicleStatus, string>();

    this.$document.ready(() => {
      this.loadStatusColors()
        .then(() => {
          if (this.vehicle.trackingMode === VehicleTrackingMode.ALWAYS) {
            return this.restService.loadVehicleLocation(this.vehicle.id);
          } // never initially load marker for mission if vehicle is in mission the marker will appear on the first received event
        })
        .then(positionData => {
          this.positionData = positionData;
          if (this.positionData) {
            this.initMap(positionData);
          } else {
            this.initMapWithoutCurrentPos()
          }
        })
        .catch(err => {
          this.$log.error(err.data.message)
          this.errorService.notifyWithText(err.data.message);
        });
    });
    this.listeners.push(this.$rootScope.$on('vehicle.location.change', (event, data: VehicleLocationChangeData) => {
      if (this.vehicle) {
        if (this.vehicle.trackingMode === VehicleTrackingMode.ALWAYS) {
          this.reloadPositionData(data);
        }
        // only apply changes if the vehicle is in mission if events one matches otherwise DEV-22723 bug could occur again

        if (this.vehicle.trackingMode === VehicleTrackingMode.MISSION) {
          if (data.mode === VehicleTrackingMode.MISSION) {
            if (!this.positionData) {
              this.restService.loadVehicleLocation(this.vehicle.id).then(pos => {
                this.positionData = pos;
                this.applyPositionData(this.positionData);
              });
            } else {
              this.reloadPositionData(data);
            }
          }
        }
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  private reloadPositionData(data: VehicleLocationChangeData) {
    if (this.vehicle.id !== data.vehicleId) return;
    this.vehicleLocationChanged(data);
    const timestamp = new Date(data.timestamp);
    if (this.history && this.startTime < timestamp && this.endTime > timestamp) {
      // line is built in backend, so we have to reload all data
      this.loadAndShowHistory();
    }
  }

  initMapWithoutCurrentPos() {
    L.Icon.Default.imagePath = '/img/static';

    this.map = L.map('vehicle-map', {
      worldCopyJump: true
    });

    this.initLayers(this.map);
    const settings: Settings = this.dataService.getAccount().settings;
    // set home coordinates as views center, because no current position marker is available
    if (settings.lat && settings.lng) {
      this.map.setView([settings.lat, settings.lng], 15);
    }

  }

  initMap(positionData: VehicleLocationResponse) {
    L.Icon.Default.imagePath = '/img/static';

    this.map = L.map('vehicle-map', {
      worldCopyJump: true
    });
    this.initLayers(this.map);
    this.map.setView([positionData.lat, positionData.lng], 15);

    this.applyPositionData(positionData);
  }

  private applyPositionData(positionData: VehicleLocationResponse) {
    const coords = [positionData.lat, positionData.lng];

    this.map.panTo(coords);
    this.vehicleMarker = this.createVehicleMarker(positionData);
    this.vehicleMarker.addTo(this.map);
  }

  private createVehicleMarker(vehicle: VehicleLocationResponse) {
    const icon = this.buildVehicleMarkerIcon(vehicle);
    const position = [vehicle.lat, vehicle.lng];
    const marker = L.marker(position, {icon, title: vehicle.name});
    const popup = L.popup({closeButton: true})
      .setContent(this.buildPopupContent(vehicle));
    marker.bindPopup(popup);
    return marker;

  }

  private buildVehicleMarkerIcon(vehicle: VehicleLocationResponse) {
    return L.divIcon({
      className: 'tracking-marker',
      html: this.buildVehicleMarkerHtml(vehicle),
      popupAnchor: [0, -25]
    });
  }

  private buildVehicleMarkerHtml(vehicle: VehicleLocationResponse) {
    return `<div class="tracking-icon-content" style="background: ${vehicle.statusColor};color: ${vehicle.statusTextColor};">` +
      `<div class="tracking-icon-content-status">${vehicle.statusValue}</div>` +
      '<div class="tracking-icon-content-data">' +
      '<div class="tracking-icon-content-data-name">' + vehicle.name + '</div>' +
      '</div>' +
      '</div>';
  }

  private initLayers(map: any) {
    const mapService = this.mapService;
    const layers = mapService.getBaseLayersForTracking();
    L.control.layers(layers).addTo(map);

    let selectedLayer = mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(map);

    map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });
  }

  private buildPopupContent(vehicle: VehicleLocationResponse): string {
    const statusTranslation = this.statusTranslationMapping ? this.statusTranslationMapping[vehicle.status] : vehicle.status;
    const timestamp = new Date(vehicle.timestamp).toLocaleString();

    const heightLine = vehicle.altitude != null ?
      `<br> Höhe: <b>${vehicle.altitude}m</b>` : "";

    const source = vehicle.source ?
      `<br> <i>${vehicle.source}</i>` : "";

    return `<div class="vehicle-data-popup">
                        <b>${vehicle.name}</b>
                        ${heightLine}
                        <br>
                        ${statusTranslation}
                        <br ng-if="vehicle.externalResourceId">
                        <i ng-if="vehicle.externalResourceId">${vehicle.externalResourceId}</i>
                        <br>
                        <i>${timestamp}</i>
                        ${source}
                    </div>`;
  }


  private vehicleLocationChanged(data: VehicleLocationChangeData) {
    this.positionData.lat = data.lat;
    this.positionData.lng = data.lng;
    this.positionData.altitude = data.alt;
    this.positionData.timestamp = data.timestamp;
    this.positionData.status = data.status;


    this.vehicleMarker.setIcon(this.buildVehicleMarkerIcon(this.positionData));
    this.vehicleMarker.setPopupContent(this.buildPopupContent(this.positionData));
    this.vehicleMarker.setLatLng([data.lat, data.lng]);
    if (!this.history) {
      this.map.panTo([data.lat, data.lng]);
    }
  }

  public toggleHistory() {
    this.history = !this.history;
    this.loadAndShowHistory();
  }

  public reloadMap() {
    this.loadAndShowHistory();
  }

  public onStartTimeChanged() {
    if (this.startTime > this.endTime) {
      this.endTime = new Date(this.startTime.getTime() + 1 * 60 * 60 * 1000);// Add one hour
    }
    this.loadAndShowHistory();
  }

  private loadAndShowHistory() {
    if (!this.history) {
      return;
    }
    this.isLoading = true;

    this.restService.loadVehicleLocationHistory(this.vehicle.id, this.startTime, this.endTime)
      .then(response => {
        // Delete old polyline
        this.lines.forEach(line => {
          this.map.removeLayer(line);
        });

        this.lines = response.segments.map(segment => this.createPolyline(segment));
        this.lines.forEach(line => line.addTo(this.map));
        // if current position is callable e.g. mission permission, then set mapview to first coordinate set in segments
        // otherwise the home location of the organisation remains
        if (!this.positionData) {
          if (this.vehicle.trackingMode !== VehicleTrackingMode.ALWAYS) {
            if (response.segments[0] && response.segments[0].positions[0]) {
              let firstCoords = response.segments[0].positions[0];
              this.map.setView([firstCoords.lat, firstCoords.lng], 15)
            }
          }
        }
      }).catch(err => {
      // Delete old polyline
      this.lines.forEach(line => {
        this.map.removeLayer(line);
      });
      this.$log.error(err.data.message)
      this.errorService.notifyWithText(err.data.message);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  private createPolyline(segment: Segment) {
    let points = segment.positions.map(pos => new L.LatLng(pos.lat, pos.lng));
    const polyline = new L.Polyline(points, {
      color: segment.color,
      weight: 5,
      opacity: 0.8
    });
    const popup = this.buildStatusLinePopup(segment);
    polyline.bindPopup(popup);
    return polyline;
  }


  private buildStatusLinePopup(segment: Segment) {
    const startTimestamp = new Date(segment.startTimestamp).toLocaleString();
    const endTimestamp = new Date(segment.endTimestamp).toLocaleString();
    let status;
    if (segment.status === EVehicleStatus.STATUS_UNKNOWN) {
      status = "Unbekannt";
    } else {
      status = this.statusTranslationMapping ? this.statusTranslationMapping[segment.status] : segment.status;
    }
    const popup = L.popup({closeButton: false})
      .setContent(`<div class="vehicle-data-popup">
            <b>${this.vehicle.name}</b>
            <br>
            ${status}
            <br ng-if="segment.externalResourceIds">
            <i ng-if="segment.externalResourceIds">${segment.externalResourceIds}</i>
            <br>
            <i>${startTimestamp} - ${endTimestamp}</i>
        </div>`);
    return popup;
  }

  private loadStatusColors() {
    return new Promise<void>((resolve, _) => {
      if (!(this.privilegeService.has(RolePrivilege.Station_Vehicles) || this.privilegeService.has(RolePrivilege.Station_Sirens))) {
        resolve();
        return;
      }
      this.vehicleService.getStatusColorMapping()
        .then(statusColorMapping => this.statusColorMapping = statusColorMapping)
        .then(() => this.vehicleService.getStatusTextColorMapping())
        .then(statusTextColorMapping => this.statusTextColorMapping = statusTextColorMapping)
        .then(() => this.vehicleService.getStatusTranslationMapping())
        .then(statusTranslationMapping => this.statusTranslationMapping = statusTranslationMapping)
        .catch(error => this.$log.error(error))
        .finally(() => {
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }
}