import { Vehicle, StatusEntryResponse, VehicleStatusHistory, EVehicleStatus, AssetStrength } from './../../../../data/vehicles.data';
import { IScope, IRootScopeService, ILogService } from 'angular';
import RestService from '../../../../services/rest.service';
import angular = require("angular");

'use strict';

require('./status.component.css');

export default class StatusComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.scope = {
      vehicle: '='
    }
    this.template = require('./status.component.html');

    this.controller = StatusController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//status
/* @ngInject */
class StatusController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public helperService: any;
  public vehicle: Vehicle;
  public isLoading: boolean;
  public $uibModal;
  public limit: number = 15;
  public currentPage = 0;
  public data = {
    currentPage: 1
  } as VehicleStatusHistory;

  public isAdmin: boolean;

  constructor($scope: IScope, dataService, $uibModal, $rootScope: IRootScopeService, $log: ILogService, restService: RestService, helperService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.helperService = helperService;
    this.$uibModal = $uibModal;

    if (dataService.hasAccount()) {
      this.isAdmin = dataService.getAccount().admin;
    }

    this.$scope.$watch('ctrl.vehicle', (oldValue, newVehicle: Vehicle) => {
      if (angular.isDefined(newVehicle)) {
        this.pageChanged();
      }
    });


  }

  pageChanged() {
    this.isLoading = true;
    //Current page starts with 0
    this.restService.getStatus(this.vehicle, this.currentPage, this.limit, (response) => {
      this.isLoading = false;
      this.data = response.data;
    }, (errorResponse) => {
      this.isLoading = false;
      this.$log.error(errorResponse);
    });
  };

  nextPage() {
    this.currentPage++;
    this.pageChanged();
  }

  previousPage() {
    if (this.currentPage === 0) return;
    this.currentPage--;
    this.pageChanged();
  }

  hasStrength(entry: StatusEntryResponse): boolean {
    if(!entry.strength) {
      return false;
    }
    let status = entry.status;
    if(status == EVehicleStatus.STATUS_1
      || status == EVehicleStatus.STATUS_3
      || status == EVehicleStatus.STATUS_4
      || status == EVehicleStatus.STATUS_7
      || status == EVehicleStatus.STATUS_8) {
      return true;
    }
  }

  totalStrength(strength: AssetStrength): number {
    return strength.cntBattalionLeader + strength.cntUnitLeader + strength.cntCrew;
  }

  clearStatusHistory() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.restService.clearStatusHistoryForVehicle(this.vehicle).then(() => {
              this.data = {
                currentPage: 1
              } as VehicleStatusHistory
              this.$scope.$applyAsync();
            }).catch(error => {
              this.$log.error(error);
            }).finally(() => {
              this.isLoading = false;
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });


  }
}
