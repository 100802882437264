import {AnnouncementDisplay, EAnnouncementType} from "../../../../../data/announcement.data";
import {WacheTabs} from "../../../../views/wache.view.component/wache.view.component";
import RestService from "../../../../../services/rest.service";
import PrivilegeService from "../../../../../services/privilege.service";
import {ILocationService} from "angular";
'use strict';

export default class AnnouncementWidgetComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      announcement:'='
    }
    this.template = require('./announcement.widget.component.html');

    this.controller = AnnouncementWidgetController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
require('./announcement.widget.component.scss')
class AnnouncementWidgetController{
  public announcement:AnnouncementDisplay;
  public $state: any;
  public announcementTypes:typeof EAnnouncementType= EAnnouncementType;
  public $log:any;
  public $location:ILocationService;



  constructor($state: any, $log:any, $location:ILocationService,public restService:RestService, public privilegeService:PrivilegeService) {
    this.$state = $state;
    this.$log = $log;
    this.$location= $location;


  }
  openAnnouncements(){
    this.$log.info('Change to announcement tab ' + WacheTabs.ANNOUNCEMENTS );
    this.$location.path('/main/wache/').search({ tab: WacheTabs.ANNOUNCEMENTS });

  }
}