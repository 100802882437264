'use strict';

/* @ngInject */
export default class ModalSavePipelineTemplateInstanceController {
	public $scope: any;
	public $rootScope: any;
	public $uibModalInstance: any;
	public $log: any;
	public dataService: any;
	public pipeline: any;
	public pipelineTemplateName: any;
	public pipelineTemplateDescription: any;

  constructor($scope, $rootScope, $uibModalInstance, $log, dataService, pipeline) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.dataService = dataService;
    this.pipeline = pipeline;
    this.pipelineTemplateName;
    this.pipelineTemplateDescription;
  }

  save() {
    this.$rootScope.isLoading = true;
    this.dataService.addPipelineTemplate(this.pipelineTemplateName, this.pipelineTemplateDescription, this.pipeline, (response) => {
      this.$rootScope.isLoading = false;
      this.$uibModalInstance.close();
    }, (errorResponse) => {
      //Error occured
      this.$rootScope.isLoading = false;
      this.$log.error(errorResponse);
    });
  }

  cancel() {
    this.$uibModalInstance.close();
  }
}