'use strict';

import { AAOTime, AAOTimeDay } from "../../../../data/aao.data";

require('./aao.adjust.time.css');

/* @ngInject */
export default class AAOAdjustTimeModalController {
  public $uibModalInstance: any;

  public time: AAOTimeDay;
  public okFunction: Function;

  // Validation
  public isEndInvalid = false;
  public isStartInvalid = false;

  public mode: TimeMode = TimeMode.FULL_DAY;


  constructor($uibModalInstance, time: AAOTimeDay, okFunction: Function) {
    this.$uibModalInstance = $uibModalInstance;
    this.time = time;
    this.okFunction = okFunction;
    this.validate();
  }

  save() {
    this.okFunction();
    this.$uibModalInstance.close();
  }

  validate() {
    const start = new Date();
    let tmp = this.time.start.split(':');
    if (tmp.length !== 2) {
      this.isStartInvalid = true;
      return;
    }

    start.setHours(parseInt(tmp[0]));
    start.setMinutes(parseInt(tmp[1]));

    const end = new Date();
    tmp = this.time.end.split(':');
    if (tmp.length !== 2) {
      this.isEndInvalid = true;
      return;
    }
    end.setHours(parseInt(tmp[0]));
    end.setMinutes(parseInt(tmp[1]));

    if (end.getTime() < start.getTime()) {
      this.isEndInvalid = true;
      return;
    }

    this.isStartInvalid = false;
    this.isEndInvalid = false;

    if (this.time.start === '00:00' && this.time.end === '00:00') {
      this.mode = TimeMode.DEACTIVATED;
    } else if (this.time.start === '00:00' && this.time.end === '24:00') {
      this.mode = TimeMode.FULL_DAY;
    } else {
      this.mode = TimeMode.CUSTOM;
    }

  }

  selectMode(newMode: TimeMode) {
    switch (newMode) {
      case TimeMode.CUSTOM:
        this.time.start = '08:00';
        this.time.end = '17:00';
        break;
      case TimeMode.DEACTIVATED:
        this.time.start = '00:00';
        this.time.end = '00:00';
        break;
      case TimeMode.FULL_DAY:
        this.time.start = '00:00';
        this.time.end = '24:00';
        break;
    }
    this.mode = newMode;
  }
}


enum TimeMode {
  CUSTOM = 'CUSTOM', DEACTIVATED = 'DEACTIVATED', FULL_DAY = 'FULL_DAY'
}