'use strict';

import { IScope } from "angular";
import { VehicleSimple, VehiclesPaginatedResponse, VehiclesSimplePaginatedResponse } from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";

require('./vehicles.base.field.css');

export default class VehiclesBaseFieldComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./vehicles.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = VehiclesBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class VehiclesBaseFieldController {
  public field: any;
  public vehicles: VehiclesSimplePaginatedResponse;
  public $scope: IScope;
  public restService: RestService;
  public isLoading = true;
  public isDerivedFromTemplate: boolean;
  public searchFilter: string = '';
  public params = {
    currentPage: 0,
    totalElements: 0,
    pageSize: 15
  } as SortParams;

  constructor($scope: IScope, restService: RestService) {
    this.restService = restService;
    this.$scope = $scope;

    this.$scope.$watch('ctrl.field', (oldValue, newValue) => {
      if (newValue) {
        this.pageChanged();
      }
    });

  }

  pageChanged() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadVehiclesPaginatedSimple(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.pageSize, '', 'ASC').then(
        (result: VehiclesSimplePaginatedResponse) => {
          this.vehicles = result;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }


  /**
   * Is the given vehicle currently selected
   * @param {vehicle} VehicleSimple
   */
  isSelected(vehicle: VehicleSimple) {
    for (var i = 0; i < this.field.value.length; i++) {
      if (this.field.value[i] === vehicle.id) {
        return true;
      }
    }
    return false;
  };


  /**
   * Select the given vehicle
   * @param {vehicle} VehicleSimple
   */
  select(vehicle: VehicleSimple) {
    if (this.isDerivedFromTemplate) {
      return;
    }
    if (this.isSelected(vehicle)) {
      //Remove
      var indexToDelete = -1;
      for (var i = 0; i < this.field.value.length; i++) {
        if (this.field.value[i] === vehicle.id) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete > -1) {
        this.field.value.splice(indexToDelete, 1);
      }
    } else {
      //Add
      this.field.value.push(vehicle.id);
    }
  }

}
