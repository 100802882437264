'use strict';

import { ILogService, IScope } from "angular";
import { AlarmObject } from "../../../../data/objects.data";
import ErrorService from "../../../../services/error.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./object.directioncard.component.css');

//objectDirectioncard
export default class ObjectDirectioncardComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.directioncard.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectDirectioncardComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class ObjectDirectioncardComponentController {
  public hasBMA = false;
  public selected: any = undefined;
  public isUploading = false;
  public isLoading = false;
  public auth: string;
  public alarmObject: AlarmObject;
  public uploader: any;

  constructor(public $log: ILogService,
    public $scope: IScope,
    public restService: RestService,
    public dataService,
    public privilegeService: PrivilegeService,
    public errorService: ErrorService) {
    this.auth = this.restService.getAuthHeader();

    /**
     * Init data changed
     */
    this.$scope.$watch('ctrl.alarmObject', (newValue) => {
      if (this.alarmObject) {
        this.hasBMA = this.alarmObject.bma && this.alarmObject.bma !== null;
      }
      if (newValue && !this.uploader) {
        this.uploader = this.restService.createDirectioncardUploader(this.alarmObject);
      }
    });
  }

  openLink(doc) {
    return '/rest/objects/' + this.alarmObject.id + '/directioncards/' + doc.fileName + '?Authorization=' + this.auth;
  };

  /**
 * Add a new document
 */
  uploadFiles() {
    if (this.uploader.queue.length === 0) {
      return;
    }
    this.isUploading = true;

    this.uploader.onBeforeUploadItem = (item) => {
      //Change upload path
      item.url = item.url + encodeURI(item.file.name);
    };

    //Success
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      //Response contains all directioncards from the bma
      this.$log.info('Upload completed: ' + status);
      this.isUploading = false;
      this.alarmObject.bma.directioncards = response
    };
    //Error
    this.uploader.onErrorItem = (item, response, status) => {
      this.$log.error('Upload failed: ' + status);
      this.$log.error(response);
      this.isUploading = false;
      this.errorService.notifyWithText(response.message);
    };

    this.uploader.onCompleteAll = () => {
      this.isUploading = false;
    };

    this.uploader.uploadAll();
  };

  /**
   * Delete existing ventilation
   * @param {*} data
   */
  deleteDirectioncard(directioncard) {
    this.isLoading = true;
    this.dataService.deleteDirectioncard(this.alarmObject, directioncard, (suc) => {
      this.isLoading = false;
    }, (err) => {
      this.$log.error(err);
      this.isLoading = false;
    });
  };
}