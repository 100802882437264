'use strict';

import angular = require('angular');
export default class AvailabilityFunctionSummaryComponent {
	public restrict: any;
	public scope: any;
	public template: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '='
      };
    this.template = require('./functions.summary.component.html');


    this.controller = AvailabilityFunctionSummaryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AvailabilityFunctionSummaryController {
	public $scope: any;
	public $log: any;

  constructor($scope, $log) {

    this.$scope = $scope;
    this.$log = $log;

    this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
    })
  }
}