import {ex} from "@fullcalendar/core/internal-common";

export interface Announcement {
  id: string;
  parent: string;
  author: string;
  title: string;
  text: string;
  emoji: string;
  startDate: Date;
  endDate: Date;
  type: EAnnouncementType;
  pinned: boolean;
  pinnedOnAlarmPerspective: boolean;
  activeState: EAnnouncementActiveState;
  admin: boolean;
  category: EAnnouncementCategory;
  categoryLabel: string;
  notification: EAnnouncementNotification;
  receivers: AnnouncementReceivers;
  displayOnAMweb: boolean;
  categoryLabels: Map<EAnnouncementCategory, string>;
}


export interface AnnouncementDisplay {
  id: string;
  parent: string;
  author: string
  category: EAnnouncementCategory;
  type: EAnnouncementType;
  startDate: Date;
  endDate: Date;
  title: string;
  text: string;
  emoji: string;
  state: string;
  color: string;
}
export class AnnouncementRequest {
  displayOnAMweb: boolean;
  emoji: string;
  notification: string;
  pinned: boolean;
  pinnedOnAlarmPerspective: boolean;
  text: string;
  title: string;
  type: string;
  duration: number;
  content: string;

}

export enum EAnnouncementActiveState {
  ACTIVE, FUTURE, PAST
}

export enum EAnnouncementNotification {
  NONE = 'NONE', USER_ADMINS = 'USER_ADMINS', ALL_PERSONS = 'ALL_PERSONS'
}

export enum EAnnouncementType {
  INFO="INFO", HINT="HINT", WARNING="WARNING"
}

export enum EAnnouncementCategory {
  _1, _2, _3, _4, _5, _6, _7, _8, _9
}

export interface AnnouncementReceivers {
  size: number;
  duration: string;
  hasSent: boolean;
  sendingFinished: string;
  sendingStarted: string;
}

export interface AnnouncementResponsePaginated {
  content: Announcement[],
  totalPages: number,
  totalElements: number,
  size: number,
  number: number
}

export interface AnnouncementResponses {
    announcements: AnnouncementResponsePaginated;
    globalAnnouncements: Announcement[];
}