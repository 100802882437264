'use strict'
import { ILogService, IScope, IWindowService} from "angular";
import {
  EMissionReportState,
  Emergency,
  MissionReport
} from "../../../../data/emergency.data";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";

import {KeywordAutoComplete} from "../../../../data/search.data";
import angular = require("angular");

import {VehicleNameIDResponse} from "../../../../data/vehicles.data";
import { AlarmObjectsSearchResult} from "../../../../data/objects.data";
import HelperService from "../../../../services/helper.service";
import ErrorService from "../../../../services/error.service";
import { GeneralResponse } from "../../../../data/admin.data";

require('./mission.report.modal.scss')
/* @ngInject */
export default class ReportModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public $log: ILogService
  public report: MissionReport;
  public emergency: Emergency;
  public isNew: boolean;
  public loadingPerson: boolean;
  public loadingObjects = false;

  public okFunction: any;
  public modalTabs: typeof MissionReportTabs = MissionReportTabs;
  public hasEditRight: boolean;
  public hasPublishRight: boolean;
  public hasExportRight: boolean;
  public mode: MissionReportTabs;
  public startDate: Date;
  public endDate: Date;
  public keyword: any;
  public loadingAMobile: boolean;
  public reloadValues: boolean = false;

  public connectedVehicle: string;
  public isSavingReport = false;
  public helper: HelperService;

  constructor($scope: IScope, private $window: IWindowService, $log: ILogService, private $http, private Notification, $uibModalInstance: any, isNew: boolean, okFunction: any, missionReport: MissionReport, emergency: Emergency, public restService: RestService, public privilegeService: PrivilegeService, public dataService, helperService: HelperService, public $uibModal: any, private errorService: ErrorService) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.report = missionReport;
    this.emergency = emergency;
    this.isNew = isNew;
    this.okFunction = okFunction;
    this.mode = MissionReportTabs.BASE_DATA;
    this.helper = helperService;
    this.$log = $log;
    this.hasEditRight = privilegeService.privileges['Home_Emergency_Details_Report_Write'];
    this.hasPublishRight= privilegeService.privileges['Home_Emergency_Details_Report_Close']
    this.hasExportRight= privilegeService.privileges['Home_Emergency_Details_Export_Report']

    if (this.report.state === EMissionReportState.PUBLISHED) {
      this.hasEditRight = false;
    }

    this.initializeValues();
  }

  /**
   * initialize the date objects to work on (data structure has millis timestamp)
   * @private
   */
  private initializeValues() {
    if (this.report && this.report.generalData && this.report.generalData.startTime) {
      this.startDate = this.clearSecondsAndMillis(new Date(this.report.generalData.startTime));
    }
    if (this.report && this.report.generalData && this.report.generalData.endTime) {
      this.endDate = this.clearSecondsAndMillis(new Date(this.report.generalData.endTime));
    }
    this.connectedVehicle = this.report.vehicle?.name;
  }

  /**
   * return correct value to display for selected aMobile
   */
  getNameForVehicle(): string {
    if (this.report.vehicle) {
      return this.report.vehicle.name;
    }
    return '';
  }

// clear the millis and seconds from the date objects
  private clearSecondsAndMillis(date: Date): Date {
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  /**
   Get all available cities
   */
  getCities(val: string) {
    return this.restService.getCities(val);
  };

  /**
   Get all available objects
   */
  getObjects(val: string) {
    return this.restService.getBuildings(val);
  };

  /**
   Get all available streets
   */
  getStreets(val: string) {
    if (angular.isUndefined(this.report.generalData.address.city) || this.report.generalData.address.city === '') {
      return;
    }

    return this.restService.getStreets(this.report.generalData.address.city, val);
  };

  /**
   A street has been selected
   */
  streetSelected($item) {

    this.report.generalData.address.city = $item.city;
    this.report.generalData.address.street = $item.street;
  };

  buildingSelected($item: AlarmObjectsSearchResult) {
    this.report.generalData.building.name = $item.building;
    this.report.generalData.building.id = $item.id;
  }


  /**
   * search for a Mobiles
   * @param val
   */
  loadVehicle(val: string) {
    return this.restService.searchForVehiclesAccessibleForUser(val, this.emergency.parentUserId, 10);
  }

  changeVehicle() {
  }

  /**
   * select an aMobile for report
   * @param item
   */
  vehicleSelected(item: VehicleNameIDResponse) {
    this.report.vehicle = item;
  }

  /**
   Get all available keywords
   */
  getKeywords(val: string) {
    return this.restService.getKeywords(val);
  }

  /**
   Get all available keywords searched by description
   */
  getKeywordsByDescription(val: string) {
    return this.restService.getKeywordsByDescription(val);
  }

  changeKeyword() {
    if (this.report.generalData.keyword === '') {
      this.report.generalData.keywordDescription = '';
    }
  }

  /**
   A keyword has been selected
   */
  keywordSelected(item: KeywordAutoComplete) {
    //Set postalcode, city and street
    if (!angular.isUndefined(item.description)) {
      this.report.generalData.keywordDescription = item.description;
    }
    if (!angular.isUndefined(item.keyword)) {
      this.report.generalData.keyword = item.keyword;
    }
    this.keyword = item;
  }

  /**
   * save and then close report edit modal
   */
  save() {
    return new Promise<void>((resolve, reject) => {
      if (!this.hasEditRight) {
        return;
      }
      this.setFields();
      this.isSavingReport = true;
      if (this.isNew) {
        var userId = '';
        if (this.dataService.getAccount().admin) {
          userId = this.emergency.parentUserId;
        }

        this.restService.createMissionReport(this.report.externalId, this.report, userId).then((createdReport) => {
          this.reloadValues = true;
          this.isNew = false;
          this.report = createdReport;
        }).finally(() => {
          this.isSavingReport = false;
          this.$scope.$applyAsync();
          resolve();
        });
      } else {
        this.report.revisionNumber += 1;
        this.restService.updateMissionReport(this.report.externalId, this.report.reportId, this.report).then((updatedReport) => {
          this.reloadValues = true;
          this.report = updatedReport;
        }).finally(() => {
          this.isSavingReport = false;
          this.$scope.$applyAsync();
          resolve();
        });
      }
    });
  }



  publish(): void {
    this.isSavingReport = true;
    this.$uibModal.open({
      template: require('../mission.report.publish.confirm.modal/mission.report.publish.confirm.modal.html'),
      controller: 'MissionReportPublishConfirmModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (confirmed, createMissionConfirmationAndSend) =>{
            if (confirmed) {
              this.isSavingReport = true;
              this.report.revisionNumber += 1;
              // only save here to not make a version counter out of the publish button
              this.save().then(() => {
                this.reloadValues = true;
                this.restService.publishMissionReport(this.report.externalId, this.report.reportId).then(() => {
                  this.report.state = EMissionReportState.PUBLISHED;
                  this.reloadValues = true;
                }).then(() => {
                  if (createMissionConfirmationAndSend) {
                    this.createAndSendAllMissionConfirmations();
                  }
                }).catch(error => {
                  this.$log.error(error);
                });
              }).catch(error => {
                this.$log.error(error);
                this.errorService.notifyWithText(error.data.message);
              }).finally(() => {
                this.isSavingReport = false;
                this.$scope.$applyAsync();
              });
            } else {
              this.isSavingReport = false;
            }
          }
        }
      }});

  }

  getSourcePlattform() {
    if (this.report) {
      return this.report.sourcePlatform;
    }
  }

  getVersion() {
    if (this.report && this.report.revisionNumber) {
      return this.report.revisionNumber;
    } else {
      return 1;
    }
  }

  /**
   * close, without reload trigger
   */
  close() {
    // no reload if no change was made
    this.okFunction(this.reloadValues);
    this.$uibModalInstance.close();
  }

  /**
   * Export the current report as PDF
   */
  exportPdf(): void {
    this.$window.open(
      `${this.restService.getBaseUrl()}/missionReports/${encodeURIComponent(this.report.externalId)}/${this.report.reportId}/export?Authorization=${this.$http.defaults.headers.common.Authorization}`,
      '_blank'
    );
  }

  /**
   * parse the dates to millis timestamps again
   * @private
   */
  private setFields() {
    if (this.startDate) {
      this.report.generalData.startTime = this.startDate.getTime();
    }
    if (this.endDate) {
      this.report.generalData.endTime = this.endDate.getTime();
    }
  }

  private createAndSendAllMissionConfirmations() {
    this.restService.createAndSendMissionConfirmation(this.report.externalId, this.report.reportId).then((res) => {
      this.Notification.success({
        message: res.message
      });
    }).catch(err => {
      // Nothing to do
    }).finally(() => {
      // nothing jet
    });
  }
}

export enum MissionReportTabs {
  BASE_DATA = 'BASE_DATA',
  MISSION_DATA = 'MISSION_DATA',
  DEVICES = 'DEVICES',
  PARTICIPANTS = 'PARTICIPANTS',
  RESOURCES = 'RESOURCES'
}
