'use strict';

import {IScope} from "angular";
import PrivilegeService from "../../../../services/privilege.service";

/* @ngInject */
export default class TimeplanModalController {
    public $scope: IScope;
    public $uibModalInstance: any;
    public timeplan: any;

    constructor($scope: IScope, $uibModalInstance, timeplan, public privilegeService: PrivilegeService) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.timeplan = timeplan;
    }

    close() {
        this.$uibModalInstance.close(this.timeplan);
    }
}