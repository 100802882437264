import { IScope } from "angular";
import { EFE2Features } from "../../../../../data/account.data";
import { AlarmData } from "../../../../../data/alarm.data";
import { Emergency } from "../../../../../data/emergency.data";
import { RolePrivilege } from "../../../../../data/privileges.enum";
import PrivilegeService from "../../../../../services/privilege.service";

require("./mission.alarms.component.css")
/* @ngInject */
export default class MissionAlarmsComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./mission.alarms.component.html');
    this.scope = {
      emergency: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = MissionAlarmsComponentController;

  }
}

class MissionAlarmsComponentController {

  public emergency: Emergency;

  public deletionOfAlarmsDeactivated = false;
  public isShiftBookActive = false;

  constructor(private $scope: IScope,
    public privilegeService: PrivilegeService,
    private $uibModal: any,
    private dataService: any,
    private accountService: any) {

      this.deletionOfAlarmsDeactivated =  this.dataService.getAccount().deletionOfAlarmsDeactivated;
      this.isShiftBookActive = this.accountService.isFeatureEnabled(EFE2Features.SHIFT_BOOK);

    }

  /**
    Select an alarm and show it
  */
  openAlarm(alarm: AlarmData) {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Alarms_Details)) {
      return;
    }

    this.$uibModal.open({
      template: require('../../../../modals/alarms/alarm.modal/alarm.modal.html'),
      controller: 'AlarmModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        alarm: () => {
          return alarm;
        }
      }
    });
  }
}